import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Protected = (props) => {
  const { Component } = props;
  const navigate = useNavigate();

  useEffect(() => {
    const userRole = Cookies.get("userRole");
    const jwtToken = Cookies.get("COOKIE_JWTTOKEN");
    if (!userRole || !jwtToken) {
      navigate("/");
    }
  });

  return (
    <>
      <Component />
    </>
  );
};

export default Protected;
