import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { USER_LOGIN } from "../../APIProxy/api";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

export const userlogin = createAsyncThunk(
  "user/userlogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${USER_LOGIN}?email=${data.email}&password=${data.password}`
      );
      if (response.status === 200) {
        if (response.data) {
          const decodedToken = jwtDecode(response.data.result.token);
          const expiresOn = new Date(decodedToken.exp * 1000);
          Cookies.set(
            "COOKIE_JWTTOKEN",
            CryptoJS.AES.encrypt(
              response.data.result.token,
              "CRYPTO_SECRET_KEY"
            ).toString(),
            {
              expires: expiresOn,
            }
          );
          return response.data.result;
        } else {
            return rejectWithValue({ message: "Invalid username or password" });
        }
      } else {
        return rejectWithValue({
          message: `An error occurred during login, status code: ${response.status}`,
        });
      }
    } catch (error) {
      return rejectWithValue({ message: "An error occurred during login" });
    }
  }
);
