import { createSlice } from '@reduxjs/toolkit';
import { listAllUsers } from '../Actions/allUsersActions';

const initialState = {
  allUsers: [],
  loading: false,
  error: null,
};

const allUsersSlice = createSlice({
  name: 'allUsers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    //All Users Slices
      .addCase(listAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(listAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.allUsers = action.payload;
      })
      .addCase(listAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default allUsersSlice.reducer;