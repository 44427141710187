import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import {
  API_ADD_OR_EDIT,
  API_LIST_USERS,
  API_DELETE_USER,
  API_LIST_CLIENTS,
  API_ADD_OR_EDIT_CLIENT,
  API_DELETE_CLIENT,
} from "../../APIProxy/api";

//Admin APIs
const token =
  !!Cookies.get("COOKIE_JWTTOKEN") && Cookies.get("COOKIE_JWTTOKEN");
let decodedToken = "";

if (token) {
  const decryptedToken = CryptoJS.AES.decrypt(token, "CRYPTO_SECRET_KEY");
  decodedToken = decryptedToken.toString(CryptoJS.enc.Utf8);
}

export const addUser = createAsyncThunk("user/addUser", async (user) => {
  const response = await axios.post(API_ADD_OR_EDIT, user, {
    headers: { Authorization: `Bearer ${decodedToken}` },
  });
  return response.data;
  return response.data;
});

export const listUsers = createAsyncThunk(
  "users/listUsers",
  async ({ searchTerm, sortBy, sortDirection, page, pageSize, parentId }) => {
    const response = await axios.get(API_LIST_USERS, {
      headers: { Authorization: `Bearer ${decodedToken}` },
      params: {
        parentId,
        searchTerm,
        sortBy,
        sortDirection,
        page,
        pageSize,
      },
    });
    return response.data;
  }
);
export const editUser = createAsyncThunk("user/editUser", async (user) => {
  const response = await axios.post(API_ADD_OR_EDIT, user, {
    headers: { Authorization: `Bearer ${decodedToken}` },
  });
  return response.data;
});

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (userId) => {
    const response = await axios.get(API_DELETE_USER + userId, {
      headers: { Authorization: `Bearer ${decodedToken}` },
    }); //, { ID: userId });
    return response.data;
  }
);

//CLient APIs

export const listClients = createAsyncThunk(
  "users/listClients",
  async ({ searchTerm, sortBy, sortDirection, page, pageSize }) => {
    const response = await axios.get(API_LIST_CLIENTS, {
      headers: { Authorization: `Bearer ${decodedToken}` },
      params: {
        sortBy,
        searchTerm,
        sortDirection,
        page,
        pageSize,
      },
    });
    return response.data;
  }
);

// export const addClient = createAsyncThunk('user/addClient', async (user) => {
//   const response = await axios.post(API_ADD_OR_EDIT_CLIENT, user);
//   return response.data;
// });

export const addClient = createAsyncThunk(
  "user/addClient",
  async ({ user }) => {
    //, imageFile
    const formData = new FormData();
    Object.entries(user).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("logo", user.imageFile);
    try {
      const response = await axios.post(API_ADD_OR_EDIT_CLIENT, formData, {
        headers: { Authorization: `Bearer ${decodedToken}` },
      });
      return response.data;
    } catch (error) {
      throw Error("Failed to add client: " + error.message);
    }
  }
);

// export const editClient = createAsyncThunk('user/editClient', async (user) => {
//   const response = await axios.post(API_ADD_OR_EDIT_CLIENT, user);
//   return response.data;
// });
export const editClient = createAsyncThunk(
  "user/editClient",
  async (payload) => {
    const { logo, ...user } = payload;
    const formData = new FormData();
    Object.entries(user).forEach(([key, value]) => {
      formData.append(key, value);
    });
    if (logo) {
      formData.append("logo", logo);
    }
    const response = await axios.post(API_ADD_OR_EDIT_CLIENT, formData, {
      headers: { Authorization: `Bearer ${decodedToken}` },
    });
    return response.data;
  }
);

// export const editClient = createAsyncThunk('user/editClient', async ({ user, imageFile }) => {
//   const formData = new FormData();
//   Object.entries(user).forEach(([key, value]) => {
//     formData.append(key, value);
//   });
//   formData.append('logo', imageFile); // Append the image file to the FormData
//   try {
//     const response = await axios.post(API_ADD_OR_EDIT_CLIENT, formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     });
//     return response.data;
//   } catch (error) {
//     throw Error('Failed to edit client: ' + error.message);
//   }
// });

export const deleteClient = createAsyncThunk(
  "user/deleteClient",
  async (userId) => {
    const response = await axios.get(API_DELETE_CLIENT + userId, {
      headers: { Authorization: `Bearer ${decodedToken}` },
    }); //, { ID: userId });
    return response.data;
  }
);
