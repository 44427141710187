import { createSlice } from "@reduxjs/toolkit";
import {
  approveCarbonSavingKG,
  approveColours,
  approveCost,
  approveDescription,
  approveEsfaCode,
  approveItemType,
  carbonSavingKGMasterList,
  coloursMasterList,
  costMasterList,
  descriptionMasterList,
  esfaCodesMasterList,
  itemTypeMasterList,
} from "../Actions/MastersApprovalActions";

const initialState = {
  discriptionsList: [],
  noOfRecordsDiscriptions: 0,
  descriptionLoading: false,
  descriptionError: null,
  itemTypesMasterList: [],
  noOfRecordsItemTypes: 0,
  itemTypeLoading: false,
  itemTypeError: null,
  esfaCodesLoading: false,
  noOfRecordsEsfaCodes: 0,
  esfaCodesMasterList: [],
  esfaCodesError: null,
  coloursLoading: false,
  noOfRecordsColours: 0,
  coloursMasterList: [],
  coloursError: null,
  costsLoading: false,
  noOfRecordsCosts: 0,
  costsMasterList: [],
  costsError: null,
  carbonSavingKGLoading: false,
  noOfRecordsCarbonSavingKG: 0,
  carbonSavingKGMasterList: [],
  carbonSavingKGError: null,
  error: null,
};

const MastersApprovalSlice = createSlice({
  name: "MastersApproval",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(descriptionMasterList.pending, (state) => {
        state.descriptionLoading = true;
      })
      .addCase(descriptionMasterList.fulfilled, (state, action) => {
        state.descriptionLoading = false;
        state.discriptionsList = action.payload.descriptionsList;
        state.noOfRecordsDiscriptions = action.payload.noOfRecords;
      })
      .addCase(descriptionMasterList.rejected, (state, action) => {
        state.descriptionLoading = false;
        state.descriptionError = action.error.message;
      })
      .addCase(itemTypeMasterList.pending, (state) => {
        state.itemTypeLoading = true;
      })
      .addCase(itemTypeMasterList.fulfilled, (state, action) => {
        state.itemTypeLoading = false;
        state.itemTypesMasterList = action.payload.itemTypesList;
        state.noOfRecordsItemTypes = action.payload.noOfRecords;
      })
      .addCase(itemTypeMasterList.rejected, (state, action) => {
        state.itemTypeLoading = false;
        state.itemTypeError = action.error.message;
      })
      .addCase(esfaCodesMasterList.pending, (state) => {
        state.esfaCodesLoading = true;
      })
      .addCase(esfaCodesMasterList.fulfilled, (state, action) => {
        state.esfaCodesLoading = false;
        state.esfaCodesMasterList = action.payload.eSFACodesList;
        state.noOfRecordsEsfaCodes = action.payload.noOfRecords;
      })
      .addCase(esfaCodesMasterList.rejected, (state, action) => {
        state.esfaCodesLoading = false;
        state.esfaCodesError = action.error.message;
      })
      .addCase(coloursMasterList.pending, (state) => {
        state.coloursLoading = true;
      })
      .addCase(coloursMasterList.fulfilled, (state, action) => {
        state.coloursLoading = false;
        state.coloursMasterList = action.payload.coloursList;
        state.noOfRecordsColours = action.payload.noOfRecords;
      })
      .addCase(coloursMasterList.rejected, (state, action) => {
        state.coloursLoading = false;
        state.coloursError = action.error.message;
      })
      .addCase(costMasterList.pending, (state) => {
        state.costsLoading = true;
      })
      .addCase(costMasterList.fulfilled, (state, action) => {
        state.costsLoading = false;
        state.costsMasterList = action.payload.costingsList;
        state.noOfRecordsCosts = action.payload.noOfRecords;
      })
      .addCase(costMasterList.rejected, (state, action) => {
        state.costsLoading = false;
        state.costsError = action.error.message;
      })
      .addCase(carbonSavingKGMasterList.pending, (state) => {
        state.carbonSavingKGLoading = true;
      })
      .addCase(carbonSavingKGMasterList.fulfilled, (state, action) => {
        state.carbonSavingKGLoading = false;
        state.carbonSavingKGMasterList = action.payload.carbonSavingKGsList;
        state.noOfRecordsCarbonSavingKG = action.payload.noOfRecords;
      })
      .addCase(carbonSavingKGMasterList.rejected, (state, action) => {
        state.carbonSavingKGLoading = false;
        state.carbonSavingKGError = action.error.message;
      })
      .addCase(approveDescription.pending, (state) => {
        state.descriptionLoading = true;
      })
      .addCase(approveDescription.fulfilled, (state, action) => {
        state.descriptionLoading = false;
      })
      .addCase(approveDescription.rejected, (state, action) => {
        state.descriptionLoading = false;
        state.error = action.error.message;
      })
      .addCase(approveItemType.pending, (state) => {
        state.itemTypeLoading = true;
      })
      .addCase(approveItemType.fulfilled, (state, action) => {
        state.itemTypeLoading = false;
      })
      .addCase(approveItemType.rejected, (state, action) => {
        state.itemTypeLoading = false;
        state.error = action.error.message;
      })
      .addCase(approveEsfaCode.pending, (state) => {
        state.esfaCodesLoading = true;
      })
      .addCase(approveEsfaCode.fulfilled, (state, action) => {
        state.esfaCodesLoading = false;
      })
      .addCase(approveEsfaCode.rejected, (state, action) => {
        state.esfaCodesLoading = false;
        state.error = action.error.message;
      })
      .addCase(approveColours.pending, (state) => {
        state.coloursLoading = true;
      })
      .addCase(approveColours.fulfilled, (state, action) => {
        state.coloursLoading = false;
      })
      .addCase(approveColours.rejected, (state, action) => {
        state.coloursLoading = false;
        state.error = action.error.message;
      })
      .addCase(approveCost.pending, (state) => {
        state.costsLoading = true;
      })
      .addCase(approveCost.fulfilled, (state, action) => {
        state.costsLoading = false;
      })
      .addCase(approveCost.rejected, (state, action) => {
        state.costsLoading = false;
        state.error = action.error.message;
      })
      .addCase(approveCarbonSavingKG.pending, (state) => {
        state.carbonSavingKGLoading = true;
      })
      .addCase(approveCarbonSavingKG.fulfilled, (state, action) => {
        state.carbonSavingKGLoading = false;
      })
      .addCase(approveCarbonSavingKG.rejected, (state, action) => {
        state.carbonSavingKGLoading = false;
        state.error = action.error.message;
      });
  },
});

export default MastersApprovalSlice.reducer;
