/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip, Button, Modal } from "react-bootstrap";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import config from "../../config";
import Swal from "sweetalert2";
import {
  saveRequestForChange,
  updateProjectReport,
  updateProjectReportForChangeRequest,
} from "../../Redux/Actions/projectsActions";
import { DataTable as DT } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
import Cookies from "js-cookie";
import * as CryptoJS from "crypto-js";
import { secret_key } from "../../APIProxy/secret";
import { Button as Btn } from "primereact/button";
//-------------

// import { useDispatch, useSelector } from "react-redux";
import "react-awesome-lightbox/build/style.css";
// import Loader from "../Common/Loader/loader";
// import * as CryptoJS from "crypto-js";
// import Cookies from "js-cookie";
// import { secret_key } from "../../APIProxy/secret";
import { Chart, registerables } from "chart.js/auto";
// import { Button } from "bootstrap";
// import moment from "moment";
// import DataTable from "react-data-table-component";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import {
  allProjectsReportListForChangeRequested,
  projectsReportForChangeRequested,
  saveApproveRejectRequestForChange,
} from "../../Redux/Actions/ChangeRequestActions";
Chart.register(...registerables);
//-------------

const ChangeRequestProjectReports = () => {
  const userRole = Cookies.get("userRole");
  const { projectId, clientId } = useParams();
  const [filters, setFilters] = useState({
    level: { value: null, matchMode: FilterMatchMode.IN },
    roomNumber: { value: null, matchMode: FilterMatchMode.IN },
    roomName: { value: null, matchMode: FilterMatchMode.IN },
    description: { value: null, matchMode: FilterMatchMode.IN },
    quantity: { value: null, matchMode: FilterMatchMode.IN },
    itemType: { value: null, matchMode: FilterMatchMode.IN },
    esfaCode: { value: null, matchMode: FilterMatchMode.IN },
    condition: { value: null, matchMode: FilterMatchMode.IN },
    lifeExpectancy: { value: null, matchMode: FilterMatchMode.IN },
    colour: { value: null, matchMode: FilterMatchMode.IN },
    makeModel: { value: null, matchMode: FilterMatchMode.IN },
    length: { value: null, matchMode: FilterMatchMode.IN },
    depth: { value: null, matchMode: FilterMatchMode.IN },
    height: { value: null, matchMode: FilterMatchMode.IN },
    primaryColour: { value: null, matchMode: FilterMatchMode.IN },
    secondaryColour: { value: null, matchMode: FilterMatchMode.IN },
    abnormal: { value: null, matchMode: FilterMatchMode.IN },
    comments: { value: null, matchMode: FilterMatchMode.IN },
    // photograph: { value: null, matchMode: FilterMatchMode.IN },
    cost: { value: null, matchMode: FilterMatchMode.IN },
    carbonSavingKG: { value: null, matchMode: FilterMatchMode.IN },
    qrCode: { value: null, matchMode: FilterMatchMode.IN },
    legacyCode: { value: null, matchMode: FilterMatchMode.IN },
    newRoomName: { value: null, matchMode: FilterMatchMode.IN },
    newRoomNumber: { value: null, matchMode: FilterMatchMode.IN },
  });

  const cookieUserId = Cookies.get("userId");
  const loginUserId = !!cookieUserId
    ? CryptoJS.AES.decrypt(cookieUserId, secret_key).toString(CryptoJS.enc.Utf8)
    : null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const IMAGE_BASE_URL = config.IMAGE_URL;
  //----------
  const encryptedUserId = Cookies.get("userId");
  const decryptedBytes =
    !!encryptedUserId && CryptoJS.AES.decrypt(encryptedUserId, secret_key);
  const userId = decryptedBytes.toString(CryptoJS.enc.Utf8);
  //----------

  const [isOpenModalImage, setIsOpenModalImage] = useState(false);
  const [modalImageName, setModalImageName] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortColumnDir, setSortColumnDir] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [projectReportArr, setProjectReportArr] = useState([]);
  const [descriptionFilter, setDescriptionFilter] = useState(null);
  const [levelFilter, setLevelFilter] = useState(null);
  const [roomNumberFilter, setRoomNumberFilter] = useState(null);
  const [roomNameFilter, setRoomNameFilter] = useState(null);
  const [quantityFilter, setQuantityFilter] = useState(null);
  const [itemTypeFilter, setItemTypeFilter] = useState(null);
  const [esfaCodeFilter, setEsfaCodeFilter] = useState(null);
  const [conditionFilter, setConditionFilter] = useState(null);
  const [lifeExpectancyFilter, setLifeExpectancyFilter] = useState(null);
  const [colourFilter, setColourFilter] = useState(null);
  const [makeModelFilter, setMakeModelFilter] = useState(null);
  const [lengthFilter, setLengthFilter] = useState(null);
  const [depthFilter, setDepthFilter] = useState(null);
  const [heightFilter, setHeightFilter] = useState(null);
  const [primaryColourFilter, setPrimaryColourFilter] = useState(null);
  const [secondaryColourFilter, setSecondaryColourFilter] = useState(null);
  const [abnormalFilter, setAbnormalFilter] = useState(null);
  const [commentsFilter, setCommentsFilter] = useState(null);
  // const [photographFilter, setPhotographFilter] = useState(null);
  const [costFilter, setCostFilter] = useState(null);
  const [carbonSavingKGFilter, setCarbonSavingKGFilter] = useState(null);
  const [newRoomNameFilter, setNewRoomNameFilter] = useState(null);
  const [newRoomNumberFilter, setNewRoomNumberFilter] = useState(null);
  const [qrCodeFilter, setQRCodeFilter] = useState(null);
  const [legacyCodeFilter, setLegacyCodeFilter] = useState(null);
  const [columnData, setColumnData] = useState();
  const [id, setId] = useState(0);
  const [isApproved, setIsApproved] = useState(null);
  const [modalIsOpen, setModelIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState(null);
  //--------------

  const conditionDescriptionWithCounts = useSelector(
    (state) => state.clientProjectsReport.conditionDescriptionWithCounts
  );
  const projects = useSelector((state) => state.projects);

  const trimprojectReportArr =
    !!projectReportArr &&
    projectReportArr.map((item) => {
      const trimmedItem = {};
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          trimmedItem[key] =
            typeof item[key] === "string" ? item[key].trim() : item[key];
        }
      }
      return trimmedItem;
    });

  const totals = {
    description: "Total Result",
    aSuitable: 0,
    bMinorDamage: 0,
    cMediumDamage: 0,
    dHeavyDamage: 0,
    eUnsuitable: 0,
    fSpecialRequired: 0,
    totalResult: 0,
  };

  conditionDescriptionWithCounts &&
    conditionDescriptionWithCounts.forEach((item) => {
      totals.aSuitable += item.aSuitable;
      totals.bMinorDamage += item.bMinorDamage;
      totals.cMediumDamage += item.cMediumDamage;
      totals.dHeavyDamage += item.dHeavyDamage;
      totals.eUnsuitable += item.eUnsuitable;
      totals.fSpecialRequired += item.fSpecialRequired;
      // totals.gElectrical += item.gElectrical;
      totals.totalResult += item.totalResult;
    });

  const projectReport = useSelector(
    (state) => state.changeRequest.projectReport
  );

  const projectReportDDList = useSelector(
    (state) => state.changeRequest.projectReportDDList
  );

  const noOfRecords = useSelector((state) => state.changeRequest.noOfRecords);
  const projectName = useSelector((state) => state.changeRequest.projectName);
  const columnJSON = useSelector((state) => state.changeRequest.columnJSON);

  const validationSchema = Yup.object().shape({
    comments: Yup.string().required("Comments is required"),
  });

  useEffect(() => {
    const projectcolumnJSON = !!columnJSON && JSON.parse(columnJSON);
    let convertedData = [];
    if (projectcolumnJSON && typeof projectcolumnJSON === "object") {
      convertedData = Object.keys(projectcolumnJSON).map((key) => ({
        columnName: key,
        alternateColumnName: projectcolumnJSON[key].ALternateColumnName,
        visible: projectcolumnJSON[key].Visible,
      }));
    }
    setColumnData(convertedData);
  }, [columnJSON]);

  function findAlternateName(name) {
    const lifeExpectancyColumn =
      !!columnData && columnData.find((column) => column.columnName === name);
    return lifeExpectancyColumn
      ? lifeExpectancyColumn.alternateColumnName
      : null;
  }
  function checkVisibility(name) {
    const lifeExpectancyColumn =
      !!columnData && columnData.find((column) => column.columnName === name);
    return lifeExpectancyColumn ? lifeExpectancyColumn.visible : true;
  }

  const handleReqForChange = () => {
    formik.handleSubmit();
  };

  const handleLightBoxClosed = () => {
    setModalImage(null);
    setIsOpenModalImage(false);
  };

  function closeModal() {
    setLoader(false);
    formik.resetForm();
    setId(0);
    setModelIsOpen(false);
    setIsApproved(null);
  }

  function openModal() {
    setModelIsOpen(true);
  }

  const formik = useFormik({
    initialValues: {
      comments: "",
    },
    validationSchema,
    onSubmit: async () => {
      setLoader(true);
      openModal(true);
      if (formik.isValid) {
        const res = await saveApproveRejectRequestForChangeDetails({
          projectId,
          id,
          ...formik.values,
          userId,
          isApproved,
        });
        closeModal();
        if (res.payload.result > 0) {
          Swal.fire({
            title: res?.payload?.message,
            confirmButtonColor: "#000000",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "confirm-button-swal",
            },
          });
          getProjectReportDataList(
            projectId,
            clientId,
            page,
            perPage,
            sortColumn,
            sortColumnDir,
            searchText,
            filters
          );
        }
      }
    },
  });

  // useEffect(() => {
  //   if (
  //     projects.projects.listData &&
  //     projects.projects.listData.length > 0 &&
  //     noOfRecords > 0
  //   ) {
  //     const firstProjectId = projects.projects.listData[0].id;
  //     getProjectReportDataListDash(
  //       firstProjectId,
  //       page,
  //       perPage,
  //       sortColumn,
  //       sortColumnDir,
  //       searchText
  //     );
  //   }
  // }, [projects]);

  const loading = useSelector((state) => state.changeRequest.loading);

  const ReqForChangeTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Request For Change
    </Tooltip>
  );
  const ViewImageTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View Image
    </Tooltip>
  );

  const ViewApproveTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Approve
    </Tooltip>
  );

  const ViewRejectTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Reject
    </Tooltip>
  );
  const ViewQRTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View QR
    </Tooltip>
  );

  function extractIdFromUrl(url) {
    const parts = url.split("/");
    return parts[parts.length - 1];
  }

  const viewQR = (row) => {
    if (!row.qrCode) return null;
    return (
      <div className={`actions`}>
        <OverlayTrigger placement="top" overlay={ViewQRTooltip}>
          <button
            onClick={() => {
              if (!!row.qrCode) {
                localStorage.setItem(
                  "targetLayoutToQRView",
                  "changeRequestProjectReports"
                );
                const itemId = extractIdFromUrl(row.qrCode);
                // const newItemUrl = `${window.location.origin}/viewitem/${itemId}`;
                const newItemUrl = `${config.WEB_URL}/viewitem/${itemId}`;
                //window.location.href = newItemUrl;
                window.open(newItemUrl, "_blank");
              }
            }}
            className="btn btn-sm bg-warning-subtle me-2"
          >
            <i className="bi bi-qr-code-scan" />
          </button>
        </OverlayTrigger>
      </div>
    );
  };

  const getProjectReportDataList = (
    project_id,
    client_id,
    page_number,
    page_size,
    sort_column_name,
    sort_column_dir,
    search_text,
    filters
  ) => {
    const data = {
      page_number: page_number,
      page_size: page_size,
      projectId: project_id,
      clientId: client_id,
      sort_column_name: !!sort_column_name ? sort_column_name : null,
      sort_column_dir: !!sort_column_dir ? sort_column_dir : null,
      search_text: !!search_text ? search_text : null,
      level: !!filters["level"].value
        ? filters["level"].value.toString()
        : filters["level"].value,
      room_number: !!filters["roomNumber"].value
        ? filters["roomNumber"].value.toString()
        : filters["roomNumber"].value,
      room_name: !!filters["roomName"].value
        ? filters["roomName"].value.toString()
        : filters["roomName"].value,
      description: !!filters["description"].value
        ? filters["description"].value.toString()
        : filters["description"].value,
      quantity: !!filters["quantity"].value
        ? filters["quantity"].value.toString()
        : filters["quantity"].value,
      item_type: !!filters["itemType"].value
        ? filters["itemType"].value.toString()
        : filters["itemType"].value,
      esfa_code: !!filters["esfaCode"].value
        ? filters["esfaCode"].value.toString()
        : filters["esfaCode"].value,
      condition: !!filters["condition"].value
        ? filters["condition"].value.toString()
        : filters["condition"].value,
      life_expectancy: !!filters["lifeExpectancy"].value
        ? filters["lifeExpectancy"].value.toString()
        : filters["lifeExpectancy"].value,
      colour: !!filters["colour"].value
        ? filters["colour"].value.toString()
        : filters["colour"].value,
      make_model: !!filters["makeModel"].value
        ? filters["makeModel"].value.toString()
        : filters["makeModel"].value,
      length: !!filters["length"].value
        ? filters["length"].value.toString()
        : filters["length"].value,
      depth: !!filters["depth"].value
        ? filters["depth"].value.toString()
        : filters["depth"].value,
      height: !!filters["height"].value
        ? filters["height"].value.toString()
        : filters["height"].value,
      primary_colour: !!filters["primaryColour"].value
        ? filters["primaryColour"].value.toString()
        : filters["primaryColour"].value,
      secondary_colour: !!filters["secondaryColour"].value
        ? filters["secondaryColour"].value.toString()
        : filters["secondaryColour"].value,
      abmormal: !!filters["abnormal"].value
        ? filters["abnormal"].value.toString()
        : filters["abnormal"].value,
      comments: !!filters["comments"].value
        ? filters["comments"].value.toString()
        : filters["comments"].value,
      cost: !!filters["cost"].value
        ? filters["cost"].value.toString()
        : filters["cost"].value,
      carbon_saving_kg: !!filters["carbonSavingKG"].value
        ? filters["carbonSavingKG"].value.toString()
        : filters["carbonSavingKG"].value,
      qr_code: !!filters["qrCode"].value
        ? filters["qrCode"].value.toString()
        : filters["qrCode"].value,
      legacy_code: !!filters["legacyCode"].value
        ? filters["legacyCode"].value.toString()
        : filters["legacyCode"].value,
      newRoomName: !!filters["newRoomName"].value
        ? filters["newRoomName"].value.toString()
        : filters["newRoomName"].value,
      newRoomNumber: !!filters["newRoomNumber"].value
        ? filters["newRoomNumber"].value.toString()
        : filters["newRoomNumber"].value,
    };
    dispatch(projectsReportForChangeRequested(data));
  };

  const handleSort = (column, sortDirection) => {
    // if (Object.keys(column).length !== 0 && !!sortDirection) {
    setSortColumn(column);
    setSortColumnDir(sortDirection);
    getProjectReportDataList(
      projectId,
      clientId,
      page,
      perPage,
      column,
      sortDirection,
      searchText,
      filters
    );
    // }
  };

  const template2 = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
        { label: 200, value: 200 },
        { label: 500, value: 500 },
        { label: 1000, value: 1000 },
        // { label: 'View All', value: options.totalRecords }
        // { label: 'View all', value: noOfRecords}
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={(e) => {
              setPerPage(e.value);
              setPage(1);
              setFirst(0);
              getProjectReportDataList(
                projectId,
                clientId,
                page,
                e.value,
                sortColumn,
                sortColumnDir,
                searchText,
                filters
              );
            }}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };
  const onPageChange = (e, index) => {
    setFirst(e.first);
    setPage(e.page + 1);
    setPerPage(e.rows);
    getProjectReportDataList(
      projectId,
      clientId,
      e.page + 1,
      e.rows,
      sortColumn,
      sortColumnDir,
      searchText,
      filters
    );
  };

  const rowClass = (data) => {
    return data.colour;
  };

  const rowItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option}</span>
      </div>
    );
  };

  const rowFilterTemplate = (options, data, alternateColumnName) => {
    return (
      <MultiSelect
        value={options.value}
        options={data}
        filter
        itemTemplate={rowItemTemplate}
        onChange={(e) => {
          options.filterCallback(e.value);
        }}
        placeholder={`Select ${alternateColumnName}`}
        className="p-column-filter"
        maxSelectedLabels={1}
      />
    );
  };

  const getFieldUniqueList = (reportData, fieldName) => {
    const setData = new Set();
    reportData.forEach((x) => {
      if (x[fieldName]) {
        const value = x[fieldName].toString().trim();
        setData.add(value);
      }
    });
    const uniqueData = Array.from(setData);
    return uniqueData.sort();
  };

  const initFilters = () => {
    const clearFilter = {
      level: { value: null, matchMode: FilterMatchMode.IN },
      roomNumber: { value: null, matchMode: FilterMatchMode.IN },
      roomName: { value: null, matchMode: FilterMatchMode.IN },
      description: { value: null, matchMode: FilterMatchMode.IN },
      quantity: { value: null, matchMode: FilterMatchMode.IN },
      itemType: { value: null, matchMode: FilterMatchMode.IN },
      esfaCode: { value: null, matchMode: FilterMatchMode.IN },
      condition: { value: null, matchMode: FilterMatchMode.IN },
      lifeExpectancy: { value: null, matchMode: FilterMatchMode.IN },
      colour: { value: null, matchMode: FilterMatchMode.IN },
      makeModel: { value: null, matchMode: FilterMatchMode.IN },
      length: { value: null, matchMode: FilterMatchMode.IN },
      depth: { value: null, matchMode: FilterMatchMode.IN },
      height: { value: null, matchMode: FilterMatchMode.IN },
      primaryColour: { value: null, matchMode: FilterMatchMode.IN },
      secondaryColour: { value: null, matchMode: FilterMatchMode.IN },
      abnormal: { value: null, matchMode: FilterMatchMode.IN },
      comments: { value: null, matchMode: FilterMatchMode.IN },
      // photograph: { value: null, matchMode: FilterMatchMode.IN },
      cost: { value: null, matchMode: FilterMatchMode.IN },
      carbonSavingKG: { value: null, matchMode: FilterMatchMode.IN },
      qrCode: { value: null, matchMode: FilterMatchMode.IN },
      legacyCode: { value: null, matchMode: FilterMatchMode.IN },
      newRoomName: { value: null, matchMode: FilterMatchMode.IN },
      newRoomNumber: { value: null, matchMode: FilterMatchMode.IN },
    };
    setFilters(clearFilter);
    getProjectReportDataList(
      projectId,
      clientId,
      page,
      perPage,
      sortColumn,
      sortColumnDir,
      searchText,
      clearFilter
    );
  };

  useEffect(() => {
    setProjectReportArr(projectReport);
  }, [projectReport]);

  useEffect(() => {
    const uniqueLevel = getFieldUniqueList(projectReportDDList, "level");
    const uniqueRoomNumber = getFieldUniqueList(
      projectReportDDList,
      "roomNumber"
    );
    const uniqueRoomName = getFieldUniqueList(projectReportDDList, "roomName");
    const uniqueDescriptions = getFieldUniqueList(
      projectReportDDList,
      "description"
    );
    const uniqueQuantity = getFieldUniqueList(projectReportDDList, "quantity");
    const uniqueItemType = getFieldUniqueList(projectReportDDList, "itemType");
    const uniqueEsfaCode = getFieldUniqueList(projectReportDDList, "esfaCode");
    const uniqueCondition = getFieldUniqueList(
      projectReportDDList,
      "condition"
    );
    const uniqueLifeExpectancy = getFieldUniqueList(
      projectReportDDList,
      "lifeExpectancy"
    );
    const uniqueColour = getFieldUniqueList(projectReportDDList, "colour");
    const uniqueMakeModel = getFieldUniqueList(
      projectReportDDList,
      "makeModel"
    );
    const uniqueLength = getFieldUniqueList(projectReportDDList, "length");
    const uniqueDepth = getFieldUniqueList(projectReportDDList, "depth");
    const uniqueHeight = getFieldUniqueList(projectReportDDList, "height");
    const uniquePrimaryColour = getFieldUniqueList(
      projectReportDDList,
      "primaryColour"
    );
    const uniqueSecondaryColour = getFieldUniqueList(
      projectReportDDList,
      "secondaryColour"
    );
    const uniqueAbnormal = getFieldUniqueList(projectReportDDList, "abnormal");
    const uniqueComments = getFieldUniqueList(projectReportDDList, "comments");
    const uniqueCost = getFieldUniqueList(projectReportDDList, "cost");
    const uniqueCarbonSavingKG = getFieldUniqueList(
      projectReportDDList,
      "carbonSavingKG"
    );
    const uniqueQRCode = getFieldUniqueList(projectReportDDList, "qrCode");
    const uniqueLegacyCode = getFieldUniqueList(
      projectReportDDList,
      "legacyCode"
    );
    const uniqueNewRoomName = getFieldUniqueList(
      projectReportDDList,
      "newRoomName"
    );
    const uniqueNewRoomNumber = getFieldUniqueList(
      projectReportDDList,
      "newRoomNumber"
    );

    setLevelFilter(uniqueLevel);
    setRoomNumberFilter(uniqueRoomNumber);
    setRoomNameFilter(uniqueRoomName);
    setDescriptionFilter(uniqueDescriptions);
    setQuantityFilter(uniqueQuantity);
    setItemTypeFilter(uniqueItemType);
    setEsfaCodeFilter(uniqueEsfaCode);
    setConditionFilter(uniqueCondition);
    setLifeExpectancyFilter(uniqueLifeExpectancy);
    setColourFilter(uniqueColour);
    setMakeModelFilter(uniqueMakeModel);
    setLengthFilter(uniqueLength);
    setDepthFilter(uniqueDepth);
    setHeightFilter(uniqueHeight);
    setPrimaryColourFilter(uniquePrimaryColour);
    setSecondaryColourFilter(uniqueSecondaryColour);
    setAbnormalFilter(uniqueAbnormal);
    setCommentsFilter(uniqueComments);
    setCostFilter(uniqueCost);
    setCarbonSavingKGFilter(uniqueCarbonSavingKG);
    setQRCodeFilter(uniqueQRCode);
    setLegacyCodeFilter(uniqueLegacyCode);
    setNewRoomNameFilter(uniqueNewRoomName);
    setNewRoomNumberFilter(uniqueNewRoomNumber);
  }, [projectReportDDList]);

  useEffect(() => {
    if (!!projectId) {
      dispatch(allProjectsReportListForChangeRequested(projectId));
    }
  }, [projectId]);

  useEffect(() => {
    if (!!projectId) {
      getProjectReportDataList(
        projectId,
        clientId,
        page,
        perPage,
        sortColumn,
        sortColumnDir,
        searchText,
        filters
      );
    }
  }, [projectId, searchText]);

  const header = (
    <div className="d-flex justify-content-between">
      <Btn
        type="button"
        icon="bi bi-funnel"
        label="Clear"
        className="btn btn-outline-dark d-flex align-items-center"
        outlined
        onClick={() => initFilters()}
      />
    </div>
  );

  const handleApproveRejectRequest = (isApproved, id) => {
    Swal.fire({
      title: `Are you sure you want to ${
        isApproved ? "accept" : "reject"
      } this request?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#000000",

      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      customClass: {
        confirmButton: "confirm-button-swal",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (isApproved) {
          openModal(true);
          setIsApproved(true);
        } else {
          const res = await saveApproveRejectRequestForChangeDetails({
            projectId,
            id,
            comments: "",
            userId,
            isApproved,
          });
          closeModal();
          if (res.payload.result > 0) {
            Swal.fire({
              title: res?.payload?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "confirm-button-swal",
              },
            });
            getProjectReportDataList(
              projectId,
              clientId,
              page,
              perPage,
              sortColumn,
              sortColumnDir,
              searchText,
              filters
            );
          }
        }
      } else {
        setIsApproved(null);
      }
    });
  };

  const updateProjectReportDetailsForChangeRequest = async (
    projectDetailsList
  ) => {
    return new Promise(async (resolve, reject) => {
      const transformedData = projectDetailsList.map((data, index) => {
        return {
          ...data,
          modifiedBy: loginUserId,
          modifiedDateTime: new Date(),
        };
      });
      const res = await dispatch(
        updateProjectReportForChangeRequest({
          requestParam: {
            projectReportModels: transformedData,
            isChangeRequest: true,
            approvalCommentedBy: loginUserId,
          },
        })
      );
      resolve(res);
    });
  };

  const ActionRow = (row) => {
    return (
      <>
        <div className={`actions`}>
          <OverlayTrigger placement="top" overlay={ViewApproveTooltip}>
            <div className="actions" onClick={() => {}}>
              <Link
                className="btn btn-sm bg-success-subtle"
                onClick={async () => {
                  // handleApproveRejectRequest(true);
                  // setId(row.id);

                  // Filter the projectReportArr to find the item with matching row.id
                  Swal.fire({
                    title: `Are you sure you want to approve this request?`,
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#000000",

                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                    customClass: {
                      confirmButton: "confirm-button-swal",
                    },
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      const filteredProjectReportArr = projectReportArr.filter(
                        (report) => report.id === row.id
                      );
                      const res =
                        await updateProjectReportDetailsForChangeRequest(
                          filteredProjectReportArr
                        );
                      if (res.payload.result > 0) {
                        dispatch(
                          allProjectsReportListForChangeRequested(projectId)
                        );
                        getProjectReportDataList(
                          projectId,
                          clientId,
                          page,
                          perPage,
                          sortColumn,
                          sortColumnDir,
                          searchText,
                          filters
                        );
                        Swal.fire({
                          title: res?.payload?.message,
                          confirmButtonColor: "#000000",
                          confirmButtonText: "OK",
                          customClass: {
                            confirmButton: "confirm-button-swal",
                          },
                        });
                      }
                    }
                  });
                }}
              >
                <i className="bi bi-check-circle"></i>
              </Link>
            </div>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={ViewRejectTooltip}>
            <button
              className="btn btn-sm bg-danger-subtle ms-2"
              onClick={() => {
                handleApproveRejectRequest(false, row.id);
                setId(row.id);
              }}
            >
              <i className="bi bi-x-circle"></i>
            </button>
          </OverlayTrigger>
        </div>
      </>
    );
  };

  const saveApproveRejectRequestForChangeDetails = async (requestDetails) => {
    return new Promise(async (resolve, reject) => {
      const res = await dispatch(
        saveApproveRejectRequestForChange({
          requestParam: {
            ProjectId: requestDetails.projectId,
            ProjectReportRowId: requestDetails.id,
            Comments: requestDetails.comments,
            CommentedBy: requestDetails.userId,
            IsApproved: requestDetails.isApproved,
          },
          isUpdated: true,
        })
      );
      resolve(res);
    });
  };

  const viewImage = (row) => {
    return (
      <>
        {!!row.photographName && (
          <div className={`actions`}>
            <OverlayTrigger placement="top" overlay={ViewImageTooltip}>
              <button
                onClick={() => {
                  if (!!row.photographName) {
                    // Split photographName into an array and map it to full URLs
                    const imagesArray = row.photographName
                      .split(",")
                      .map((name, index) => ({
                        url: `${IMAGE_BASE_URL}/${row.projectId}/${name}`,
                        title: `${name}`, // Set the title to the photograph name
                      }));
                    setModalImage(imagesArray);
                    // setModalImage(
                    //   `${IMAGE_BASE_URL}/${row.projectId}/${row.photographName}`
                    // );
                    setModalImageName(row.photographName);
                    setIsOpenModalImage(true);
                  } else {
                    Swal.fire({
                      title: "No image found!",
                      confirmButtonColor: "#000000",
                      confirmButtonText: "OK",
                      customClass: {
                        confirmButton: "confirm-button-swal",
                      },
                    });
                  }
                }}
                className="btn btn-sm bg-warning-subtle me-2"
              >
                <i className="bi bi-eye" />
              </button>
            </OverlayTrigger>
          </div>
        )}
      </>
    );
  };
  const onCellEditComplete = async (data) => {
    const index = data.rowIndex;
    const projectDetails = [...projectReportArr];
    const columnData = projectDetails[index];
  };

  const inputComponent = (options) => {
    const { rowIndex } = options;
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
          setProjectReportArr((prevArr) => {
            const newArray = [...prevArr];
            newArray[rowIndex] = {
              ...newArray[rowIndex],
              [options.field]: e.target.value,
            };
            return newArray;
          });
          //setIsLabelShow(true);
          //setIsChangesSaved(false);
        }}
      />
    );
  };

  const getEditorComponent = (options) => {
    return inputComponent(options);
  };

  return (
    <>
      <div className="mainContent px-4 pt-3">
        <div className="page-header mb-3">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <div
                className="page-sub-header"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <div className="page-title-right d-flex">
                  <Link
                    className="btn btn-outline-dark ms-2 d-flex"
                    to={`/changeRequestApproval`}
                  >
                    <i className="bi bi-arrow-left me-2"></i> Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="card shadow-sm border-0">
              <div className="card-body p-9">
                <div className="row">
                  <div className="col-lg-4 projectDetailReportWrap">
                    <div className="project-title-company-name pt-0 fs-5 mb-2">
                      {projectName}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="card shadow-sm border-0 reportTable">
              <div className="card-body p-9 table-font-12">
                <>
                  <DT
                    value={trimprojectReportArr}
                    rows={25}
                    size="small"
                    dataKey="id"
                    rowClassName={rowClass}
                    header={header}
                    filters={filters}
                    selection={selectedProjectDetails}
                    //selectionMode={enableRowEdit ? "multiple" : null}
                    onSelectionChange={(e) => {
                      setSelectedProjectDetails(e.value);
                    }}
                    className="text-center"
                    loading={loading}
                    globalFilterFields={["description"]}
                    sortField={sortColumn}
                    editMode="cell"
                    sortOrder={sortColumnDir === "asc" ? 1 : -1}
                    onSort={(data) => {
                      setSortColumn(data.sortField);
                      setSortColumnDir(data.sortOrder === 1 ? "asc" : "desc");
                      handleSort(
                        data.sortField,
                        data.sortOrder === 1 ? "asc" : "desc"
                      );
                    }}
                    onFilter={(e) => {
                      setFilters(e.filters);
                      getProjectReportDataList(
                        projectId,
                        clientId,
                        page,
                        perPage,
                        sortColumn,
                        sortColumnDir,
                        searchText,
                        e.filters
                      );
                    }}
                  >
                    <Column
                      header={
                        findAlternateName("QRCode")
                          ? findAlternateName("QRCode")
                          : "QR Code"
                      }
                      hidden={!checkVisibility("QRCode")}
                      filterField="qrCode"
                      sortable
                      field="qrCode"
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      showFilterMatchModes={false}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          qrCodeFilter,
                          findAlternateName("QRCode")
                            ? findAlternateName("QRCode")
                            : "QR Code"
                        )
                      }
                    />
                    <Column
                      header={
                        findAlternateName("LegacyCode")
                          ? findAlternateName("LegacyCode")
                          : "LegacyCode"
                      }
                      hidden={!checkVisibility("LegacyCode")}
                      filterField="legacyCode"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="legacyCode"
                      filter
                      showFilterMatchModes={false}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          legacyCodeFilter,
                          findAlternateName("LegacyCode")
                            ? findAlternateName("LegacyCode")
                            : "Legacy Code"
                        )
                      }
                    />
                    <Column
                      header={
                        findAlternateName("Level")
                          ? findAlternateName("Level")
                          : "Level"
                      }
                      hidden={!checkVisibility("Level")}
                      filterField="level"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="level"
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      showFilterMatchModes={false}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          levelFilter,
                          findAlternateName("Level")
                            ? findAlternateName("Level")
                            : "Level"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("RoomNumber")
                          ? findAlternateName("RoomNumber")
                          : "Room Number"
                      }
                      hidden={!checkVisibility("RoomNumber")}
                      filterField="roomNumber"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="roomNumber"
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      showFilterMatchModes={false}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          roomNumberFilter,
                          findAlternateName("RoomNumber")
                            ? findAlternateName("RoomNumber")
                            : "Room Number"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("RoomName")
                          ? findAlternateName("RoomName")
                          : "Room Name"
                      }
                      hidden={!checkVisibility("RoomName")}
                      filterField="roomName"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="roomName"
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      showFilterMatchModes={false}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          roomNameFilter,
                          findAlternateName("RoomName")
                            ? findAlternateName("RoomName")
                            : "Room Name"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("Description")
                          ? findAlternateName("Description")
                          : "Description"
                      }
                      hidden={!checkVisibility("Description")}
                      filterField="description"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="description"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          descriptionFilter,
                          findAlternateName("Description")
                            ? findAlternateName("Description")
                            : "Description"
                        )
                      }
                      style={{ minWidth: "14rem" }}
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("Quantity")
                          ? findAlternateName("Quantity")
                          : "Quantity"
                      }
                      hidden={!checkVisibility("Quantity")}
                      filterField="quantity"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="quantity"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          quantityFilter,
                          findAlternateName("Quantity")
                            ? findAlternateName("Quantity")
                            : "Quantity"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("ItemType")
                          ? findAlternateName("ItemType")
                          : "Item Type"
                      }
                      hidden={!checkVisibility("ItemType")}
                      filterField="itemType"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="itemType"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          itemTypeFilter,
                          findAlternateName("ItemType")
                            ? findAlternateName("ItemType")
                            : "Item Type"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("DFECode")
                          ? findAlternateName("DFECode")
                          : "DFE Code"
                      }
                      hidden={!checkVisibility("DFECode")}
                      filterField="esfaCode"
                      sortField="esfaCode"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="esfaCode"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          esfaCodeFilter,
                          findAlternateName("DFECode")
                            ? findAlternateName("DFECode")
                            : "DFE Code"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("Condition")
                          ? findAlternateName("Condition")
                          : "Condition"
                      }
                      hidden={!checkVisibility("Condition")}
                      filterField="condition"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="condition"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          conditionFilter,
                          findAlternateName("Condition")
                            ? findAlternateName("Condition")
                            : "Condition"
                        )
                      }
                      style={{ minWidth: "14rem" }}
                      bodyClassName="text-center"
                      // filterElement={(option) =>{
                      //   const cf = [...new Set(conditionFilter)]
                      //   rowFilterTemplate(option, cf)
                      // }}
                    />
                    <Column
                      header={
                        findAlternateName("LifeExpectancy")
                          ? findAlternateName("LifeExpectancy")
                          : "Life Expectancy"
                      }
                      hidden={!checkVisibility("LifeExpectancy")}
                      filterField="lifeExpectancy"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="lifeExpectancy"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          lifeExpectancyFilter,
                          findAlternateName("LifeExpectancy")
                            ? findAlternateName("LifeExpectancy")
                            : "Life Expectancy"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("MakeModel")
                          ? findAlternateName("MakeModel")
                          : "Make/Model"
                      }
                      hidden={!checkVisibility("MakeModel")}
                      filterField="makeModel"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="makeModel"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          makeModelFilter,
                          findAlternateName("MakeModel")
                            ? findAlternateName("MakeModel")
                            : "Make/Model"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("Length")
                          ? findAlternateName("Length")
                          : "Length"
                      }
                      hidden={!checkVisibility("Length")}
                      filterField="length"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="length"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          lengthFilter,
                          findAlternateName("Length")
                            ? findAlternateName("Length")
                            : "Length"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("Depth")
                          ? findAlternateName("Depth")
                          : "Depth"
                      }
                      hidden={!checkVisibility("Depth")}
                      filterField="depth"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="depth"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          depthFilter,
                          findAlternateName("Depth")
                            ? findAlternateName("Depth")
                            : "Depth"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("Height")
                          ? findAlternateName("Height")
                          : "Height"
                      }
                      hidden={!checkVisibility("Height")}
                      filterField="height"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="height"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          heightFilter,
                          findAlternateName("Height")
                            ? findAlternateName("Height")
                            : "Height"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("PrimaryColour")
                          ? findAlternateName("PrimaryColour")
                          : "Primary Colour"
                      }
                      hidden={!checkVisibility("PrimaryColour")}
                      filterField="primaryColour"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="primaryColour"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          primaryColourFilter,
                          findAlternateName("PrimaryColour")
                            ? findAlternateName("PrimaryColour")
                            : "Primary Colour"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("SecondaryColour")
                          ? findAlternateName("SecondaryColour")
                          : "Secondary Colour"
                      }
                      hidden={!checkVisibility("SecondaryColour")}
                      filterField="secondaryColour"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="secondaryColour"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          secondaryColourFilter,
                          findAlternateName("SecondaryColour")
                            ? findAlternateName("SecondaryColour")
                            : "Secondary Colour"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("DFEStandard")
                          ? findAlternateName("DFEStandard")
                          : "DFE Standard"
                      }
                      hidden={!checkVisibility("DFEStandard")}
                      filterField="abnormal"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="abnormal"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          abnormalFilter,
                          findAlternateName("DFEStandard")
                            ? findAlternateName("DFEStandard")
                            : "DFE Standard"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("Comments")
                          ? findAlternateName("Comments")
                          : "Comments"
                      }
                      hidden={!checkVisibility("Comments")}
                      filterField="comments"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="comments"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          commentsFilter,
                          findAlternateName("Comments")
                            ? findAlternateName("Comments")
                            : "Comments"
                        )
                      }
                      style={{ minWidth: "14rem" }}
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("Cost")
                          ? findAlternateName("Cost")
                          : "Cost"
                      }
                      hidden={!checkVisibility("Cost")}
                      filterField="cost"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="cost"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          costFilter,
                          findAlternateName("Cost")
                            ? findAlternateName("Cost")
                            : "Cost"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("CarbonSavingKG")
                          ? findAlternateName("CarbonSavingKG")
                          : "Carbon Saving KG"
                      }
                      hidden={!checkVisibility("CarbonSavingKG")}
                      filterField="carbonSavingKG"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="carbonSavingKG"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          carbonSavingKGFilter,
                          findAlternateName("CarbonSavingKG")
                            ? findAlternateName("CarbonSavingKG")
                            : "Carbon Saving KG"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("NewRoomName")
                          ? findAlternateName("NewRoomName")
                          : "New Room Name"
                      }
                      hidden={!checkVisibility("NewRoomName")}
                      filterField="newRoomName"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="newRoomName"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          newRoomNameFilter,
                          findAlternateName("NewRoomName")
                            ? findAlternateName("NewRoomName")
                            : "New Room Name"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header={
                        findAlternateName("NewRoomNumber")
                          ? findAlternateName("NewRoomNumber")
                          : "New Room Number"
                      }
                      hidden={!checkVisibility("NewRoomNumber")}
                      filterField="newRoomNumber"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="newRoomNumber"
                      showFilterMatchModes={false}
                      filter
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={getEditorComponent}
                      filterElement={(option) =>
                        rowFilterTemplate(
                          option,
                          newRoomNumberFilter,
                          findAlternateName("NewRoomNumber")
                            ? findAlternateName("NewRoomNumber")
                            : "New Room Number"
                        )
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      hidden={
                        !userRole &&
                        (userRole !== "Client" || userRole !== "ClientUser")
                      }
                      header={
                        findAlternateName("RemarksComment")
                          ? findAlternateName("RemarksComment")
                          : "Remarks"
                      }
                      body={(row) => row.remarksComment}
                      bodyClassName="text-center"
                    />
                    {/* <Column
                      hidden={
                        !userRole &&
                        (userRole !== "Client" || userRole !== "ClientUser")
                      }
                      header={
                        findAlternateName("ApprovalsComment")
                          ? findAlternateName("ApprovalsComment")
                          : "Approvals Comment"
                      }
                      body={(row) =>
                        !!row.remarksCommentedBy && row.approvalsComment == null
                          ? "Pending"
                          : row.approvalsComment
                      }
                      bodyClassName="text-center"
                    /> */}
                    <Column
                      hidden={
                        !userRole &&
                        (userRole !== "Client" || userRole !== "ClientUser")
                      }
                      header="Action"
                      body={ActionRow}
                      bodyClassName="text-center"
                    />

                    <Column
                      hidden={!checkVisibility("PhotographCode")}
                      header="View Image"
                      body={viewImage}
                      bodyClassName="text-center"
                    />

                    <Column
                      header="QRCode"
                      hidden
                      filterField="qrCode"
                      sortable
                      filterMenuStyle={{ width: "14rem" }}
                      field="qrCode"
                      showFilterMatchModes={false}
                      filter
                      filterElement={(option) =>
                        rowFilterTemplate(option, qrCodeFilter, "QRCode")
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      hidden={!checkVisibility("QRCode")}
                      header="View QR"
                      body={viewQR}
                      bodyClassName="text-center"
                    />
                  </DT>
                  <Paginator
                    template={template2}
                    first={first}
                    rows={perPage}
                    totalRecords={noOfRecords}
                    onPageChange={(e) => onPageChange(e, 1)}
                    className="justify-content-end"
                  />
                </>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {isOpenModalImage && (
        <Lightbox
          image={modalImage}
          onClose={() => {
            setModalImage(null);
            setIsOpenModalImage(false);
          }}
          title={modalImageName}
        />
      )} */}
      {isOpenModalImage && modalImage.length === 1 && (
        <Lightbox
          image={modalImage[0].url}
          title={modalImage[0].title}
          onClose={() => {
            handleLightBoxClosed();
          }}
        />
      )}

      {isOpenModalImage && modalImage.length > 1 && (
        <Lightbox
          images={modalImage}
          onClose={() => {
            handleLightBoxClosed();
          }}
        />
      )}
      <Modal
        show={modalIsOpen}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => closeModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Approval Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label htmlFor="comments" className="form-label">
                Comments<span className="text-danger"> * </span>
              </label>
              <textarea
                name="comments"
                className={`form-control ${
                  formik.touched.comments && formik.errors.comments
                    ? "is-invalid"
                    : ""
                }`}
                id="comments"
                placeholder="Enter Comments"
                value={!!formik.values.comments ? formik.values.comments : ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows={5}
              />
              {formik.touched.comments && formik.errors.comments && (
                <div className="invalid-feedback">{formik.errors.comments}</div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={closeModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleReqForChange}
            disabled={loader}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangeRequestProjectReports;
