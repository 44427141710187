import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, Navigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import history from '../../history';
import { changePassword } from "../../../Redux/Actions/changePasswordActions";
// import logo from "../../../Assets/images/logo.png";
import { secret_key } from "../../../APIProxy/secret";
import * as CryptoJS from "crypto-js";
import Swal from "sweetalert2";

const validationSchema = Yup.object().shape({
  //email: Yup.string().email("Email is not valid").required("Email is required"),
//   newPassword: Yup.string().required("Password is required"),
//   confirmPassword: Yup.string().required("Password is required")
  oldPassword: Yup.string().min(6, "Password must be at least 6 characters").required("Old Password is required"),
  newPassword: Yup.string().min(6, 'Password must be at least 6 characters').required('New Password is required'),
  confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match').required('Confirm Password is required'),
});

const ChangePassword = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userType, setUserType] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (data) => {
      const encryptedUserId = Cookies.get('userId');
      const decryptedBytes = !!encryptedUserId && CryptoJS.AES.decrypt(encryptedUserId, secret_key);
      const userId = decryptedBytes.toString(CryptoJS.enc.Utf8);

      var dataModel = {
        id: userId,
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
        modifiedBy: userId

      };
      setIsSubmitting(true);
      if (formik.isValid) {
        dispatch(changePassword(dataModel)).then((data) => {
          var res = data.payload;
          if(res.result === 1){
            Swal.fire({
              title: res?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: 'confirm-button-swal'
              }
            })
                setErrorMessage('');
                  navigate('/');
          }
          else if(res.result === 2)
          {
            setIsSubmitting(false);
            Swal.fire({
              title: res?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: 'confirm-button-swal'
              }
            })
            setErrorMessage('Password do not match. Please try again')
          }
       })
      } 
    },
  });
  const handleChangePassword = () => {
    setErrorMessage('');
    formik.handleSubmit();
  };
  useEffect(() => {
    const userRole = Cookies.get("userRole");
      if (userRole && userRole === "Admin") {
        setUserType("Admin");
      }
      if (userRole && userRole === "Employee") {
        setUserType("Employee");
      }
      if (userRole && userRole === "Client") {
        setUserType("Client");
      }
  });

  return (
    <>
      <div className="page-title-right d-flex">
          <div className="symbol-project bg-light"></div>
          {(userType === "Admin" || userType === "Employee") && (
            <Link
              className="btn btn-outline-dark ms-2 d-flex"
              to={`/admin`}
            >
              <i className="bi bi-arrow-left me-2"></i> Back
            </Link>
          )}
          {(userType === "Client") && (
            <Link
              className="btn btn-outline-dark ms-2 d-flex"
              to={`/dashboard`}
            >
              <i className="bi bi-arrow-left me-2"></i> Back
            </Link>
          )}
      </div>
      <div className="d-flex align-items-center h-100 login-set">
        <div className="login-wrapper">
          <div className="container">
            <div className="loginbox">
              <div className="login-right">
                <div className="login-right-wrap">
                  <div className="text-center mb-3">
                    <img src={"/Assets/images/logo.png"} className="img-fluid" style={{ height: "60px" }} alt="Logo" />
                    {/* <img src="Assets/images/logo.png" style={{ height: "60px" }} alt="Logo" /> */}
                  </div>
                  <h2>Change Password</h2>
                  <form onSubmit={formik.handleSubmit}>
                  <div className="form-group mb-4">
                      <label>Old password <span className="text-danger">*</span></label>
                      <div className="position-relative">
                        <input
                          className={`form-control ${formik.touched.oldPassword && formik.errors.oldPassword ? "is-invalid" : ""}`}
                          type={showOldPassword? "text" : "password"}
                          id="oldPassword"
                          placeholder="Enter Old Password"
                          value={formik.values.oldPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <span className="bi bi-eye-fill login-show-password" onClick={() => setShowOldPassword(!showOldPassword)}></span>
                      </div>
                      {formik.touched.oldPassword && formik.errors.oldPassword && (
                        <div className="invalid-feedback">{formik.errors.oldPassword}</div>
                      )}
                    </div>
                    <div className="error-message">{errorMessage}</div>

                    <div className="form-group mb-4 password-control position-relative">
                      <label>New password <span className="text-danger">*</span></label>
                      <input
                          className={`form-control ${formik.touched.newPassword && formik.errors.newPassword ? "is-invalid" : ""}`}
                          type={showNewPassword ? "text" : "password"}
                          id="newPassword"
                          placeholder="Enter New Password"
                          value={formik.values.newPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <span className="bi bi-eye-fill login-show-password" onClick={() => setShowNewPassword(!showNewPassword)}></span>
                      {formik.touched.newPassword && formik.errors.newPassword && (
                        <div className="invalid-feedback">{formik.errors.newPassword}</div>
                      )}
                    </div>
                    
                    <div className="form-group mb-4 password-control position-relative">
                      <label>Confirm new password <span className="text-danger">*</span></label>
                      <input
                          className={`form-control ${formik.touched.confirmPassword && formik.errors.confirmPassword ? "is-invalid" : ""}`}
                          type={showConfirmPassword ? "text" : "password"}
                          id="confirmPassword"
                          placeholder="Confirm New Password"
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <span className="bi bi-eye-fill login-show-password" onClick={() => setShowConfirmPassword(!showConfirmPassword)}></span>
                      {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                        <div className="invalid-feedback">{formik.errors.confirmPassword}</div>
                      )}
                    </div>
                    <div className="forgotpass mb-4">
                      {/* <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                          Remember me
                        </label>
                      </div> */}
                      {/* <a href="#" className="btn-link fw-bold">Forgot Password?</a> */}
                    </div>
                    {/* <p className="account-subtitle">Need an account? <a href="#" className="btn-link">Sign Up</a></p> */}
                    <div className="d-grid gap-2">
                      <button className="btn btn-primary btn-block" type="button" onClick={handleChangePassword} disabled={isSubmitting}>
                        Change Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
