import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie"
import CryptoJS from "crypto-js"
import { CHANGE_PASSWORD } from "../../APIProxy/api";

//Change password APIs
const token = !!Cookies.get("COOKIE_JWTTOKEN") && Cookies.get("COOKIE_JWTTOKEN");
let decodedToken = '';

if (token) {
  const decryptedToken = CryptoJS.AES.decrypt(token, "CRYPTO_SECRET_KEY");
  decodedToken = decryptedToken.toString(CryptoJS.enc.Utf8);
}
export const changePassword = createAsyncThunk(
  "changePassword",
  async (user) => {
    const response = await axios.post(CHANGE_PASSWORD,user,{
      headers: {'Authorization': `Bearer ${decodedToken}`},
    });
    return response.data;
  }
);


