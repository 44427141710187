import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editClient } from "../../Redux/Actions/usersActions";
import { listAllUsers } from "../../Redux/Actions/allUsersActions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Loader from "../Common/Loader/loader";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { capitalizeWords } from "../Common/capitalizeWord";
const phoneRegExp =
  /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
const nameRegExp = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required").min(3, "Name is too short").matches(nameRegExp, 'Name is not valid'),//.max(15, "Name is too long"),
  //contactPerson: Yup.string().required('Contact Person is required').min(3, "Contact Person Name is too short"), //.matches(nameRegExp, 'Contact Person Name is not valid').max(15, "Contact Person  is too long"),
  contactNo: Yup.string()
    .matches(phoneRegExp, "Contact number is not valid")
    .min(6, "Contact number is too short")
    .max(20, "Contact number is too long"), //.required('Contact Number is required')
  //location: Yup.string().required('Location is required').matches(nameRegExp, 'Location is not valid').min(3, "Location is too short").max(15, "Location is too long"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export default function RegisterClient() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector((state) => state.allUsers);
  const loading = useSelector((state) => state.allUsers.loading);
  const [roleID, setRoleID] = useState(2);
  // const [name, setName] = useState('');
  const [email, setEmail] = useState("");
  // const [location, setLocation] = useState('');
  // const [contactNo, setContactNo] = useState('');
  // const [contactPerson, setContactPerson] = useState('');
  // const [isDeleted, setIsDeleted] = useState(0);
  const [loadingMailToAdmin, setLoadingMailToAdmin] = useState(false);
  const [data, setUsersdata] = useState("");
  const [id, setId] = useState();
  const { userId } = useParams();
  const [updateList, setUpdateList] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  useEffect(() => {
    dispatch(listAllUsers());
  }, [dispatch, updateList]);

  useEffect(() => {
    if (users?.allUsers?.listData?.length)
      setUsersdata(users?.allUsers?.listData);
  }, [users?.allUsers?.listData]);

  useEffect(() => {
    if (!loadingMailToAdmin && updateList) {
      const timeoutId = setTimeout(() => {
        navigate("/");
      }, 5000); // Adjust the delay time as needed
      return () => clearTimeout(timeoutId);
    }
  }, [loadingMailToAdmin, updateList, navigate]);

  useEffect(() => {
    if (userId) {
      const user = data && data.find((item) => item.enID === userId); //item.enID === userId);
      user && user.email && setEmail(user.email);
      user && user.id && setId(user.id)
    }
    
  }, [dispatch, userId, data]);

  const handleCancle = () => {
    formik.resetForm();
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      // contactPerson: '',
      contactNo: "0000000000",
      // location: '',
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async () => {
      if (formik.isValid) {
        try {
          setLoadingMailToAdmin(true);
          const user = data && data.find((item) => item.id === id);
          const res = await dispatch(
            editClient({
              ...formik.values,
              id: user.id,
              email,
              roleID : user.roleID,
              createdBy:user?.createdBy,
              isActive: true,
            })
          ); // password: user.password,
          const name = formik.values.name;
          const userPassword = formik.values.password;
          Swal.fire({
            title: res?.payload?.message,
            confirmButtonColor: "#000000",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: 'confirm-button-swal'
            }
          })
          setLoadingMailToAdmin(false);
          setUpdateList(true);
          handleCancle();
        } catch (error) {
          console.error("Error sending mail to admin:", error);
          setLoadingMailToAdmin(false);
        }
      }
    },
  });
  const handleAddUser = () => {
    formik.handleSubmit();
  };

  return (
    <>
      {/* {(decryptIdLoading || loadingMailToAdmin) && <Loader />} */}
      {loading && <Loader />}
      <div className="container">
        <div className="row mt-4 register-client-container">
          {/* <div className="row mt-4"> */}
          <div className="col-md-8 mt-5">
            {/* <div className="mainContent px-4 pt-3"> */}
            <div className="page-header mb-3">
              <div className="row align-items-center">
                <div className="col-sm-12">
                  <div className="page-sub-header">
                    <h3 className="page-title">Registration</h3>
                    {/* <div className="symbol-project bg-light">
                                <img src="Assets/images/project-logo/project1.png" alt="image" className="p-2"/>
                            </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="card shadow-sm border-0">
                  <div className="card-body p-9">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label for="name" className="form-label">
                          Name<span className="text-danger"> * </span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.touched.name && formik.errors.name
                              ? "is-invalid"
                              : ""
                          }`}
                          id="name"
                          placeholder="Enter Name"
                          value={!!formik.values.name ? capitalizeWords(formik.values.name) : ''}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className="invalid-feedback">
                            {formik.errors.name}
                          </div>
                        )}
                      </div>
                      {/* <div className="col-md-6 mb-3">
                                        <label for="email" className="form-label">Email Address</label>
                                        <input type="text" className="form-control" id="email" placeholder="Enter Email" value={email} readOnly />
                                    </div> */}
                      {/* <div className="col-md-6 mb-3">
                                        <label for="contactPerson" className="form-label">Contact Person</label>
                                        <input type="text" className={`form-control ${formik.touched.contactPerson && formik.errors.contactPerson ? 'is-invalid' : ''}`} id="contactPerson" placeholder="Contact Person" value={formik.values.contactPerson} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        {formik.touched.contactPerson && formik.errors.contactPerson &&
                                            <div className='invalid-feedback'>{formik.errors.contactPerson}</div>}
                                    </div> */}
                      <div className="col-md-6 mb-3">
                        <label for="contactNo" className="form-label">
                          Contact Number
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.touched.contactNo && formik.errors.contactNo
                              ? "is-invalid"
                              : ""
                          }`}
                          id="contactNo"
                          placeholder="Enter Contact Number"
                          value={formik.values.contactNo}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.contactNo &&
                          formik.errors.contactNo && (
                            <div className="invalid-feedback">
                              {formik.errors.contactNo}
                            </div>
                          )}
                      </div>

                      <div className="col-md-6 mb-3 password-control position-relative">
                        <label for="password" className="form-label">
                          Enter password<span className="text-danger"> * </span>
                        </label>
                          <input
                            type={showPassword ? "text" : "password"}
                            className={`form-control ${
                              formik.touched.password && formik.errors.password
                                ? "is-invalid"
                                : ""
                            }`}
                            id="password"
                            placeholder="Password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <span
                            className="bi bi-eye-fill login-register-show-password"
                            onClick={() => setShowPassword(!showPassword)}
                          ></span>
                        {formik.touched.password && formik.errors.password && (
                          <div className="invalid-feedback">
                            {formik.errors.password}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 mb-3 password-control position-relative">
                        <label for="confirmPassword" className="form-label">
                          Confirm Password
                          <span className="text-danger"> * </span>
                        </label>
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            className={`form-control ${
                              formik.touched.confirmPassword &&
                              formik.errors.confirmPassword
                                ? "is-invalid"
                                : ""
                            }`}
                            id="confirmPassword"
                            placeholder="Confirm Password"
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <span
                            className="bi bi-eye-fill login-register-show-password"
                            onClick={() =>
                              setConfirmShowPassword(!showConfirmPassword)
                            }
                          ></span>
                        {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword && (
                            <div className="invalid-feedback">
                              {formik.errors.confirmPassword}
                            </div>
                          )}
                      </div>
                      {/* <div className="col-md-12 mb-3">
                                        <label for="location" className="form-label">Location</label>
                                        <input type="text" className={`form-control ${formik.touched.location && formik.errors.location ? 'is-invalid' : ''}`} id="location" placeholder="Location" value={formik.values.location} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        {formik.touched.location && formik.errors.location &&
                                            <div className='invalid-feedback'>{formik.errors.location}</div>}
                                    </div> */}
                      <div className="col-md-12 mb-3 text-end">
                        <button
                          type="button"
                          className="btn btn-warning mx-2"
                          onClick={handleCancle}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary mx-2"
                          onClick={handleAddUser}
                          disabled={loadingMailToAdmin}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
