import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie"
import CryptoJS from "crypto-js"
import {
  API_LIST_CLIENT_PROJECT_REPORT,
  API_PROJECT_COLOUR,
} from "../../APIProxy/api";

//Project Report APIs
const token = !!Cookies.get("COOKIE_JWTTOKEN") && Cookies.get("COOKIE_JWTTOKEN");
let decodedToken = '';

if (token) {
  const decryptedToken = CryptoJS.AES.decrypt(token, "CRYPTO_SECRET_KEY");
  decodedToken = decryptedToken.toString(CryptoJS.enc.Utf8);
}
export const clientProjectsReport = createAsyncThunk(
  "project/clientProjectsReport",
  async (data) => {
    const response = await axios.get(`${API_LIST_CLIENT_PROJECT_REPORT}`,{
      headers: {'Authorization': `Bearer ${decodedToken}`},
      params: data,
    });
    return response.data;
  }
);

//Project Colour
export const projectColour = createAsyncThunk(
  "project/projectColour",
  async () => {
    const response = await axios.get(API_PROJECT_COLOUR, {
      headers: {'Authorization': `Bearer ${decodedToken}`},
    },);
    return response.data;
  }
);
