/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import * as Yup from "yup";
import {
  addProjectDetails,
  allProjectDetailsList,
  deleteProjectDetails,
  getLookupColumnValue,
  projectDetailsHistoryList,
  // addProjectDetails,
  projectDetailsList,
  publishProject,
  updatePublishProjectStatus,
} from "../../Redux/Actions/projectsActions";
import DataTable from "react-data-table-component";
import { DataTable as DT } from "primereact/datatable";
import Loader from "../Common/Loader/loader";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import Cookies from "js-cookie";
import * as CryptoJS from "crypto-js";
import { secret_key } from "../../APIProxy/secret";
import Swal from "sweetalert2";
import { Field, Form, Formik, useFormik } from "formik";
import withReactContent from "sweetalert2-react-content";
import history from "../history";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
import { Button as Btn } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import config from "../../config";

const ProjectDetails = () => {
  const IMAGE_BASE_URL = config.LOGO_URL;
  const { projectId, clientId } = useParams();
  const cookieUserId = Cookies.get("userId");

  const loginUserId = !!cookieUserId
    ? CryptoJS.AES.decrypt(cookieUserId, secret_key).toString(CryptoJS.enc.Utf8)
    : null;
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    surveyorName: { value: null, matchMode: FilterMatchMode.IN },
    roomNumber: { value: null, matchMode: FilterMatchMode.IN },
    roomName: { value: null, matchMode: FilterMatchMode.IN },
    rawDescription: { value: null, matchMode: FilterMatchMode.IN },
    description: { value: null, matchMode: FilterMatchMode.IN },
    quantity: { value: null, matchMode: FilterMatchMode.IN },
    itemType: { value: null, matchMode: FilterMatchMode.IN },
    esfaCode: { value: null, matchMode: FilterMatchMode.IN },
    condition: { value: null, matchMode: FilterMatchMode.IN },
    makeModel: { value: null, matchMode: FilterMatchMode.IN },
    length: { value: null, matchMode: FilterMatchMode.IN },
    depth: { value: null, matchMode: FilterMatchMode.IN },
    height: { value: null, matchMode: FilterMatchMode.IN },
    rawPrimaryColour: { value: null, matchMode: FilterMatchMode.IN },
    primaryColour: { value: null, matchMode: FilterMatchMode.IN },
    rawSecondaryColour: { value: null, matchMode: FilterMatchMode.IN },
    secondaryColour: { value: null, matchMode: FilterMatchMode.IN },
    abnormal: { value: null, matchMode: FilterMatchMode.IN },
    comments: { value: null, matchMode: FilterMatchMode.IN },
    // photograph: { value: null, matchMode: FilterMatchMode.IN },
    cost: { value: null, matchMode: FilterMatchMode.IN },
    descriptionH: { value: null, matchMode: FilterMatchMode.IN },
    carbonSavingKG: { value: null, matchMode: FilterMatchMode.IN },
    qrCode: { value: null, matchMode: FilterMatchMode.IN },
  });
  const [selectedProjectDetails, setSelectedProjectDetails] = useState(null);
  const [surveyorNameFilter, setSurveyorNameFilter] = useState(null);
  const [descriptionFilter, setDescriptionFilter] = useState(null);
  const [rawDescriptionFilter, setRawDescriptionFilter] = useState(null);
  const [roomNumberFilter, setRoomNumberFilter] = useState(null);
  const [roomNameFilter, setRoomNameFilter] = useState(null);
  const [quantityFilter, setQuantityFilter] = useState(null);
  const [itemTypeFilter, setItemTypeFilter] = useState(null);
  const [esfaCodeFilter, setEsfaCodeFilter] = useState(null);
  const [conditionFilter, setConditionFilter] = useState(null);
  const [makeModelFilter, setMakeModelFilter] = useState(null);
  const [lengthFilter, setLengthFilter] = useState(null);
  const [depthFilter, setDepthFilter] = useState(null);
  const [heightFilter, setHeightFilter] = useState(null);
  const [rawPrimaryColourFilter, setRawPrimaryColourFilter] = useState(null);
  const [primaryColourFilter, setPrimaryColourFilter] = useState(null);
  const [rawSecondaryColourFilter, setRawSecondaryColourFilter] =
    useState(null);
  const [secondaryColourFilter, setSecondaryColourFilter] = useState(null);
  const [abnormalFilter, setAbnormalFilter] = useState(null);
  const [commentsFilter, setCommentsFilter] = useState(null);
  // const [photographFilter, setPhotographFilter] = useState(null);
  const [costFilter, setCostFilter] = useState(null);
  const [descriptionHFilter, setDescriptionHFilter] = useState(null);
  const [carbonSavingKGFilter, setCarbonSavingKGFilter] = useState(null);
  const [qrCodeFilter, setQrCodeFilter] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [searchHistoryText, setSearchHistoryText] = useState(null);
  const [projectDetailsArr, setProjectDetailsArr] = useState([]);
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortColumnDir, setSortColumnDir] = useState(null);

  const [projectDetailsId, setProjectDetailsId] = useState(null);
  const [historyPage, setHistoryPage] = useState(1);
  const [historyPerPage, setHistoryPerPage] = useState(25);
  const [historySortColumn, setHistorySortColumn] = useState(null);
  const [historySortColumnDir, setHistorySortColumnDir] = useState(null);

  const [isLabelShow, setIsLabelShow] = useState(true);
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const [isChangesSaved, setIsChangesSaved] = useState(true);
  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: "",
  });
  const [isVisibleDialog, setVisibleDialog] = useState(false);
  const [enableRowEdit, setEnableRowEdit] = useState(false);
  const [showBulkEditableGrid, setShowBulkEditableGrid] = useState(false);

  const projectDetails = useSelector((state) => state.projects.projectDetails);
  const allProjectDetailsLists = useSelector(
    (state) => state.projects.allProjectDetailsLists
  );
  const projectName = useSelector((state) => state.projects.projectName);
  const clientName = useSelector((state) => state.projects.clientName);
  const isProjectPublished = useSelector(
    (state) => state.projects.isProjectPublished
  );
  const email = useSelector((state) => state.projects.clientEmail);
  const noOfRecords = useSelector((state) => state.projects.noOfRecords);

  const projectDetailsHistory = useSelector(
    (state) => state.projectDetails.projectDetailsHistory
  );
  const noOfRecordsHistory = useSelector(
    (state) => state.projectDetails.noOfRecords
  );

  const loading = useSelector((state) => state.projects.loading);
  const historyLoading = useSelector((state) => state.projectDetails.loading);

  const trimprojectDetailsArr =
    !!projectDetailsArr &&
    projectDetailsArr.map((item) => {
      const trimmedItem = {};
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          trimmedItem[key] =
            typeof item[key] === "string" ? item[key].trim() : item[key];
        }
      }
      return trimmedItem;
    });

  const userRole = Cookies.get("userRole");

  const ViewHistoryTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View History
    </Tooltip>
  );

  const DeleteProjectDetailsTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete
    </Tooltip>
  );

  const PublishProjectDetailsTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Publish
    </Tooltip>
  );

  const rowItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option}</span>
      </div>
    );
  };

  const rowFilterTemplate = (options, data) => {
    return (
      <MultiSelect
        value={options.value}
        options={data}
        filter
        itemTemplate={rowItemTemplate}
        onChange={(e) => {
          options.filterCallback(e.value);
        }}
        placeholder={`Select ${options.field}`}
        className="p-column-filter"
        maxSelectedLabels={1}
      />
    );
  };

  const ViewQRTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View QR
    </Tooltip>
  );

  // const getFieldUniqueList = (reportData, fieldName) => {
  //   const setData = new Set();
  //   const uniqueData = reportData.reduce((acc, x) => {
  //     if (!setData.has(`${x[fieldName]}`) && !!x[fieldName]) {
  //       setData.add(`${x[fieldName]}`);
  //       acc.push(x[fieldName]);
  //     }
  //     return acc;
  //   }, []);
  //   return uniqueData;
  // };
  const getFieldUniqueList = (reportData, fieldName) => {
    const setData = new Set();
    reportData.forEach((x) => {
      if (x[fieldName]) {
        const value = x[fieldName].toString().trim();
        setData.add(value);
      }
    });
    const uniqueData = Array.from(setData);
    return uniqueData.sort();
  };

  const customStylesDataTable = {
    rows: {
      style: {
        width: "auto",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "Bold",
        width: "auto",
      },
    },
  };

  function extractIdFromUrl(url) {
    const parts = url.split("/");
    return parts[parts.length - 1];
  }

  const viewQR = (row) => {
    if (!row.qrCode) return null;
    return (
      <div className={`actions`}>
        <OverlayTrigger placement="top" overlay={ViewQRTooltip}>
          <button
            onClick={() => {
              if (!!row.qrCode) {
                const itemId = extractIdFromUrl(row.qrCode);
                // const newItemUrl = `${window.location.origin}/viewitem/${itemId}`;
                const newItemUrl = `${config.WEB_URL}/viewitem/${itemId}`;
                //window.location.href = newItemUrl;
                window.open(newItemUrl, "_blank");
              }
              // else {
              //   Swal.fire({
              //     title: "Not found!",
              //     confirmButtonText: "OK",
              //   });
              // }
            }}
            className="btn btn-sm bg-warning-subtle me-2"
          >
            <i className="bi bi-qr-code-scan" />
          </button>
        </OverlayTrigger>
      </div>
    );
  };

  const onCellEditComplete = async (data) => {
    const index = data.rowIndex;
    const projectDetails = [...projectDetailsArr];
    const columnData = projectDetails[index];
    const res = await getLookUpColumnValue(data.field, data.newRowData);
    switch (data.field) {
      case "rawDescription":
        setProjectDetailsArr((prevArr) => {
          const newArray = [...prevArr];
          newArray[index] = {
            ...newArray[index],
            itemType: res.payload.itemType,
            description: res.payload.description,
            esfaCode: res.payload.esfaCode,
            abnormal: res.payload.abnormal,
            cost: res.payload.cost,
            carbonSavingKG: res.payload.carbonSavingKG,
          };
          return newArray;
        });
        break;
      case "description":
        setProjectDetailsArr((prevArr) => {
          const newArray = [...prevArr];
          newArray[index] = {
            ...newArray[index],
            itemType: res.payload.itemType,
            esfaCode: res.payload.esfaCode,
            abnormal: res.payload.abnormal,
            cost: res.payload.cost,
            carbonSavingKG: res.payload.carbonSavingKG,
          };
          return newArray;
        });
        break;
      case "esfaCode":
        setProjectDetailsArr((prevArr) => {
          const newArray = [...prevArr];
          newArray[index] = {
            ...newArray[index],
            esfaCode: columnData.esfaCode,
            abnormal: res.payload.abnormal,
          };
          return newArray;
        });
        break;
      case "height":
        setProjectDetailsArr((prevArr) => {
          const newArray = [...prevArr];
          newArray[index] = {
            ...newArray[index],
            esfaCode: res.payload.esfaCode,
            abnormal: res.payload.abnormal,
            cost: res.payload.cost,
          };
          return newArray;
        });
        break;
      case "depth":
        setProjectDetailsArr((prevArr) => {
          const newArray = [...prevArr];
          newArray[index] = {
            ...newArray[index],
            esfaCode: res.payload.esfaCode,
            abnormal: res.payload.abnormal,
            cost: res.payload.cost,
          };
          return newArray;
        });
        break;
      case "length":
        setProjectDetailsArr((prevArr) => {
          const newArray = [...prevArr];
          newArray[index] = {
            ...newArray[index],
            esfaCode: res.payload.esfaCode,
            abnormal: res.payload.abnormal,
            cost: res.payload.cost,
          };
          return newArray;
        });
        break;
      case "rawPrimaryColour":
        setProjectDetailsArr((prevArr) => {
          const newArray = [...prevArr];
          newArray[index] = {
            ...newArray[index],
            primaryColour: res.payload.primaryColour,
          };
          return newArray;
        });
        break;
      case "rawSecondaryColour":
        setProjectDetailsArr((prevArr) => {
          const newArray = [...prevArr];
          newArray[index] = {
            ...newArray[index],
            secondaryColour: res.payload.secondaryColour,
          };
          return newArray;
        });
        break;
      default:
        break;
    }
  };

  const onEditableRowCellEditComplete = async (data) => {
    const index = data.rowIndex;
    const projectDetails = [...selectedProjectDetails];
    const columnData = projectDetails[index];
    const res = await getLookUpColumnValue(
      data.field,
      !!data.newRowData ? data.newRowData : data.rowData
    );
    switch (data.field) {
      case "rawDescription":
        // setSelectedProjectDetails((prevArr) => {
        //   const newArray = [...prevArr];
        //   newArray[index] = {
        //     ...newArray[index],
        //     itemType: res.payload.itemType,
        //     description: res.payload.description,
        //     esfaCode: res.payload.esfaCode,
        //     abnormal: res.payload.abnormal,
        //     cost: res.payload.cost,
        //     carbonSavingKG: res.payload.carbonSavingKG,
        //   };
        //   return newArray;
        // });
        // const newValue = e.target.value;
        setSelectedProjectDetails((prevArr) => {
          return prevArr.map((item, index) => {
            return {
              ...item,
              itemType: res.payload.itemType,
              description: res.payload.description,
              esfaCode: res.payload.esfaCode,
              abnormal: res.payload.abnormal,
              cost: res.payload.cost,
              carbonSavingKG: res.payload.carbonSavingKG,
            };
          });
        });
        break;
      case "description":
        // setSelectedProjectDetails((prevArr) => {
        //   const newArray = [...prevArr];
        //   newArray[index] = {
        //     ...newArray[index],
        //     itemType: res.payload.itemType,
        //     esfaCode: res.payload.esfaCode,
        //     abnormal: res.payload.abnormal,
        //     cost: res.payload.cost,
        //     carbonSavingKG: res.payload.carbonSavingKG,
        //   };
        //   return newArray;
        // });
        setSelectedProjectDetails((prevArr) => {
          return prevArr.map((item, index) => {
            return {
              ...item,
              itemType: res.payload.itemType,
              esfaCode: res.payload.esfaCode,
              abnormal: res.payload.abnormal,
              cost: res.payload.cost,
              carbonSavingKG: res.payload.carbonSavingKG,
            };
          });
        });
        break;
      case "esfaCode":
        // setSelectedProjectDetails((prevArr) => {
        //   const newArray = [...prevArr];
        //   newArray[index] = {
        //     ...newArray[index],
        //     esfaCode: columnData.esfaCode,
        //     abnormal: res.payload.abnormal,
        //   };
        //   return newArray;
        // });
        setSelectedProjectDetails((prevArr) => {
          return prevArr.map((item, index) => {
            return {
              ...item,
              esfaCode: columnData.esfaCode,
              abnormal: res.payload.abnormal,
            };
          });
        });
        break;
      case "height":
        // setSelectedProjectDetails((prevArr) => {
        //   const newArray = [...prevArr];
        //   newArray[index] = {
        //     ...newArray[index],
        //     esfaCode: res.payload.esfaCode,
        //     abnormal: res.payload.abnormal,
        //     cost: res.payload.cost,
        //   };
        //   return newArray;
        // });
        setSelectedProjectDetails((prevArr) => {
          return prevArr.map((item, index) => {
            return {
              ...item,
              esfaCode: res.payload.esfaCode,
              abnormal: res.payload.abnormal,
              cost: res.payload.cost,
            };
          });
        });
        break;
      case "depth":
        // setSelectedProjectDetails((prevArr) => {
        //   const newArray = [...prevArr];
        //   newArray[index] = {
        //     ...newArray[index],
        //     esfaCode: res.payload.esfaCode,
        //     abnormal: res.payload.abnormal,
        //     cost: res.payload.cost,
        //   };
        //   return newArray;
        // });
        setSelectedProjectDetails((prevArr) => {
          return prevArr.map((item, index) => {
            return {
              ...item,
              esfaCode: res.payload.esfaCode,
              abnormal: res.payload.abnormal,
              cost: res.payload.cost,
            };
          });
        });
        break;
      case "length":
        // setSelectedProjectDetails((prevArr) => {
        //   const newArray = [...prevArr];
        //   newArray[index] = {
        //     ...newArray[index],
        //     esfaCode: res.payload.esfaCode,
        //     abnormal: res.payload.abnormal,
        //     cost: res.payload.cost,
        //   };
        //   return newArray;
        // });
        setSelectedProjectDetails((prevArr) => {
          return prevArr.map((item, index) => {
            return {
              ...item,
              esfaCode: res.payload.esfaCode,
              abnormal: res.payload.abnormal,
              cost: res.payload.cost,
            };
          });
        });
        break;
      case "rawPrimaryColour":
        // setSelectedProjectDetails((prevArr) => {
        //   const newArray = [...prevArr];
        //   newArray[index] = {
        //     ...newArray[index],
        //     primaryColour: res.payload.primaryColour,
        //   };
        //   return newArray;
        // });
        setSelectedProjectDetails((prevArr) => {
          return prevArr.map((item, index) => {
            return {
              ...item,
              primaryColour: res.payload.primaryColour,
            };
          });
        });
        break;
      case "rawSecondaryColour":
        // setSelectedProjectDetails((prevArr) => {
        //   const newArray = [...prevArr];
        //   newArray[index] = {
        //     ...newArray[index],
        //     secondaryColour: res.payload.secondaryColour,
        //   };
        //   return newArray;
        // });
        setSelectedProjectDetails((prevArr) => {
          return prevArr.map((item, index) => {
            return {
              ...item,
              secondaryColour: res.payload.secondaryColour,
            };
          });
        });
        break;
      default:
        break;
    }
  };

  // common function toLowerCase() upda"check" ? "text-danger" : ""te each cell value in table
  const inputComponent = (options) => {
    const { rowIndex } = options;
    // const value = projectDetailsArr[rowIndex][options.field]
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
          setProjectDetailsArr((prevArr) => {
            const newArray = [...prevArr];
            newArray[rowIndex] = {
              ...newArray[rowIndex],
              [options.field]: e.target.value,
            };
            return newArray;
          });
          setIsChangesSaved(false);
        }}
      />
    );
  };

  const inputRowEditComponent = (options) => {
    const { rowIndex } = options;
    // const value = selectedProjectDetails[rowIndex][options.field]
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
          const newValue = e.target.value;
          setSelectedProjectDetails((prevArr) => {
            return prevArr.map((item, index) => {
              return {
                ...item,
                [options.field]: newValue,
              };
            });
          });
          setIsChangesSaved(false);
        }}
      />
    );
  };

  const initFilters = () => {
    const clearFilter = {
      surveyorName: { value: null, matchMode: FilterMatchMode.IN },
      roomNumber: { value: null, matchMode: FilterMatchMode.IN },
      roomName: { value: null, matchMode: FilterMatchMode.IN },
      rawDescription: { value: null, matchMode: FilterMatchMode.IN },
      description: { value: null, matchMode: FilterMatchMode.IN },
      quantity: { value: null, matchMode: FilterMatchMode.IN },
      itemType: { value: null, matchMode: FilterMatchMode.IN },
      esfaCode: { value: null, matchMode: FilterMatchMode.IN },
      condition: { value: null, matchMode: FilterMatchMode.IN },
      makeModel: { value: null, matchMode: FilterMatchMode.IN },
      length: { value: null, matchMode: FilterMatchMode.IN },
      depth: { value: null, matchMode: FilterMatchMode.IN },
      height: { value: null, matchMode: FilterMatchMode.IN },
      rawPrimaryColour: { value: null, matchMode: FilterMatchMode.IN },
      primaryColour: { value: null, matchMode: FilterMatchMode.IN },
      rawSecondaryColour: { value: null, matchMode: FilterMatchMode.IN },
      secondaryColour: { value: null, matchMode: FilterMatchMode.IN },
      abnormal: { value: null, matchMode: FilterMatchMode.IN },
      comments: { value: null, matchMode: FilterMatchMode.IN },
      // photograph: { value: null, matchMode: FilterMatchMode.IN },
      cost: { value: null, matchMode: FilterMatchMode.IN },
      descriptionH: { value: null, matchMode: FilterMatchMode.IN },
      carbonSavingKG: { value: null, matchMode: FilterMatchMode.IN },
      qrCode: { value: null, matchMode: FilterMatchMode.IN },
    };
    setFilters(clearFilter);
    getAllProjectDetailsList(
      page,
      perPage,
      projectId,
      sortColumn,
      sortColumnDir,
      searchText,
      clearFilter
    );
  };

  const headerProjectDetails = (
    <div className="d-flex justify-content-between">
      <Btn
        type="button"
        icon="bi bi-funnel"
        label="Clear"
        className="btn btn-outline-dark d-flex align-items-center"
        outlined
        onClick={() => initFilters()}
      />
      {!isProjectPublished && (
        <div className="d-flex align-items-center">
          <div className="me-2">Enable Row Edit</div>
          <InputSwitch
            id="value"
            name="value"
            checked={enableRowEdit}
            onChange={(e) => {
              setEnableRowEdit(e.value);
            }}
          />
        </div>
      )}
      {enableRowEdit ? (
        <Btn
          type="button"
          icon="bi bi-pencil-square"
          label="Edit"
          className="btn btn-outline-dark"
          outlined
          disabled={
            !!selectedProjectDetails && selectedProjectDetails.length > 0
              ? false
              : true
          }
          onClick={() => {
            setShowBulkEditableGrid(true);
          }}
        />
      ) : (
        !isProjectPublished && (
          <Btn
            type="button"
            icon="bi bi-save2"
            label="Save"
            className="btn btn-outline-dark"
            outlined
            disabled={isLabelShow && isChangesSaved}
            onClick={async () => {
              const res = await updateProjectDetails(projectDetailsArr);
              if (res.payload.result > 0) {
                setIsLabelShow(true);
                Swal.fire({
                  title: res?.payload?.message,
                  confirmButtonColor: "#000000",
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "confirm-button-swal",
                  },
                });
                setIsChangesSaved(true);
              }
            }}
          />
        )
      )}
    </div>
  );

  const headerRowEditProjectDetails = (
    <div className="d-flex justify-content-end">
      <Btn
        type="button"
        icon="bi bi-save2"
        label="Save"
        className="btn btn-outline-dark"
        outlined
        onClick={async () => {
          const res = await updateProjectDetails(selectedProjectDetails);
          if (res.payload.result > 0) {
            setIsLabelShow(true);
            Swal.fire({
              title: res?.payload?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "confirm-button-swal",
              },
            });
            setIsChangesSaved(true);
            setEnableRowEdit(false);
            setShowBulkEditableGrid(false);
            setSelectedProjectDetails(null);
            getAllProjectDetailsList(
              page,
              perPage,
              projectId,
              sortColumn,
              sortColumnDir,
              searchText,
              filters
            );
          }
        }}
      />
    </div>
  );

  const ActionRow = (row) => {
    return (
      <>
        <div className={`actions`}>
          <OverlayTrigger placement="top" overlay={ViewHistoryTooltip}>
            <div
              className="actions"
              onClick={() => {
                setProjectDetailsId(row.projectDetailsId);
                setShowHistoryModal(true);
                setSearchHistoryText(null);
              }}
            >
              <Link className="btn btn-sm bg-info-subtle">
                <i className="bi bi-clock-history"></i>
              </Link>
            </div>
          </OverlayTrigger>
          {!isProjectPublished && (
            <OverlayTrigger
              placement="top"
              overlay={DeleteProjectDetailsTooltip}
            >
              <button
                className="btn btn-sm bg-danger-subtle ms-2"
                onClick={() =>
                  deleteProjectDetailsData(row.projectDetailsId, loginUserId)
                }
              >
                <i className="bi bi-trash"></i>
              </button>
            </OverlayTrigger>
          )}
          <div className={`actions`}>
            {row.qrCode && (
              <OverlayTrigger placement="top" overlay={ViewQRTooltip}>
                <button
                  onClick={() => {
                    if (!!row.qrCode) {
                      const itemId = extractIdFromUrl(row.qrCode);
                      // const newItemUrl = `${window.location.origin}/viewitem/${itemId}`;
                      const newItemUrl = `${config.WEB_URL}/viewitem/${itemId}`;
                      //window.location.href = newItemUrl;
                      window.open(newItemUrl, "_blank");
                    }
                    // else {
                    //   Swal.fire({
                    //     title: "Not found!",
                    //     confirmButtonText: "OK",
                    //   });
                    // }
                  }}
                  className="btn btn-sm bg-warning-subtle ms-2"
                >
                  <i className="bi bi-qr-code-scan" />
                </button>
              </OverlayTrigger>
            )}
          </div>
        </div>
      </>
    );
  };

  const getLookUpColumnValue = async (e, columnData) => {
    return new Promise(async (resolve, reject) => {
      const response = await dispatch(
        getLookupColumnValue({
          rawDescription:
            e === "rawDescription" ? columnData.rawDescription : null,
          description: e === "rawDescription" ? null : columnData.description,
          length: columnData.length,
          depth: columnData.depth,
          height: columnData.height,
          primaryColour: columnData.rawPrimaryColour,
          secondaryColour: columnData.rawSecondaryColour,
          condition: columnData.condition,
          makeModel: columnData.makeModel,
          qrCode: columnData.qrCode,
        })
      );
      resolve(response);
    });
  };

  const projectDetailsHistoryColumns = [
    {
      name: "Modified By",
      sortable: true,
      sortField: "CreatedBy",
      selector: (row) => row.createdBy,
    },
    {
      name: "Date Time",
      sortable: true,
      sortField: "CreatedDateTime",
      selector: (row) =>
        moment(row.createdDateTime).format("DD MMM YYYY hh:mm A"),
    },
  ];

  // function toLowerCase() upda"check" ? "text-danger" : ""te project details table value in database
  const updateProjectDetails = async (projectDetailsList) => {
    return new Promise(async (resolve, reject) => {
      const transformedData = projectDetailsList.map((data, index) => {
        return {
          ...data,
          modifiedBy: loginUserId,
          modifiedDateTime: new Date(),
        };
      });
      const res = await dispatch(
        addProjectDetails({
          projectDetailsList: transformedData,
          isUpdated: true,
        })
      );
      resolve(res);
    });
  };

  const selectInputValidationSchema = Yup.object().shape({
    reason: Yup.string().required("reason is required"),
  });

  const formik = useFormik({
    initialValues: {
      reason: null,
    },
    validationSchema: selectInputValidationSchema,
    onSubmit: async () => {
      if (formik.isValid) {
      }
    },
  });

  let formikRef = null;

  const deleteProjectDetailsData = (project_details_id, deleted_by) => {
    withReactContent(Swal).fire({
      title: "Are you sure? You want to delete!",
      html: (
        <Formik
          innerRef={(ref) => (formikRef = ref)}
          initialValues={{ reason: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.reason) {
              errors.reason = "reason is required";
            }
            return errors;
          }}
          onSubmit={() => {}}
        >
          <Form>
            <Field
              type="text"
              placeholder="Enter reason"
              className="swal2-input"
              name="reason"
              onKeyPress={(event) =>
                event.key === "Enter" && Swal.clickConfirm()
              }
            />
          </Form>
        </Formik>
      ),
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#000000",
      customClass: {
        confirmButton: "confirm-button-swal",
      },
      didOpen: () => {
        Swal.getPopup()?.querySelector("input")?.focus();
      },
      preConfirm: async () => {
        await formikRef?.submitForm();
        if (formikRef?.isValid) {
          const data = {
            project_details_id: project_details_id,
            reason: formikRef.values.reason,
            deleted_by: deleted_by,
          };
          const res = await dispatch(deleteProjectDetails(data));
          if (res.payload.result > 0) {
            getAllProjectDetailsList(
              page,
              perPage,
              projectId,
              sortColumn,
              sortColumnDir,
              searchText,
              filters
            );
            Swal.fire({
              title: res?.payload?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "confirm-button-swal",
              },
            });
          } else {
            Swal.fire({
              title: res?.payload?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "confirm-button-swal",
              },
            });
          }
        } else {
          Swal.showValidationMessage(formikRef?.errors.reason);
        }
      },
    });
    // Swal.fire({
    //   title: "Are you sure?",
    //   html: `You want to delete!`,
    //   icon: "question",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Delete",
    // }).then(async (result) => {
    //   if (result.isConfirmed) {
    //     const res = await dispatch(deleteProjectDetails(data));
    //     if (res.payload.result > 0) {
    //       getAllProjectDetailsList(
    //         page,
    //         perPage,
    //         projectId,
    //         sortColumn,
    //         sortColumnDir,
    //         searchText
    //       );
    //       toast.success(res?.payload?.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     } else {
    //       toast.error(res?.payload?.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     }
    //   }
    // });
  };

  // this method is invoke everytime when component render
  const getAllProjectDetailsList = (
    page_number,
    page_size,
    project_id,
    sort_column_name,
    sort_column_dir,
    search_text,
    filters
  ) => {
    const data = {
      page_number: page_number,
      page_size: page_size,
      projectId: project_id,
      sort_column_name: !!sort_column_name ? sort_column_name : null,
      sort_column_dir: !!sort_column_dir ? sort_column_dir : null,
      search_text: !!search_text ? search_text : null,
      surveyor_name: !!filters["surveyorName"].value
        ? filters["surveyorName"].value.toString()
        : filters["surveyorName"].value,
      room_number: !!filters["roomNumber"].value
        ? filters["roomNumber"].value.toString()
        : filters["roomNumber"].value,
      room_name: !!filters["roomName"].value
        ? filters["roomName"].value.toString()
        : filters["roomName"].value,
      raw_description: !!filters["rawDescription"].value
        ? filters["rawDescription"].value.join(">")
        : filters["rawDescription"].value,
      description: !!filters["description"].value
        ? filters["description"].value.toString()
        : filters["description"].value,
      quantity: !!filters["quantity"].value
        ? filters["quantity"].value.toString()
        : filters["quantity"].value,
      item_type: !!filters["itemType"].value
        ? filters["itemType"].value.toString()
        : filters["itemType"].value,
      esfa_code: !!filters["esfaCode"].value
        ? filters["esfaCode"].value.toString()
        : filters["esfaCode"].value,
      condition: !!filters["condition"].value
        ? filters["condition"].value.toString()
        : filters["condition"].value,
      make_model: !!filters["makeModel"].value
        ? filters["makeModel"].value.toString()
        : filters["makeModel"].value,
      length: !!filters["length"].value
        ? filters["length"].value.toString()
        : filters["length"].value,
      depth: !!filters["depth"].value
        ? filters["depth"].value.toString()
        : filters["depth"].value,
      height: !!filters["height"].value
        ? filters["height"].value.toString()
        : filters["height"].value,
      raw_primary_colour: !!filters["rawPrimaryColour"].value
        ? filters["rawPrimaryColour"].value.toString()
        : filters["rawPrimaryColour"].value,
      primary_colour: !!filters["primaryColour"].value
        ? filters["primaryColour"].value.toString()
        : filters["primaryColour"].value,
      raw_secondary_colour: !!filters["rawSecondaryColour"].value
        ? filters["rawSecondaryColour"].value.toString()
        : filters["rawSecondaryColour"].value,
      secondary_colour: !!filters["secondaryColour"].value
        ? filters["secondaryColour"].value.toString()
        : filters["secondaryColour"].value,
      abnormal: !!filters["abnormal"].value
        ? filters["abnormal"].value.toString()
        : filters["abnormal"].value,
      comments: !!filters["comments"].value
        ? filters["comments"].value.toString()
        : filters["comments"].value,
      // photograph: !!filters["photograph"].value
      //   ? filters["photograph"].value.toString()
      //   : filters["photograph"].value,
      cost: !!filters["cost"].value
        ? filters["cost"].value.toString()
        : filters["cost"].value,
      descriptionH: !!filters["descriptionH"].value
        ? filters["descriptionH"].value.toString()
        : filters["descriptionH"].value,
      carbon_saving_kg: !!filters["carbonSavingKG"].value
        ? filters["carbonSavingKG"].value.toString()
        : filters["carbonSavingKG"].value,
      qr_code: !!filters["qrCode"].value
        ? filters["qrCode"].value.toString()
        : filters["qrCode"].value,
    };
    dispatch(projectDetailsList(data));
  };

  const template2 = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
        { label: 200, value: 200 },
        { label: 500, value: 500 },
        { label: 1000, value: 1000 },
        // { label: 'View All', value: options.totalRecords }
        // { label: 'View all', value: noOfRecords}
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={(e) => {
              setPerPage(e.value);
              setPage(1);
              setFirst(0);
              getAllProjectDetailsList(
                page,
                e.value,
                projectId,
                sortColumn,
                sortColumnDir,
                searchText,
                filters
              );
            }}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  const onPageChange = (e, index) => {
    setFirst(e.first);
    setPage(e.page + 1);
    setPerPage(e.rows);
    getAllProjectDetailsList(
      e.page + 1,
      e.rows,
      projectId,
      sortColumn,
      sortColumnDir,
      searchText,
      filters
    );
  };

  const handleSort = (column, sortDirection) => {
    setSortColumn(column);
    setSortColumnDir(sortDirection);
    if (!!projectId) {
      getAllProjectDetailsList(
        page,
        perPage,
        projectId,
        column,
        sortDirection,
        searchText,
        filters
      );
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
    if (!!projectId) {
      getAllProjectDetailsList(
        page,
        perPage,
        projectId,
        sortColumn,
        sortColumnDir,
        searchText,
        filters
      );
    }
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
    if (!!projectId) {
      getAllProjectDetailsList(
        page,
        newPerPage,
        projectId,
        sortColumn,
        sortColumnDir,
        searchText,
        filters
      );
    }
  };

  const getAllProjectDetailsHistoryList = (
    page_number,
    page_size,
    project_details_id,
    sort_column_name,
    sort_column_dir,
    search_text
  ) => {
    const data = {
      page_number: page_number,
      page_size: page_size,
      projectDetailsId: project_details_id,
      sort_column_name: !!sort_column_name ? sort_column_name : null,
      sort_column_dir: !!sort_column_dir ? sort_column_dir : null,
      search_text: !!search_text ? search_text : null,
    };
    dispatch(projectDetailsHistoryList(data));
  };

  const handleProjectDetailsHistorySort = (column, sortDirection) => {
    if (Object.keys(column).length !== 0 && !!sortDirection) {
      setHistorySortColumn(column.sortField);
      setHistorySortColumnDir(sortDirection);
      getAllProjectDetailsHistoryList(
        1,
        historyPerPage,
        projectDetailsId,
        column.sortField,
        sortDirection,
        searchHistoryText
      );
    }
  };

  const handleProjectDetailsHistoryPageChange = (page) => {
    setHistoryPage(page);

    getAllProjectDetailsHistoryList(
      page,
      historyPerPage,
      projectDetailsId,
      historySortColumn,
      historySortColumnDir,
      searchHistoryText
    );
  };

  const handleProjectDetailsHistoryPerRowsChange = (newPerPage, page) => {
    setHistoryPerPage(newPerPage);
    setHistoryPage(page);

    getAllProjectDetailsHistoryList(
      page,
      newPerPage,
      projectDetailsId,
      historySortColumn,
      historySortColumnDir,
      searchHistoryText
    );
  };

  const publishProjectStatus = (projectId, projectName) => {
    Swal.fire({
      title: "Are you sure?",
      html: `You want to publish <b>${projectName}</b>!`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#d33",
      confirmButtonText: "Publish",
      customClass: {
        confirmButton: "confirm-button-swal",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(
          publishProject({
            project_id: projectId,
            projectName: projectName,
            email: email,
          })
        );
        if (res.payload.result > 0) {
          dispatch(updatePublishProjectStatus(true));
          Swal.fire({
            title: res?.payload?.message,
            confirmButtonColor: "#000000",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "confirm-button-swal",
            },
          });
        } else {
          Swal.fire({
            title: res?.payload?.message,
            confirmButtonColor: "#000000",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "confirm-button-swal",
            },
          });
        }
      }
    });
  };

  const ExpandedComponent = (data) => {
    return (
      <>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Column Name</th>
              <th scope="col">Updated Value</th>
            </tr>
          </thead>
          <tbody>
            {!!data.data.surveyorName && (
              <tr>
                <td>Surveyor Name</td>
                <td>{data.data.surveyorName}</td>
              </tr>
            )}
            {!!data.data.level && (
              <tr>
                <td>Level</td>
                <td>{data.data.level}</td>
              </tr>
            )}
            {!!data.data.roomNumber && (
              <tr>
                <td>Room Number</td>
                <td>{data.data.roomNumber}</td>
              </tr>
            )}
            {!!data.data.roomName && (
              <tr>
                <td>Room Name</td>
                <td>{data.data.roomName}</td>
              </tr>
            )}
            {!!data.data.rawDescription && (
              <tr>
                <td>RAW Description</td>
                <td>{data.data.rawDescription}</td>
              </tr>
            )}
            {!!data.data.description && (
              <tr>
                <td>Description</td>
                <td>{data.data.description}</td>
              </tr>
            )}
            {!!data.data.quantity && (
              <tr>
                <td>Quantity</td>
                <td>{data.data.quantity}</td>
              </tr>
            )}
            {!!data.data.itemType && (
              <tr>
                <td>ItemType</td>
                <td>{data.data.itemType}</td>
              </tr>
            )}
            {!!data.data.esfaCode && (
              <tr>
                <td>ESFA Code</td>
                <td>{data.data.esfaCode}</td>
              </tr>
            )}
            {!!data.data.condition && (
              <tr>
                <td>Condition</td>
                <td>{data.data.condition}</td>
              </tr>
            )}
            {!!data.data.makeModel && (
              <tr>
                <td>Make Model</td>
                <td>{data.data.makeModel}</td>
              </tr>
            )}
            {!!data.data.length && (
              <tr>
                <td>Length</td>
                <td>{data.data.length}</td>
              </tr>
            )}
            {!!data.data.depth && (
              <tr>
                <td>Depth</td>
                <td>{data.data.depth}</td>
              </tr>
            )}
            {!!data.data.height && (
              <tr>
                <td>Height</td>
                <td>{data.data.height}</td>
              </tr>
            )}
            {!!data.data.rawPrimaryColour && (
              <tr>
                <td>RAW Primary Colour</td>
                <td>{data.data.rawPrimaryColour}</td>
              </tr>
            )}
            {!!data.data.primaryColour && (
              <tr>
                <td>Primary Colour</td>
                <td>{data.data.primaryColour}</td>
              </tr>
            )}
            {!!data.data.rawSecondaryColour && (
              <tr>
                <td>RAW Secondary Colour</td>
                <td>{data.data.rawSecondaryColour}</td>
              </tr>
            )}
            {!!data.data.secondaryColour && (
              <tr>
                <td>Secondary Colour</td>
                <td>{data.data.secondaryColour}</td>
              </tr>
            )}
            {!!data.data.abnormal && (
              <tr>
                <td>Abnormal</td>
                <td>{data.data.abnormal}</td>
              </tr>
            )}
            {!!data.data.comments && (
              <tr>
                <td>Comments</td>
                <td>{data.data.comments}</td>
              </tr>
            )}
            {/* {!!data.data.photograph && (
              <tr>
                <td>Photograph</td>
                <td>{data.data.photograph}</td>
              </tr>
            )} */}
            {!!data.data.cost && (
              <tr>
                <td>Cost</td>
                <td>{data.data.cost}</td>
              </tr>
            )}
            {!!data.data.descriptionH && (
              <tr>
                <td>Description & H</td>
                <td>{data.data.descriptionH}</td>
              </tr>
            )}
            {!!data.data.carbonSavingKG && (
              <tr>
                <td>CarbonSavingKG</td>
                <td>{data.data.carbonSavingKG}</td>
              </tr>
            )}
            {!!data.data.qrCode && (
              <tr>
                <td>Condition</td>
                <td>{data.data.qrCode}</td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  };

  useEffect(() => {
    if (!!projectId) {
      getAllProjectDetailsList(
        page,
        perPage,
        projectId,
        sortColumn,
        sortColumnDir,
        searchText,
        filters
      );
    }
  }, [projectId, searchText]);

  useEffect(() => {
    if (showHistoryModal) {
      setHistorySortColumn(null);
      setHistorySortColumnDir(null);
      getAllProjectDetailsHistoryList(
        historyPage,
        historyPerPage,
        projectDetailsId,
        null,
        null,
        searchHistoryText
      );
    }
  }, [showHistoryModal, searchHistoryText]);

  useEffect(() => {
    setProjectDetailsArr(projectDetails);
  }, [projectDetails]);

  useEffect(() => {
    dispatch(allProjectDetailsList(projectId));
  }, [projectDetails, isChangesSaved]);

  useEffect(() => {
    const uniqueSurveyorName = getFieldUniqueList(
      allProjectDetailsLists,
      "surveyorName"
    );
    const uniqueRoomNumber = getFieldUniqueList(
      allProjectDetailsLists,
      "roomNumber"
    );
    const uniqueRoomName = getFieldUniqueList(
      allProjectDetailsLists,
      "roomName"
    );
    const uniqueDescriptions = getFieldUniqueList(
      allProjectDetailsLists,
      "description"
    );
    const uniqueQuantity = getFieldUniqueList(
      allProjectDetailsLists,
      "quantity"
    );
    const uniqueItemType = getFieldUniqueList(
      allProjectDetailsLists,
      "itemType"
    );
    const uniqueEsfaCode = getFieldUniqueList(
      allProjectDetailsLists,
      "esfaCode"
    );
    const uniqueCondition = getFieldUniqueList(
      allProjectDetailsLists,
      "condition"
    );
    const uniqueRawDescription = getFieldUniqueList(
      allProjectDetailsLists,
      "rawDescription"
    );
    const uniqueRawPrimaryColour = getFieldUniqueList(
      allProjectDetailsLists,
      "rawPrimaryColour"
    );
    const uniqueRawSecondaryColour = getFieldUniqueList(
      allProjectDetailsLists,
      "rawSecondaryColour"
    );
    const uniqueMakeModel = getFieldUniqueList(
      allProjectDetailsLists,
      "makeModel"
    );
    const uniqueLength = getFieldUniqueList(allProjectDetailsLists, "length");
    const uniqueDepth = getFieldUniqueList(allProjectDetailsLists, "depth");
    const uniqueHeight = getFieldUniqueList(allProjectDetailsLists, "height");
    const uniquePrimaryColour = getFieldUniqueList(
      allProjectDetailsLists,
      "primaryColour"
    );
    const uniqueSecondaryColour = getFieldUniqueList(
      allProjectDetailsLists,
      "secondaryColour"
    );
    const uniqueAbnormal = getFieldUniqueList(
      allProjectDetailsLists,
      "abnormal"
    );
    const uniqueComments = getFieldUniqueList(
      allProjectDetailsLists,
      "comments"
    );
    // const uniquePhotograph = getFieldUniqueList(
    //   allProjectDetailsLists,
    //   "photograph"
    // );
    const uniqueCost = getFieldUniqueList(allProjectDetailsLists, "cost");
    const uniqueDescriptionH = getFieldUniqueList(
      allProjectDetailsLists,
      "descriptionH"
    );
    const uniqueCarbonSavingKG = getFieldUniqueList(
      allProjectDetailsLists,
      "carbonSavingKG"
    );

    const uniqueQrCode = getFieldUniqueList(allProjectDetailsLists, "qrCode");

    setSurveyorNameFilter(uniqueSurveyorName);
    setRoomNumberFilter(uniqueRoomNumber);
    setRoomNameFilter(uniqueRoomName);
    setDescriptionFilter(uniqueDescriptions);
    setQuantityFilter(uniqueQuantity);
    setItemTypeFilter(uniqueItemType);
    setEsfaCodeFilter(uniqueEsfaCode);
    setConditionFilter(uniqueCondition);
    setRawDescriptionFilter(uniqueRawDescription);
    setRawPrimaryColourFilter(uniqueRawPrimaryColour);
    setRawSecondaryColourFilter(uniqueRawSecondaryColour);
    setMakeModelFilter(uniqueMakeModel);
    setLengthFilter(uniqueLength);
    setDepthFilter(uniqueDepth);
    setHeightFilter(uniqueHeight);
    setPrimaryColourFilter(uniquePrimaryColour);
    setSecondaryColourFilter(uniqueSecondaryColour);
    setAbnormalFilter(uniqueAbnormal);
    setCommentsFilter(uniqueComments);
    // setPhotographFilter(uniquePhotograph);
    setCostFilter(uniqueCost);
    setDescriptionHFilter(uniqueDescriptionH);
    setCarbonSavingKGFilter(uniqueCarbonSavingKG);
    setQrCodeFilter(uniqueQrCode);
  }, [allProjectDetailsLists]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isChangesSaved) {
        event.preventDefault();
        event.returnValue = ""; // For older browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isChangesSaved]);

  const handleGoToIntendedPage = useCallback(
    (location) => history.push(location),
    [history]
  );

  useEffect(() => {
    if (triggerExit.onOk) {
      handleGoToIntendedPage(triggerExit.path);
    }
    const unblock = history.block((location) => {
      if (
        !location.location.pathname.includes("ProjectDetails") &&
        !isChangesSaved
      ) {
        setVisibleDialog(true);
      } else {
        setTriggerExit((obj) => ({
          ...obj,
          onOk: true,
        }));
      }
      setTriggerExit((obj) => ({ ...obj, path: location.location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [
    handleGoToIntendedPage,
    history,
    triggerExit.onOk,
    triggerExit.path,
    isChangesSaved,
  ]);

  // useEffect(() => {
  //   const tableHead = document.getElementsByClassName("rdt_TableHead");
  //   window.addEventListener("scroll", () => {
  //     if (isProjectDetailsVisible && projectDetailsArr.length > 0) {
  //       if (window.scrollY > 1) {
  //         tableHead[0].classList.add("customTableHead");
  //       } else {
  //         tableHead[0].classList.remove("customTableHead");
  //       }
  //     }
  //   });
  // }, [projectDetailsArr]);

  return (
    <>
      {historyLoading && <Loader />}
      <div className="mainContent px-4 pt-3">
        <div className="page-header mb-3">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Project Details</h3>
                <div className="page-title-right d-flex">
                  <div className="symbol-project bg-light"></div>
                  <label
                    className="btn btn-outline-dark ms-2 d-flex"
                    onClick={() => history.push(`/projectList/${clientId}`)}
                  >
                    <i className="bi bi-arrow-left me-2"></i> Back
                  </label>
                  {/* {isLabelShow && !!userRole && userRole === "Admin" && (
                    <a
                      className="btn btn-outline-dark ms-2 d-flex"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={() => {
                        setIsLabelShow(!isLabelShow);
                        // setIsChangesSaved(false);
                      }}
                    >
                      <i className="bi bi-pencil me-2"></i> <span>Edit</span>
                    </a>
                  )} */}

                  {/* {!isChangesSaved && (
                    <a
                      className="btn btn-outline-dark ms-2 d-flex"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={async () => {
                        const res = await updateProjectDetails(
                          projectDetailsArr
                        );
                        if (res.payload.result > 0) {
                          setIsLabelShow(true);
                          Swal.fire(res?.payload?.message);
                          setIsChangesSaved(true);
                        }
                      }}
                    >
                      <i className="bi bi-save me-2"></i> <span>Save</span>
                    </a>
                  )} */}
                  {!isProjectPublished && (
                    <OverlayTrigger
                      placement="top"
                      overlay={PublishProjectDetailsTooltip}
                    >
                      <a
                        className="btn btn-outline-dark ms-2 d-flex"
                        onClick={() =>
                          publishProjectStatus(projectId, projectName)
                        }
                      >
                        <i className="bi bi-check2-circle me-2"></i>{" "}
                        <span>Publish</span>
                      </a>
                    </OverlayTrigger>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="card shadow-sm border-0">
              <div className="card-body p-9">
                <div className="d-flex gap-3 p-3">
                  <div className="d-lg-flex gap-1 d-none">
                    <div className="pro-icon">
                      <i className="bi bi-cast"></i>
                    </div>
                    <div>
                      <h5 className="my-0">Project Name</h5>
                      <h6 className="my-0 fw-normal">
                        {!!projectName ? projectName : "-"}
                      </h6>
                    </div>
                  </div>
                  <div className="d-lg-flex gap-1  d-none">
                    <div className="client-icon">
                      {/* <i className="bi bi-people-fill"></i> */}
                      {clientId && (
                        <img
                          src={`${IMAGE_BASE_URL}/${clientId}.jpg?timestamp=${Date.now()}`}
                          alt="Logo"
                          className="project-logo-img client-logo"
                        />
                      )}
                    </div>
                    <div>
                      <h5 className="my-0">Client Name</h5>
                      <h6 className="my-0 fw-normal">
                        {!!clientName ? clientName : "-"}
                      </h6>
                    </div>
                  </div>
                </div>
                {showBulkEditableGrid ? (
                  <DT
                    value={selectedProjectDetails}
                    size="small"
                    dataKey="projectDetailsId"
                    header={headerRowEditProjectDetails}
                    // editMode={"cell"}
                    editMode={!isProjectPublished && "cell"}
                    row
                    emptyMessage="No data found."
                  >
                    <Column header="Action" body={ActionRow} />
                    <Column
                      header="Surveyor Name"
                      field="surveyorName"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      bodyClassName="text-center"
                    />
                    <Column
                      header="Room Number"
                      field="roomNumber"
                      editor={(options) => inputRowEditComponent(options)}
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      bodyClassName="text-center"
                    />
                    <Column
                      header="Room Name"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="roomName"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="RAW Description"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="rawDescription"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="Description"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="description"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="Quantity"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="quantity"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="Item Type"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="itemType"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="ESFA Code"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="esfaCode"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="Condition"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="condition"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="Make/Model"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="makeModel"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="Length"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="length"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="Depth"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="depth"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="Height"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="height"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="RAW Primary Colour"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="rawPrimaryColour"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="Primary Colour"
                      // onCellEditComplete={(data) =>
                      //   onEditableRowCellEditComplete(data)
                      // }
                      // editor={(options) => inputRowEditComponent(options)}
                      field="primaryColour"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="RAW Secondary Colour"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="rawSecondaryColour"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="Secondary Colour"
                      // onCellEditComplete={(data) =>
                      //   onEditableRowCellEditComplete(data)
                      // }
                      // editor={(options) => inputRowEditComponent(options)}
                      field="secondaryColour"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="Abnormal"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="abnormal"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="Comments"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="comments"
                      bodyClassName="text-center"
                    />
                    {/* <Column
                      header="Photograph"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="photograph"
                    /> */}
                    <Column
                      header="Cost"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="cost"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="Description&H"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="descriptionH"
                      bodyClassName="text-center"
                    />
                    <Column
                      header="Carbon Saving KG"
                      onCellEditComplete={(data) =>
                        onEditableRowCellEditComplete(data)
                      }
                      editor={(options) => inputRowEditComponent(options)}
                      field="carbonSavingKG"
                      bodyClassName="text-center"
                    />
                  </DT>
                ) : (
                  !!trimprojectDetailsArr && (
                    <>
                      <DT
                        value={trimprojectDetailsArr}
                        // rows={10}
                        size="small"
                        dataKey="projectDetailsId"
                        filters={filters}
                        loading={loading}
                        sortField={sortColumn}
                        sortOrder={sortColumnDir === "asc" ? 1 : -1}
                        selection={selectedProjectDetails}
                        selectionMode={enableRowEdit ? "multiple" : null}
                        onSelectionChange={(e) => {
                          setSelectedProjectDetails(e.value);
                        }}
                        onSort={(data) => {
                          setSortColumn(data.sortField);
                          setSortColumnDir(
                            data.sortOrder === 1 ? "asc" : "desc"
                          );
                          handleSort(
                            data.sortField,
                            data.sortOrder === 1 ? "asc" : "desc"
                          );
                        }}
                        header={headerProjectDetails}
                        // editMode={enableRowEdit ? "row" : "cell"}
                        editMode={
                          isProjectPublished ||
                          (!isProjectPublished && enableRowEdit)
                            ? "row"
                            : "cell"
                        }
                        row
                        emptyMessage="No data found."
                        onFilter={(e) => {
                          setFilters(e.filters);
                          getAllProjectDetailsList(
                            page,
                            perPage,
                            projectId,
                            sortColumn,
                            sortColumnDir,
                            searchText,
                            e.filters
                          );
                        }}
                      >
                        {enableRowEdit && (
                          <Column
                            selectionMode="multiple"
                            headerStyle={{ width: "3rem" }}
                          />
                        )}
                        <Column header="Action" body={ActionRow} />
                        <Column
                          header="Surveyor Name"
                          filterField="surveyorName"
                          // style={{ width: '600px' }}
                          sortable
                          headerStyle={{ width: "5rem" }}
                          filterMenuStyle={{ width: "14rem" }}
                          field="surveyorName"
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filter
                          showFilterMatchModes={false}
                          filterElement={(option) =>
                            rowFilterTemplate(option, surveyorNameFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="Room Number"
                          // style={{ minWidth: '14rem' }}
                          filterField="roomNumber"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="roomNumber"
                          filter
                          editor={(options) => inputComponent(options)}
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          showFilterMatchModes={false}
                          filterElement={(option) =>
                            rowFilterTemplate(option, roomNumberFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="Room Name"
                          // style={{ minWidth: '14rem' }}
                          filterField="roomName"
                          sortable
                          filterMenuStyle={{ width: "14rem" }}
                          field="roomName"
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filter
                          showFilterMatchModes={false}
                          filterElement={(option) =>
                            rowFilterTemplate(option, roomNameFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="RAW Description"
                          style={{ minWidth: "14rem" }}
                          filterField="rawDescription"
                          sortable
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="rawDescription"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, rawDescriptionFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="Description"
                          style={{ minWidth: "14rem" }}
                          filterField="description"
                          sortable
                          // onCellEditComplete={(data) => onCellEditComplete(data)}
                          // editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="description"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, descriptionFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="Quantity"
                          filterField="quantity"
                          sortable
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="quantity"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, quantityFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="Item Type"
                          filterField="itemType"
                          sortable
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="itemType"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, itemTypeFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="ESFA Code"
                          filterField="esfaCode"
                          sortField="esfaCode"
                          sortable
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="esfaCode"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, esfaCodeFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="Condition"
                          style={{ minWidth: "14rem" }}
                          filterField="condition"
                          sortable
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="condition"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, conditionFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="Make/Model"
                          filterField="makeModel"
                          sortable
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="makeModel"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, makeModelFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="Length"
                          filterField="length"
                          sortable
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="length"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, lengthFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="Depth"
                          filterField="depth"
                          sortable
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="depth"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, depthFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="Height"
                          filterField="height"
                          sortable
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="height"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, heightFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="RAW Primary Colour"
                          filterField="rawPrimaryColour"
                          sortable
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="rawPrimaryColour"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, rawPrimaryColourFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="Primary Colour"
                          filterField="primaryColour"
                          sortable
                          // onCellEditComplete={(data) => onCellEditComplete(data)}
                          // editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="primaryColour"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, primaryColourFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="RAW Secondary Colour"
                          filterField="rawSecondaryColour"
                          sortable
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="rawSecondaryColour"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, rawSecondaryColourFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="Secondary Colour"
                          filterField="secondaryColour"
                          sortable
                          // onCellEditComplete={(data) => onCellEditComplete(data)}
                          // editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="secondaryColour"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, secondaryColourFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="Abnormal"
                          filterField="abnormal"
                          sortable
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="abnormal"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, abnormalFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="Comments"
                          filterField="comments"
                          style={{ minWidth: "14rem" }}
                          sortable
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="comments"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, commentsFilter)
                          }
                          bodyClassName="text-center"
                        />
                        {/* <Column
                      header="Photograph"
                      filterField="photograph"
                      sortable
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={(options) => inputComponent(options)}
                      filterMenuStyle={{ width: "14rem" }}
                      field="photograph"
                      showFilterMatchModes={false}
                      filter
                      filterElement={(option) =>
                        rowFilterTemplate(option, photographFilter)
                      }
                    /> */}
                        <Column
                          header="Cost"
                          filterField="cost"
                          sortable
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="cost"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, costFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="Description&H"
                          filterField="descriptionH"
                          sortable
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="descriptionH"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, descriptionHFilter)
                          }
                          bodyClassName="text-center"
                        />
                        <Column
                          header="Carbon Saving KG"
                          filterField="carbonSavingKG"
                          sortable
                          onCellEditComplete={(data) =>
                            onCellEditComplete(data)
                          }
                          editor={(options) => inputComponent(options)}
                          filterMenuStyle={{ width: "14rem" }}
                          field="carbonSavingKG"
                          showFilterMatchModes={false}
                          filter
                          filterElement={(option) =>
                            rowFilterTemplate(option, carbonSavingKGFilter)
                          }
                          bodyClassName="text-center"
                        />
                        {/* <Column
                      header="QR Code"
                      filterField="qrCode"
                      sortable
                      onCellEditComplete={(data) => onCellEditComplete(data)}
                      editor={(options) => inputComponent(options)}
                      filterMenuStyle={{ width: "14rem" }}
                      field="qrCode"
                      showFilterMatchModes={false}
                      filter
                      filterElement={(option) =>
                        rowFilterTemplate(option, qrCodeFilter)
                      }
                    /> */}
                        {/* <Column
                      header="View QR"
                      body={viewQR}
                    /> */}
                      </DT>
                      <Paginator
                        template={template2}
                        first={first}
                        rows={perPage}
                        totalRecords={noOfRecords}
                        onPageChange={(e) => onPageChange(e, 1)}
                        className="justify-content-end"
                      />
                    </>
                  )
                )}
                {/* <Paginator
                  template={template2}
                  first={first}
                  rows={perPage}
                  totalRecords={noOfRecords}
                  onPageChange={(e) => onPageChange(e, 1)}
                  className="justify-content-end"
                /> */}
                {/* <DataTable
                  columns={columns}
                  data={projectDetailsArr}
                  className="table table-bordered table-striped"
                  filterServer={true}
                  pagination
                  paginationServer
                  paginationTotalRows={noOfRecords}
                  subHeader
                  subHeaderComponent={
                    <div className="col-md-2">
                      <input
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder="Search"
                        className="form form-control"
                      />
                    </div>
                  }
                  onChangeRowsPerPage={(newPerPage, page) =>
                    handlePerRowsChange(newPerPage, page)
                  }
                  onChangePage={(page) => handlePageChange(page)}
                  onSort={(column, sortDirection) =>
                    handleSort(column, sortDirection)
                  }
                  progressPending={loading}
                  progressComponent={
                    <div style={{ padding: "100px" }}>
                      <div class="spinner-border" role="status"></div>
                    </div>
                  }
                  highlightOnHover
                  searchableRows={true}
                  customStyles={customStylesDataTable}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(false)}
        // fullscreen={true}
        size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Project Details History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={projectDetailsHistoryColumns}
            data={projectDetailsHistory}
            className="table table-bordered table-striped"
            filterServer={true}
            striped={true}
            fixedHeader={true}
            pagination
            paginationServer
            paginationTotalRows={noOfRecordsHistory}
            subHeader
            subHeaderComponent={
              <div className="col-md-2">
                <input
                  onChange={(e) => setSearchHistoryText(e.target.value)}
                  placeholder="Search"
                  className="form form-control"
                />
              </div>
            }
            onChangeRowsPerPage={handleProjectDetailsHistoryPerRowsChange}
            onChangePage={handleProjectDetailsHistoryPageChange}
            onSort={handleProjectDetailsHistorySort}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            sortServer
            highlightOnHover
            searchableRows={true}
            responsive={true}
            customStyles={customStylesDataTable}
          />
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={isVisibleDialog}
        onHide={() => setVisibleDialog(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your changes are unsaved. <br />
          Are you sure you want to move to another page?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setVisibleDialog(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setTriggerExit((obj) => ({
                ...obj,
                onOk: true,
              }));
              setVisibleDialog(false);
            }}
          >
            Yes, I want to move to another page
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProjectDetails;
