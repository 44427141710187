import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import {
  API_LIST_ALL_CHANGE_REQUEST_PPROJECTS,
  API_LIST_ALL_PROJECT_REPORT_FOR_CHANGE_REQUESTED,
  API_LIST_PROJECT_REPORT_FOR_CHANGE_REQUESTED,
  API_SAVE_APPROVE_REJECT_CHANGE_REQUEST_FOR_REPORT,
} from "../../APIProxy/api";

const token =
  !!Cookies.get("COOKIE_JWTTOKEN") && Cookies.get("COOKIE_JWTTOKEN");
let decodedToken = "";

if (token) {
  const decryptedToken = CryptoJS.AES.decrypt(token, "CRYPTO_SECRET_KEY");
  decodedToken = decryptedToken.toString(CryptoJS.enc.Utf8);
}

export const listAllProjectsForRemarksRequested = createAsyncThunk(
  "ApprovalMaster/ProjectListForRemarksRequest",
  async ({ searchTerm, sortBy, sortDirection, page, pageSize }) => {
    const response = await axios.get(API_LIST_ALL_CHANGE_REQUEST_PPROJECTS, {
      headers: { Authorization: `Bearer ${decodedToken}` },
      params: {
        searchTerm,
        sortBy,
        sortDirection,
        page,
        pageSize,
      },
    });
    return response.data;
  }
);

export const projectsReportForChangeRequested = createAsyncThunk(
  "project/projectsReportForChangeRequested",
  async (data) => {
    const response = await axios.get(
      `${API_LIST_PROJECT_REPORT_FOR_CHANGE_REQUESTED}`,
      {
        headers: { Authorization: `Bearer ${decodedToken}` },
        params: data,
      }
    );
    return response.data;
  }
);

export const allProjectsReportListForChangeRequested = createAsyncThunk(
  "project/allProjectsReportListForChangeRequested",
  async (data) => {
    const response = await axios.get(
      `${API_LIST_ALL_PROJECT_REPORT_FOR_CHANGE_REQUESTED}`,
      {
        headers: { Authorization: `Bearer ${decodedToken}` },
        params: { projectId: data },
      }
    );

    return response.data;
  }
);

export const saveApproveRejectRequestForChange = createAsyncThunk(
  "project/saveApproveRejectChangeRequestForReport",
  async (data) => {
    const response = await axios.post(
      API_SAVE_APPROVE_REJECT_CHANGE_REQUEST_FOR_REPORT,
      data.requestParam,
      {
        headers: { Authorization: `Bearer ${decodedToken}` },
        params: { isUpdated: data.isUpdated },
      }
    );
    return response.data;
  }
);
