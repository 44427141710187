import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./Components/Layouts/layout";
import history from "./Components/history";
import CustomRouter from "./Components/customRouter";
import AddClientsDetailsWrapper from "./Components/Users/addClientsDetailsWrapper";
import Login from "./Components/Common/Login/login";
import ChangePassword from "./Components/Common/Login/changePassword";
import ForgotPassword from "./Components/Common/Login/forgotPassword";
import ResetPassword from "./Components/Common/Login/resetPassword";
import Protected from "./Components/Layouts/Protected";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import ProjectDetailsByQRCode from "./Components/QRCode/ProjectDetailsByQRCode";

function App() {
  return (
    <CustomRouter history={history}>
      <Routes>
        <Route path={"/"} element={<Login />} />
        <Route path={"/forgotPassword"} element={<ForgotPassword />} />
        <Route path={"/setNewPassword/:userId"} element={<ResetPassword />} />
        <Route
          path={"/registerClient/:userId"}
          element={<AddClientsDetailsWrapper />}
        />
        <Route
          path={"/changePassword"}
          element={<Protected Component={ChangePassword} />}
        />
        <Route path={"/*"} element={<Protected Component={Layout} />} />
        <Route
          path={"/viewItem/:QRCode"}
          element={<ProjectDetailsByQRCode />}
        />
      </Routes>
    </CustomRouter>
  );
}

export default App;
