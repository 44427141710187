import { createSlice } from '@reduxjs/toolkit';
// import { addUser, listUsers, editUser, deleteUser } from '../Actions/actions';
import { addUser, listUsers, editUser, deleteUser, listClients, addClient, editClient, deleteClient } from '../Actions/usersActions';

const initialState = {
  users: [],
  loading: false,
  error: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  noOfRecords: 0,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // //All users list Slices
    // .addCase(listAllUsers.pending, (state) => {
    //   state.loading = true;
    // })
    // .addCase(listAllUsers.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.users = action.payload;
    // })
    // .addCase(listAllUsers.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.error.message;
    // })
    //Admin Slices
      .addCase(addUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(listUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(listUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
        state.noOfRecords = action.payload.noOfRecords;
      })
      .addCase(listUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(editUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(editUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      ;
  },
});

export default usersSlice.reducer;