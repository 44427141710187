import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
// import logo from "../../../Assets/images/logo.png";
import { listAllUsers } from "../../../Redux/Actions/allUsersActions";
import { forgotPassword } from "../../../Redux/Actions/forgotPasswordActions";
import Swal from "sweetalert2";
import { useNavigate,Link } from "react-router-dom";

const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const validationSchema = Yup.object().shape({
  email: Yup.string().email("Email is not valid").matches(emailRegExp, 'Email is not valid').required("Email is required"),
});

const ForgotPassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const allUsers = useSelector((state) => state.allUsers);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(listAllUsers());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (data) => {
      setIsSubmitting(true);
      if (formik.isValid) {
        const user =
          allUsers &&
          allUsers.allUsers.listData &&
          allUsers.allUsers.listData.find((item) => item.email === data.email);
        if (user && user.id && user.name) {
          var dataModel = {
            id: user.id,
            email: data.email,
            name: user.name,
          };
          var res = await dispatch(forgotPassword(dataModel));
          setIsSubmitting(false);
          if(!res.error)
          {
            Swal.fire({
              title: res?.payload?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: 'confirm-button-swal'
              }
              // html: `You want to upload <b>${imageFiles.length} </b>Images!!`,
              // icon: "question",
              //showCancelButton: true,
              // confirmButtonColor: "#5cb85c",
              // cancelButtonColor: "#d9534f",
              // confirmButtonText: "Upload",
            }).then(async (result) => {
              if (result.isConfirmed) {
                navigate('/');
                //setSuccessMessage("Password reset link has been sent to your email.");
              }
            });
          }
          else{
            Swal.fire({
              title: "Error sending email",
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: 'confirm-button-swal'
              }})
          }
         
        } else {
          setIsSubmitting(false);
          setSuccessMessage("");
          setErrorMessage("This email does not exist.");
        }
      }
    },
  });
  const handleForgotPassword = () => {
    setErrorMessage("");
    formik.handleSubmit();
  };

  return (
    <>
      {successMessage ? (
        <div>{successMessage}</div>
      ) : (
      <>
        <div className="page-title-right d-flex">
          <div className="symbol-project bg-light"></div>
          {/* <Link
          className="btn btn-outline-dark ms-2 d-flex"
          to={`/clients`}
        >
            <i className="bi bi-arrow-left me-2"></i> Back
          </Link> */}
        </div>
        <div className="d-flex align-items-center h-100 login-set">
          <div className="login-wrapper">
            <div className="container">
              <div className="loginbox">
                <div className="login-right">
                  <div className="login-right-wrap">
                    <div className="text-center mb-3">
                    <Link to={`/login`}>
                      <img
                        src={"/Assets/images/logo.png"}
                        className="img-fluid"
                        style={{ height: "60px" }}
                        alt="Logo"
                      />
                      {/* <i className="bi bi-arrow-left me-2"></i> Back */}
                    </Link>
                      {/* <img src="Assets/images/logo.png" style={{ height: "60px" }} alt="Logo" /> */}
                    </div>
                    <h2>Forgot Password</h2>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="form-group mb-4">
                        <label>
                          User Email <span className="text-danger">*</span>
                        </label>
                        <input
                          className={`form-control ${
                            formik.touched.email && formik.errors.email
                              ? "is-invalid"
                              : ""
                          }`}
                          type="text"
                          id="email"
                          placeholder="Enter Email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="invalid-feedback">
                            {formik.errors.email}
                          </div>
                        )}
                        <div className="error-message">{errorMessage}</div>
                      </div>
                      <div className="forgotpass mb-4"></div>
                      <div className="d-grid gap-2">
                        <button
                          className="btn btn-primary btn-block"
                          type="button"
                          onClick={handleForgotPassword}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Sending" : "Send email"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
      )}
    </>
  );
};

export default ForgotPassword;
