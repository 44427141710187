import { createSlice } from '@reduxjs/toolkit';
import { decryptUserID } from '../Actions/idDecryptActions';

const initialState = {
 idDecrypt: [],
  loading: false,
  error: null,
};

const idDecryptSlice = createSlice({
  name: 'idDecrypt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    
      .addCase(decryptUserID.pending, (state) => {
        state.loading = true;
      })
      .addCase(decryptUserID.fulfilled, (state, action) => {
        state.loading = false;
        state.idDecrypt = action.payload;
      })
      .addCase(decryptUserID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default idDecryptSlice.reducer;