import { createSlice } from "@reduxjs/toolkit";
import { projectDetailsByQRCode } from "../Actions/projectsDetailsByQRCodeActions";

const initialState = {
  projectDetailsByQRCode: [],
  noOfRecords: 0,
  loading: false,
  error: null,
  columnJSON:null,
};

const projectDetailsByQRCodeSlice = createSlice({
  name: "projectDetailsByCode",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(projectDetailsByQRCode.pending, (state) => {
        state.loading = true;
        state.projectDetailsByQRCode = [];
        state.noOfRecords = 0;
        state.columnJSON = null;
      })
      .addCase(projectDetailsByQRCode.fulfilled, (state, action) => {
        state.loading = false;
        state.projectDetailsByQRCode = action.payload.projectDetailsByQRCodeList; //projectDetailsByQRCode;
        state.noOfRecords = action.payload.noOfRecords;
        state.columnJSON = action.payload.columnJSON;
      })
      .addCase(projectDetailsByQRCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default projectDetailsByQRCodeSlice.reducer;
