import { createSlice } from "@reduxjs/toolkit";
import { projectDetailsHistoryList } from "../Actions/projectsActions";

const initialState = {
  projectDetailsHistory: [],
  noOfRecords: 0,
  loading: false,
  error: null,
};

const projectDetailsSlice = createSlice({
  name: "projectDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(projectDetailsHistoryList.pending, (state) => {
        state.loading = true;
        state.projectDetailsHistory = [];
        state.noOfRecords = 0;
      })
      .addCase(projectDetailsHistoryList.fulfilled, (state, action) => {
        state.loading = false;
        state.projectDetailsHistory = action.payload.projectDetailsHistoryList;
        state.noOfRecords = action.payload.noOfRecords;
      })
      .addCase(projectDetailsHistoryList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default projectDetailsSlice.reducer;
