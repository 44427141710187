import { createSlice } from "@reduxjs/toolkit";
import {
  addProject,
  addProjectDetails,
  addProjectReportDetails,
  allProjectDetailsList,
  deleteProjectDetails,
  deleteProject,
  editProject,
  getLookupColumnValue,
  listProjects,
  projectDetailsList,
  publishProject,
  updateProjectReport,
  updatePublishProjectStatus,
} from "../Actions/projectsActions";

const initialState = {
  projects: [],
  projectDetails: [],
  allProjectDetailsLists: [],
  lookUpColumnValue: null,
  projectName: null,
  clientName: null,
  clientEmail: null,
  isProjectPublished: null,
  noOfRecords: 0,
  loading: false,
  error: null,
};

const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(listProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.projects = action.payload;
        state.clientName = action.payload.clientName;
        state.clientEmail = action.payload.clientEmail;
        state.noOfRecords = action.payload.noOfRecords;
      })
      .addCase(listProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProject.fulfilled, (state, action) => {
        state.loading = false;
        state.projects = action.payload;
      })
      .addCase(addProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(projectDetailsList.pending, (state) => {
        state.loading = true;
        state.projectDetails = [];
        state.projectName = null;
        state.clientName = null;
        state.noOfRecords = 0;
      })
      .addCase(projectDetailsList.fulfilled, (state, action) => {
        state.loading = false;
        state.projectDetails = action.payload.projectDetailsList;
        state.projectName = action.payload.projectName;
        state.clientName = action.payload.clientName;
        state.clientEmail = action.payload.clientEmail;
        state.isProjectPublished = action.payload.isProjectPublished;
        state.noOfRecords = action.payload.noOfRecords;
      })
      .addCase(projectDetailsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(allProjectDetailsList.pending, (state) => {
        state.allProjectDetailsLists = [];
      })
      .addCase(allProjectDetailsList.fulfilled, (state, action) => {
        state.allProjectDetailsLists = action.payload;
      })
      .addCase(allProjectDetailsList.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(getLookupColumnValue.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getLookupColumnValue.fulfilled, (state, action) => {
        // state.loading = false;
        state.lookUpColumnValue = action.payload;
      })
      .addCase(getLookupColumnValue.rejected, (state, action) => {
        // state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addProjectDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProjectDetails.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addProjectDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addProjectReportDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProjectReportDetails.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addProjectReportDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateProjectReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProjectReport.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateProjectReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteProjectDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProjectDetails.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteProjectDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(editProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(editProject.fulfilled, (state, action) => {
        state.loading = false;
        state.projects = action.payload;
      })
      .addCase(editProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(publishProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(publishProject.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(publishProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updatePublishProjectStatus.pending, (state) => {
        state.isProjectPublished = false;
      })
      .addCase(updatePublishProjectStatus.fulfilled, (state, action) => {
        state.isProjectPublished = true;
      })
      .addCase(updatePublishProjectStatus.rejected, (state, action) => {
        state.isProjectPublished = false;
      });
  },
});

export default projectSlice.reducer;
