import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from "js-cookie"
import CryptoJS from "crypto-js"
import { API_LIST_ALL_USERS } from "../../APIProxy/api";
// const token = !!Cookies.get("COOKIE_JWTTOKEN") && Cookies.get("COOKIE_JWTTOKEN");
// let decodedToken = '';

// if (token) {
//   const decryptedToken = CryptoJS.AES.decrypt(token, "CRYPTO_SECRET_KEY");
//   decodedToken = decryptedToken.toString(CryptoJS.enc.Utf8);
// }

//All Users List
export const listAllUsers = createAsyncThunk('user/listAllUsers', async () => {
  const response = await axios.get(API_LIST_ALL_USERS, 
    // {headers: {'Authorization': `Bearer ${decodedToken}`}}
  )
  return response.data;
});
