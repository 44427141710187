import React, { useState } from "react";
import { Nav, Tab, Tooltip } from "react-bootstrap";
import DescriptionMaster from "./DescriptionMaster";
import ItemTypeMaster from "./ItemTypeMaster";
import ESFACodeMaster from "./ESFACodeMaster";
import ColourMaster from "./ColourMaster";
import CostMaster from "./CostMaster";
import CarbonSavingKGMaster from "./CarbonSavingKGMaster";

const MastersApproval = () => {
  const [selectedTab, setSelectedTab] = useState("description");
  const Approve = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Approve
    </Tooltip>
  );

  const Delete = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete
    </Tooltip>
  );
  return (
    <>
      <div className="mainContent px-4 pt-3">
        <div className="page-header mb-3">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Masters Approval</h3>
              </div>
            </div>
          </div>
        </div>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="description"
          onSelect={(eventKey) => setSelectedTab(eventKey)}
        >
          <Nav variant="pills" className="flex pageNavTabs">
            <Nav.Item>
              <Nav.Link eventKey="description">Description</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="itemType">ItemType</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="esfacode">ESFACode</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="colour">Colour</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="cost">Cost</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="carbonsavingkg">CarbonSavingKG</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="mt-3">
            <Tab.Pane eventKey="description">
              <DescriptionMaster
                selectedTab={selectedTab}
                ApproveTooltip={Approve}
                DeleteTooltip={Delete}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="itemType">
              <ItemTypeMaster
                selectedTab={selectedTab}
                ApproveTooltip={Approve}
                DeleteTooltip={Delete}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="esfacode">
              <ESFACodeMaster
                selectedTab={selectedTab}
                ApproveTooltip={Approve}
                DeleteTooltip={Delete}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="colour">
              <ColourMaster
                selectedTab={selectedTab}
                ApproveTooltip={Approve}
                DeleteTooltip={Delete}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="cost">
              <CostMaster
                selectedTab={selectedTab}
                ApproveTooltip={Approve}
                DeleteTooltip={Delete}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="carbonsavingkg">
              <CarbonSavingKGMaster
                selectedTab={selectedTab}
                ApproveTooltip={Approve}
                DeleteTooltip={Delete}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default MastersApproval;
