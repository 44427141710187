import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie"
import CryptoJS from "crypto-js"
import { RESET_PASSWORD } from "../../APIProxy/api";

//resetPassword password APIs
// const token = !!Cookies.get("COOKIE_JWTTOKEN") && Cookies.get("COOKIE_JWTTOKEN");
// let decodedToken = '';

// if (token) {
//   const decryptedToken = CryptoJS.AES.decrypt(token, "CRYPTO_SECRET_KEY");
//   decodedToken = decryptedToken.toString(CryptoJS.enc.Utf8);
// }
export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (user) => {
    const response = await axios.post(RESET_PASSWORD, user
      // , {headers: {'Authorization': `Bearer ${decodedToken}`}}
    );
    return response.data;
  }
);


