import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import {
  API_LIST_PROJECTS,
  API_ADD_OR_EDIT_PROJECT,
  API_ADD_PROJECT_DETAILS,
  API_ADD_PROJECT_REPORT_DETAILS,
  API_LIST_PROJECT_DETAILS,
  API_LIST_PROJECT_DETAILS_HISTORY,
  API_PUBLISH_PROJECT,
  API_GET_LOOKUP_COLUMNS_VALUE,
  API_DELETE_PROJECT_DETAILS,
  API_UPDATE_PROJECT_REPORT,
  API_LIST_ALL_PROJECT_DETAILS,
  API_DELETE_PROJECT,
  API_SAVE_CHANGE_REQUEST_FOR_REPORT,
  API_UPDATE_PROJECT_REPORT_FOR_CHANGE_REQUEST,
  API_LIST_PROJECT_REPORT_HISTORY,
} from "../../APIProxy/api";

//Project APIs
const token =
  !!Cookies.get("COOKIE_JWTTOKEN") && Cookies.get("COOKIE_JWTTOKEN");
let decodedToken = "";

if (token) {
  const decryptedToken = CryptoJS.AES.decrypt(token, "CRYPTO_SECRET_KEY");
  decodedToken = decryptedToken.toString(CryptoJS.enc.Utf8);
}

export const listProjects = createAsyncThunk(
  "project/listProjects",
  async ({
    clientId,
    loginUserId,
    searchTerm,
    sortBy,
    sortDirection,
    page,
    pageSize,
  }) => {
    const response = await axios.get(API_LIST_PROJECTS, {
      headers: { Authorization: `Bearer ${decodedToken}` },
      params: {
        clientId,
        loginUserId,
        sortBy,
        searchTerm,
        sortDirection,
        page,
        pageSize,
      },
    });
    return response.data;
  }
);
export const addProject = createAsyncThunk(
  "project/addProject",
  async (user) => {
    const response = await axios.post(API_ADD_OR_EDIT_PROJECT, user, {
      headers: { Authorization: `Bearer ${decodedToken}` },
    });
    return response.data;
  }
);

export const projectDetailsList = createAsyncThunk(
  "project/projectDetailsList",
  async (data) => {
    const response = await axios.get(`${API_LIST_PROJECT_DETAILS}`, {
      headers: { Authorization: `Bearer ${decodedToken}` },
      params: data,
    });
    return response.data;
  }
);

export const allProjectDetailsList = createAsyncThunk(
  "project/allProjectDetailsList",
  async (data) => {
    const response = await axios.get(`${API_LIST_ALL_PROJECT_DETAILS}`, {
      headers: { Authorization: `Bearer ${decodedToken}` },
      params: { projectId: data },
    });
    return response.data;
  }
);

export const addProjectDetails = createAsyncThunk(
  "project/addProjectDetails",
  async (data) => {
    const response = await axios.post(
      API_ADD_PROJECT_DETAILS,data,
      //data.projectDetailsList,
      {
        headers: { Authorization: `Bearer ${decodedToken}` },
        //params: { isUpdated: data.isUpdated },
      }
    );
    return response.data;
  }
);

export const addProjectReportDetails = createAsyncThunk(
  "project/addProjectReportDetails",
  async (data) => {
    const response = await axios.post(
      API_ADD_PROJECT_REPORT_DETAILS,
      data.projectReportDetailsList,
      {
        headers: { Authorization: `Bearer ${decodedToken}` },
        params: { isUpdated: data.isUpdated },
      }
    );
    return response.data;
  }
);
// export const updateProjectReport = createAsyncThunk(
//   "project/updateProjectReport",
//   async (data) => {
//     const response = await axios.post(API_UPDATE_PROJECT_REPORT, data);
//     return response.data;
//   }
// );
export const updateProjectReport = createAsyncThunk(
  "project/updateProjectReport",
  async (data) => {
    const response = await axios.post(
      API_UPDATE_PROJECT_REPORT,
      data.projectDetailsList,
      {
        headers: { Authorization: `Bearer ${decodedToken}` },
        params: { isUpdated: data.isUpdated },
      }
    );
    return response.data;
  }
);

export const deleteProjectDetails = createAsyncThunk(
  "project/deleteProjectDetails",
  async (data) => {
    const response = await axios.post(API_DELETE_PROJECT_DETAILS, null, {
      headers: { Authorization: `Bearer ${decodedToken}` },
      params: {
        project_details_id: data.project_details_id,
        reason: data.reason,
        deleted_by: data.deleted_by,
      },
    });
    return response.data;
  }
);

export const deleteProject = createAsyncThunk(
  "project/deleteProject",
  async (ProjectId) => {
    const response = await axios.get(API_DELETE_PROJECT + ProjectId, {
      headers: { Authorization: `Bearer ${decodedToken}` },
    });
    return response.data;
  }
);

export const publishProject = createAsyncThunk(
  "project/publishProject",
  async (data) => {
    const response = await axios.post(API_PUBLISH_PROJECT, null, {
      headers: { Authorization: `Bearer ${decodedToken}` },
      params: {
        project_id: data.project_id,
        published_by: data.published_by,
        projectName: data.projectName,
        email: data.email,
      },
    });
    return response.data;
  }
);

export const updatePublishProjectStatus = createAsyncThunk(
  "project/updatePublishProjectStatus",
  async (data) => {
    return data;
  }
);

export const editProject = createAsyncThunk(
  "project/editProject",
  async (user) => {
    const response = await axios.post(API_ADD_OR_EDIT_PROJECT, user, {
      headers: { Authorization: `Bearer ${decodedToken}` },
    });
    return response.data;
  }
);

export const getLookupColumnValue = createAsyncThunk(
  "project/getLookupColumnValue",
  async (data) => {
    const response = await axios.post(API_GET_LOOKUP_COLUMNS_VALUE, data, {
      headers: { Authorization: `Bearer ${decodedToken}` },
    });
    return response.data;
  }
);

export const projectDetailsHistoryList = createAsyncThunk(
  "project/projectDetailsHistoryList",
  async (data) => {
    const response = await axios.get(`${API_LIST_PROJECT_DETAILS_HISTORY}`, {
      headers: { Authorization: `Bearer ${decodedToken}` },
      params: data,
    });
    return response.data;
  }
);

export const saveRequestForChange = createAsyncThunk(
  "project/SaveChangeRequestForReport",
  async (data) => {
    const response = await axios.post(
      API_SAVE_CHANGE_REQUEST_FOR_REPORT,
      data.requestParam,
      {
        headers: { Authorization: `Bearer ${decodedToken}` },
        params: { isUpdated: data.isUpdated },
      }
    );
    return response.data;
  }
);

export const updateProjectReportForChangeRequest = createAsyncThunk(
  "project/updateProjectReportForChangeRequest",
  async (data) => {
    const response = await axios.post(
      API_UPDATE_PROJECT_REPORT_FOR_CHANGE_REQUEST,
      data.requestParam,
      {
        headers: { Authorization: `Bearer ${decodedToken}` },
      }
    );
    return response.data;
  }
);

export const projectReportHistoryList = createAsyncThunk(
  "project/projectReportHistoryList",
  async (data) => {
    const response = await axios.get(`${API_LIST_PROJECT_REPORT_HISTORY}`, {
      headers: { Authorization: `Bearer ${decodedToken}` },
      params: data,
    });
    return response.data;
  }
);
