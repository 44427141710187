import { createSlice } from "@reduxjs/toolkit";
import { imagesUpload } from "../Actions/imagesUploadActions";

const initialState = {
  images: [],
  loading: false,
  error: null,
};

const imagesUploadSlices = createSlice({
  name: "images",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(imagesUpload.pending, (state) => {
        state.loading = true;
      })
      .addCase(imagesUpload.fulfilled, (state, action) => {
        state.loading = false;
        state.images = action.payload;
      })
      .addCase(imagesUpload.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      ;
  },
});

export default imagesUploadSlices.reducer;
