import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { listAllUsers } from '../../Redux/Actions/allUsersActions';
import LinkExpired from '../Common/linkExpired';
import RegisterClient from './registerClient';

export default function AddClientsDetailsWrapper(props) {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const allUsers = useSelector((state) => state.allUsers);

  useEffect(() => {
    dispatch(listAllUsers());
  }, [dispatch]);

  const [isExpired, setIsExpired] = useState(false);
  const [expirationTime, setExpirationTime] = useState(null);

  useEffect(() => {
    if (userId && allUsers) {
      var user = allUsers && allUsers.allUsers.listData && allUsers.allUsers.listData.find((item) => item.enID === userId); //.toLocaleLowerCase());//item.id == userId);
      const expirationTime = user?.createdDate
      setIsExpired(isLinkExpired(expirationTime));
      setExpirationTime(expirationTime);
    }

  }, [isExpired, isLinkExpired, allUsers, userId]);
  if (isExpired) {
    return <LinkExpired />;
  }

  return <RegisterClient {...props} />;

}

function getCurrentUtcDateTime() {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = (now.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = now.getUTCDate().toString().padStart(2, '0');
  const hours = now.getUTCHours().toString().padStart(2, '0');
  const minutes = now.getUTCMinutes().toString().padStart(2, '0');
  const seconds = now.getUTCSeconds().toString().padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

function isLinkExpired(expirationTime) {
  const currentUtcDateTime = getCurrentUtcDateTime();
  // Calculate expiration time as 7 days from the creation date
  const expirationUTC = new Date(expirationTime);
  expirationUTC.setDate(expirationUTC.getDate() + 7);
  return expirationUTC < new Date(currentUtcDateTime);
}
