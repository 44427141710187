import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { userlogin } from "../../../Redux/Actions/loginActions";
// import logo from "../../../Assets/images/logo.png";
import * as CryptoJS from "crypto-js";
import { secret_key } from "../../../APIProxy/secret";

const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is not valid")
    .matches(emailRegExp, "Email is not valid")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

const Login = () => {
  const userLoginRes = useSelector((state) => state.userLoginRes);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (data) => {
      var dataModel = {
        email: data.email,
        password: data.password,
      };
      if (rememberMe) {
        localStorage.setItem("rememberedEmail", data.email);
        localStorage.setItem("rememberedPassword", data.password);
      } else {
        localStorage.removeItem("rememberedEmail");
        localStorage.removeItem("rememberedPassword");
      }
      if (formik.isValid) {
        dispatch(userlogin(dataModel)).then((data) => {
          if (data.payload.id && data.payload.roleName) {
            const userId = data.payload.id;
            const encryptedUserId = CryptoJS.AES.encrypt(
              userId,
              secret_key
            ).toString();
            Cookies.set("userId", encryptedUserId, { expires: 4 / 24 }); // 4 hours expiration
            Cookies.set("userRole", data.payload.roleName, { expires: 4 / 24 }); // 4 hours expiration
            Cookies.set("userName", data.payload.name, { expires: 4 / 24 }); // 4 hours expiration);
            Cookies.set("email", data.payload.email, { expires: 4 / 24 }); // 4 hours expiration
            if (data.payload.roleName === "ClientUser") {
              const encryptedParentId = CryptoJS.AES.encrypt(
                data.payload.parentID,
                secret_key
              ).toString();
              Cookies.set("parentId", encryptedParentId, { expires: 4 / 24 }); // 4 hours expiration
            }
          }
          if (data.payload.roleName) {
            // const userRole = Cookies.get('userRole');
            if (
              data.payload.roleName === "Admin" ||
              data.payload.roleName === "Employee"
            ) {
              //history.push("/admin");
              navigate("/admin");
              window.location.reload();
            }
            if (data.payload.roleName === "Client") {
              //history.push("/dashboard");
              navigate("/dashboard");
              window.location.reload();
            }
            if (data.payload.roleName === "ClientUser") {
              //history.push("/dashboard");
              navigate("/dashboard");
              window.location.reload();
            }
          }
        });
      }
    },
  });

  useEffect(() => {
    const storedEmail = localStorage.getItem("rememberedEmail");
    const storedPassword = localStorage.getItem("rememberedPassword");
    if (!!storedEmail && !!storedPassword) {
      formik.setValues({ email: storedEmail, password: storedPassword });
      setRememberMe(true);
    }
  }, []);

  const handleUserLogin = () => {
    setErrorMessage("");
    formik.handleSubmit();
  };

  useEffect(() => {
    if (userLoginRes?.error) {
      setErrorMessage("The username or password provided is incorrect.");
    }
  }, [userLoginRes]);
  return (
    <>
      {/* <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css"
      /> */}
      <div className="d-flex align-items-center h-100 login-set">
        <div className="login-wrapper">
          <div className="container">
            <div className="loginbox">
              <div className="login-right">
                <div className="login-right-wrap">
                  <div className="text-center mb-3">
                    <img
                      src={"/Assets/images/logo.png"}
                      className="img-fluid"
                      style={{ height: "60px" }}
                      alt="Logo"
                    />
                    {/* <img src="Assets/images/logo.png" style={{ height: "60px" }} alt="Logo" /> */}
                  </div>
                  <h2>Sign in</h2>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group mb-4">
                      <label>
                        Username <span className="text-danger">*</span>
                      </label>
                      <input
                        className={`form-control ${
                          formik.touched.email && formik.errors.email
                            ? "is-invalid"
                            : ""
                        }`}
                        type="text"
                        id="email"
                        placeholder="Enter Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="invalid-feedback">
                          {formik.errors.email}
                        </div>
                      )}
                    </div>
                    <div className="form-group mb-4  password-control position-relative">
                      <label>
                        Password <span className="text-danger">*</span>
                      </label>
                      <input
                        className={`form-control pass-input ${
                          formik.touched.password && formik.errors.password
                            ? "is-invalid"
                            : ""
                        }`}
                        type={showPassword ? "text" : "password"}
                        id="password"
                        placeholder="Enter Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <span
                        className="bi bi-eye-fill login-show-password"
                        style={{ position: "absolute", right: "10px" }}
                        onClick={() => setShowPassword(!showPassword)}
                      ></span>
                      {formik.touched.password && formik.errors.password && (
                        <div className="invalid-feedback">
                          {formik.errors.password}
                        </div>
                      )}
                      <div className="error-message">{errorMessage}</div>
                    </div>
                    <div className="forgotpass mb-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                          checked={rememberMe}
                          value={rememberMe}
                          onChange={() => setRememberMe(!rememberMe)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Remember me
                        </label>
                      </div>
                      <Link to="/forgotpassword" className="btn-link fw-bold">
                        {" "}
                        Forgot Password?{" "}
                      </Link>
                    </div>
                    {/* <p className="account-subtitle">Need an account? <a href="#" className="btn-link">Sign Up</a></p> */}
                    <div className="d-grid gap-2">
                      <button
                        className="btn btn-primary btn-block"
                        type="button"
                        onClick={handleUserLogin}
                      >
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
