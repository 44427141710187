/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import Loader from "../Common/Loader/loader";
import { listAllProjectsForRemarksRequested } from "../../Redux/Actions/ChangeRequestActions";
import moment from "moment";

const ChangeRequestApprovals = () => {
  const dispatch = useDispatch();
  const chnageRequest = useSelector((state) => state.changeRequest);
  const loading = useSelector((state) => state.changeRequest.loading);
  const noOfRecords = useSelector((state) => state.users.noOfRecords);
  const [searchText, setSearchText] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState("RemarksDateTime");
  const [sortDirection, setSortDirection] = useState("desc");
  const [loader, setLoader] = useState(false);
  const [filteredData, setFilteredData] = useState();

  const GetlistAllProjectsForRemarksRequested = (
    sort_column_name,
    sort_column_dir,
    page,
    page_size
  ) => {
    const sortcolumn = !!sort_column_name ? sort_column_name : null;
    const sortdir = !!sort_column_dir ? sort_column_dir : null;
    dispatch(
      listAllProjectsForRemarksRequested({
        searchTerm: !!searchText ? searchText : null,
        sortBy: sortcolumn,
        sortDirection: sortdir,
        page: page,
        pageSize: page_size,
      })
    );
  };

  const handleSort = (column, sortDirection) => {
    if (Object.keys(column).length !== 0 && !!sortDirection) {
      setSortBy(column.sortField);
      setSortDirection(sortDirection);
      GetlistAllProjectsForRemarksRequested(
        column.sortField,
        sortDirection,
        page,
        pageSize
      );
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
    GetlistAllProjectsForRemarksRequested(
      sortBy,
      sortDirection,
      page,
      pageSize
    );
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPageSize(newPerPage);
    setPage(page);
    GetlistAllProjectsForRemarksRequested(
      sortBy,
      sortDirection,
      page,
      newPerPage
    );
  };

  useEffect(() => {
    GetlistAllProjectsForRemarksRequested(sortBy, sortDirection, 1, 10);
  }, [searchText]);

  useEffect(() => {
    setFilteredData(chnageRequest?.allRequestProject?.projectListModels);
  }, [chnageRequest]);

  const columns = [
    {
      name: "Client Name",
      selector: (row) => row.clientName,
      sortable: true,
      sortField: "U.name",
      wrap: true,
      style: {
        cursor: "pointer",
      },
    },
    {
      name: "ProjectName",
      selector: (row) => row.projectName,
      sortable: true,
      sortField: "P.name",
    },
    {
      name: "Created By",
      selector: (row) => row.remarksCommentedBy,
      sortable: true,
      sortField: "UR.name",
    },
    {
      name: "Created On",
      selector: (row) =>
        moment(row.remarksDateTime).format("DD MMM YYYY hh:mm A"),
      sortable: true,
      sortField: "RemarksDateTime",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div className="actions">
            <a
              className="btn btn-sm bg-warning-subtle me-2"
              href={`/changeRequestProjectReports/${row.projectId}/${row.clientId}`}
            >
              <i className="bi bi-eye" />
            </a>
          </div>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "130px",
    },
  ];

  return (
    <>
      {(loader || loading) && <Loader />}
      <div>
        <div className="mainContent px-4 pt-3">
          <div className="page-header mb-3">
            <div className="row align-items-center">
              <div className="col-sm-12">
                <div className="page-sub-header">
                  <h3 className="page-title">Change Request Approval</h3>
                  <div className="page-title-right">
                    <div className="symbol-project bg-light">
                      {/* <img src={project1} alt="image" className="p-2" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="card shadow-sm border-0">
                <div className="card-body p-9">
                  <DataTable
                    pagination
                    noHeader={true}
                    paginationTotalRows={noOfRecords}
                    columns={columns}
                    data={filteredData}
                    className="table table-bordered table-striped"
                    //filterServer={true}
                    paginationServer
                    paginationRowsPerPageOptions={[10, 20, 30, 40]}
                    paginationPerPage={pageSize}
                    subHeader
                    subHeaderComponent={
                      <div className="col-md-2">
                        <input
                          onChange={(e) => setSearchText(e.target.value)}
                          placeholder="Search"
                          className="form form-control"
                        />
                      </div>
                    }
                    paginationDefaultPage={page}
                    sortServer
                    onSort={handleSort}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    highlightOnHover
                    //searchableRows={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangeRequestApprovals;
