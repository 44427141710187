import { createSlice } from '@reduxjs/toolkit';
import { userlogin } from '../Actions/loginActions';
 
const initialState = {
  userLoginRes: null,
  loading: false,
  error: null,
};
 
const loginSlice = createSlice({
  name: 'userLoginRes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userlogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userlogin.fulfilled, (state, action) => {
        state.loading = false;
        state.userLoginRes = action.payload;
        state.error = null;
      })
      .addCase(userlogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
 
export default loginSlice.reducer;
// import { createSlice } from '@reduxjs/toolkit';
// import { userlogin } from '../Actions/loginActions';

// const initialState = {
//   userLoginRes: [],
//   loading: false,
//   error: null,
// };

// const loginSlice = createSlice({
//   name: 'userLoginRes',
//   initialState,
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       .addCase(userlogin.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(userlogin.fulfilled, (state, action) => {
//         state.loading = false;
//         state.userLoginRes = action.payload;
//       })
//       .addCase(userlogin.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.error.message;
//       })
//       ;
//   },
// });

// export default loginSlice.reducer;