import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// import logo from "../../../Assets/images/logo.png";
//import { decryptUserID } from "../../../Redux/Actions/idDecryptActions";
import Swal from "sweetalert2";
import { resetPassword } from "../../../Redux/Actions/resetPasswordActions";

const validationSchema = Yup.object().shape({
  newPassword: Yup.string().min(6, 'Password must be at least 6 characters').required('New Password is required'),
  confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match').required('Confirm Password is required'),
});

const ResetPassword = () => {
  const { userId } = useParams();
  //const decryptId = useSelector((state) => state.idDecrypt);
  const [decryptId , setDecryptId]= useState(null);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(()=>{
    setDecryptId(userId);
  }, [decryptId])
  
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (data) => {
      setIsSubmitting(true);
      if (formik.isValid && decryptId && decryptId.length > 0) {
        var dataModel = {
          enID: decryptId,
          password: data.newPassword
        };
        dispatch(resetPassword(dataModel)).then((data) => {
          var res = data.payload;
          if(res.result === 1){
            Swal.fire({
              title: res?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: 'confirm-button-swal'
              }
            })
                  navigate('/');
          }
          else if(res.result === 2)
          {
            setIsSubmitting(false);
            Swal.fire({
              title: res?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: 'confirm-button-swal'
              }
            })
          }
        })
      }
    },
  });
  const handleChangePassword = () => {
    formik.handleSubmit();
  };

  return (
    <>
      <div className="d-flex align-items-center h-100 login-set">
        <div className="login-wrapper">
          <div className="container">
            <div className="loginbox">
              <div className="login-right">
                <div className="login-right-wrap">
                  <div className="text-center mb-3">
                    <img src={"/Assets/images/logo.png"} className="img-fluid" style={{ height: "60px" }} alt="Logo" />
                    {/* <img src="Assets/images/logo.png" style={{ height: "60px" }} alt="Logo" /> */}
                  </div>
                  <h2>Reset Password</h2>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group mb-4  password-control position-relative">
                      <label>New password <span className="text-danger">*</span></label>
                        <input
                            className={`form-control ${formik.touched.newPassword && formik.errors.newPassword ? "is-invalid" : ""}`}
                            type={showNewPassword ? "text" : "password"}
                            id="newPassword"
                            placeholder="Enter New Password"
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <span className="bi bi-eye-fill login-show-password" style={{position:"absolute", right: "10px"}} onClick={() => setShowNewPassword(!showNewPassword)}></span>
                        {formik.touched.newPassword && formik.errors.newPassword && (
                          <div className="invalid-feedback">{formik.errors.newPassword}</div>
                        )}
                    </div>

                    <div className="form-group mb-4 password-control position-relative">
                      <label>Confirm new password <span className="text-danger">*</span></label>
                      <input
                          className={`form-control ${formik.touched.confirmPassword && formik.errors.confirmPassword ? "is-invalid" : ""}`}
                          type="password"
                          id="confirmPassword"
                          placeholder="Confirm New Password"
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <span className="bi bi-eye-fill login-show-password" style={{position:"absolute", right: "10px"}} onClick={() => setShowConfirmPassword(!showConfirmPassword)}></span>
                      {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                        <div className="invalid-feedback">{formik.errors.confirmPassword}</div>
                      )}
                    </div>
                    <div className="forgotpass mb-4">
                    </div>
                    <div className="d-grid gap-2">
                      <button className="btn btn-primary btn-block" type="button" onClick={handleChangePassword} disabled={isSubmitting}>
                        Reset Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
