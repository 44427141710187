/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listClients,
  editClient,
  addClient,
  deleteUser,
  deleteClient,
} from "../../Redux/Actions/usersActions";
import { listAllUsers } from "../../Redux/Actions/allUsersActions";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import * as CryptoJS from "crypto-js";
import { secret_key } from "../../APIProxy/secret";
import Loader from "../Common/Loader/loader";
import { Button } from "react-bootstrap";
import { capitalizeWords } from "../Common/capitalizeWord";
import config from "../../config";

const phoneRegExp = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
const nameRegExp = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
const companyRegExp = /^[A-Z]([a-zA-Z0-9]|[- @\.#&!])*$/;
const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const addValidationSchema = Yup.object().shape({
  companyName: Yup.string()
    .required("Company name is required")
    .matches(companyRegExp, "Company name is not valid")
    .min(3, "Company name is too short"),
  //.max(15, "Name is too long"),
  email: Yup.string().email("Email is not valid").matches(emailRegExp, 'Email is not valid').required("Email is required"),
  contactPerson: Yup.string()
    .required("Contact person name is required")
    .matches(nameRegExp, "Contact person name is not valid")
    .min(3, "Contact person name is too short")
    .max(15, "Contact person name is too long"),
  contactNo: Yup.string()
    // .required("Contact Number is required"
    .matches(phoneRegExp, "Contact number is not valid")
    .min(6, "Contact number is too short")
    .max(20, "Contact number is too long"),
  location: Yup.string()
    .required("Location is required")
    .matches(nameRegExp, "Location is not valid")
    .min(3, "Location is too short")
    .max(15, "Location is too long"),
  logo: Yup.mixed().required("Logo is required").test('fileType', 'Only image file is allowed', (value) => {
      if (!value) return true;
      let fileType;
      if (typeof value === 'string') {
        const fileName = value.split('\\').pop();
        fileType = fileName.split('.').pop();
      } else {
        fileType = value.type.split('/').pop();
      }
      return ['jpg', 'jpeg', 'png'].includes(fileType.toLowerCase());
    })
    // .test('fileSize', 'File size must be less than 2MB', (value) => {
    //   if (!value) return true; // Return true if no file is selected
    //   const fileSize = value.size; // Get file size in bytes
    //   const maxSize = 2 * 1024 * 1024; // 2MB in bytes
    //   return fileSize <= maxSize; // Check if file size is less than or equal to 2MB
    // }),
  
});

const editValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Client name is required")
    .matches(nameRegExp, "Client name is not valid")
    .min(3, "Client name is too short"),
  //.max(15, "Name is too long"),
  companyName: Yup.string()
    .required("Company name is required")
    .matches(companyRegExp, "Company name is not valid")
    .min(3, "Company name is too short"),
  //.max(15, "Name is too long"),
  email: Yup.string().email("Email is not valid").matches(emailRegExp, 'Email is not valid').required("Email is required"),
  contactPerson: Yup.string()
    .required("Contact person name is required")
    .matches(nameRegExp, "Contact person name is not valid")
    .min(3, "Contact person name is too short")
    .max(15, "Contact person name is too long"),
  contactNo: Yup.string()
    // .required("Contact Number is required")
    .matches(phoneRegExp, "Contact number is not valid")
    .min(6, "Contact number is too short")
    .max(20, "Contact number is too long"),
  location: Yup.string()
    .required("Location is required")
    .matches(nameRegExp, "Location is not valid")
    .min(3, "Location is too short")
    .max(15, "Location is too long"),
  logo: Yup.mixed().test('fileType', 'Only image file is allowed', (value) => { //required("Logo is required").
      if (!value) return true;
      let fileType;
      if (typeof value === 'string') {
        const fileName = value.split('\\').pop();
        fileType = fileName.split('.').pop();
      } else {
        fileType = value.type.split('/').pop();
      }
      return ['jpg', 'jpeg', 'png'].includes(fileType.toLowerCase());
    })
});

const Clients = () => {
  const dispatch = useDispatch();
  const IMAGE_BASE_URL = config.LOGO_URL;
  const users = useSelector((state) => state.clients);
  const allUsers = useSelector((state) => state.allUsers);
  const loading = useSelector((state) => state.allUsers.loading);
  const clientLoading = useSelector((state) => state.clients.loading);
  const emailState = useSelector((state) => state.email);
  const noOfRecords = useSelector((state) => state.clients.clients.noOfRecords);
  const [searchText, setSearchText] = useState(null);
  const [filteredData, setFilteredData] = useState();
  const [roleID, setRoleID] = useState(2);
  const [data, setUsersdata] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [isActive, setIsActive] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [loader, setLoader] = useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);


  useEffect(() => {
    dispatch(listAllUsers());
    Cookies.remove("projectCreationDate")
  }, [dispatch, updateList]);

  useEffect(() => {
    if (isEdit && id) {
      const user = data && data.find((item) => item.id === id);
      formik.setValues({
        name: user.name,
        companyName: user.companyName,
        email: user.email,
        contactNo: user.contactNo,
        contactPerson: user.contactPerson,
        location: user.location,
        logo: user?.logo
      });
      setId(user.id);
    } else {
      formik.setValues({
        name: "",
        companyName: "",
        email: "",
        contactNo: "",
        contactPerson: "",
        location: "",
        logo: ""
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (users?.clients?.listData?.length) setUsersdata(users?.clients?.listData);
  }, [users?.clients?.listData]);

  useEffect(() => {
    const fd =
      data &&
      data.filter((item) =>
        item?.name?.toLowerCase().includes(searchText?.toLowerCase())
      );
    setFilteredData(fd);
  }, [data, searchText]);

  let subtitle;
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setSelectedLogo(null);
    setLoader(false);
    formik.resetForm();
    setIsEdit(false);
    setId(0);
    setIsActive(false);
    setRoleID(2);
    setIsOpen(false);
  }

  function generatePassword() {
    var chars =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var string_length = 8;
    var randomstring = "";
    for (var i = 0; i < string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  }
  const validationSchema = isEdit ? editValidationSchema : addValidationSchema;

  const getCreatedByName = (id) => {
    const foundUser = allUsers.allUsers.listData.find((user) => user.id === id);

    if (foundUser) {
      return foundUser.name;
    }

    return null; // or handle the case when the user with the specified id is not found
  };
  function getImageUrl(imageName) {
    //const imageExtensions = ['jpg', 'jpeg', 'png', 'gif']; // Add more extensions if needed
    // for (let i = 0; i < imageExtensions.length; i++) {
    const imageUrl = `${IMAGE_BASE_URL}/${imageName}.jpg`;//${imageExtensions[i]}`;
    // Check if image exists by attempting to load it
    const img = new Image();
    img.src = imageUrl;
    if (img.complete) {
      return imageUrl;
    }
    return '';
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setLogoFile(file);
    // setSelectedLogo(URL.createObjectURL(file));
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const isFileTypeValid = allowedExtensions.includes(fileExtension);
    const isFileSizeValid = file.size <= 5242880; // 5MB in bytes
    if(isFileTypeValid){
      setSelectedLogo(URL.createObjectURL(file));
    }
    else{
      setSelectedLogo("error");
    }
    if (!isFileTypeValid) {
      formik.setFieldError('logo', 'Please upload a valid image file (jpg, jpeg, or png).');
      return;
    }
    if (!isFileSizeValid) {
      formik.setFieldError('logo', 'Please upload an image file smaller than 5MB.');
      return;
    }
    formik.setFieldError('logo', '');
    formik.setFieldValue('logo', file);
  };

  const handleEditUser = () => {
    formik.handleSubmit();
  };

  const handleAddUser = () => {
    setIsEdit(false);
    formik.handleSubmit();
  };

  const handleDeleteUser = (userId) => {
    Swal
      .fire({
        title: "Are you sure you want to delete this client?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#000000",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: 'confirm-button-swal'
        }
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          var res;
          if (userId !== "") {
            res = await dispatch(deleteUser(userId));
            // Swal.fire(res?.payload?.message);
            if (res?.payload?.message) {
              // Replace "user" with "client" in the message
              var message = res.payload.message;
              const modifiedMessage = message.replace("User", "Client");
              Swal.fire({
                title: modifiedMessage,
                confirmButtonColor: "#000000",
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: 'confirm-button-swal'
                }
              })
            }
            dispatch(
              listClients({
                searchTerm: searchText ? searchText : "",
                sortBy: sort.sortBy ? sort.sortBy : "name",
                sortDirection: sort.sortDirection ? sort.sortDirection : "asc",
                page: pagination.page ? pagination.page : 1,
                pageSize: pagination.pageSize ? pagination.pageSize : 12,
              })
            );
            setUpdateList(true);
          } else {
            Swal.fire({
              title: "Please fill all the details",
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: 'confirm-button-swal'
              }
            })
          }
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      companyName: "",
      email: "",
      contactPerson: "",
      contactNo: "",
      location: "",
      logo: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoader(true);
      openModal(true);
      if (!isEmailUnique) {
        Swal.fire({
          title: "Email already exists!",
          confirmButtonColor: "#000000",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: 'confirm-button-swal'
          }
        })
        closeModal();
        return;
      }
      const password = generatePassword();
      // setLogoFile(formik.values.logo);
      if (formik.isValid) {
        const encryptedUserId = Cookies.get("userId");
        const decryptedBytes = !!encryptedUserId && CryptoJS.AES.decrypt(
          encryptedUserId,
          secret_key
        );
        const userId = decryptedBytes.toString(CryptoJS.enc.Utf8);
        if (isEdit) {
          const res = await dispatch(
            editClient({
              id,
              ...formik.values,
              roleID,
              isActive: true,
              modifiedBy: userId,
              logo: logoFile
            })
          );
          closeModal();
          //await new Promise((resolve) => setTimeout(resolve, 1000));
          Swal.fire({
            title: res?.payload?.message,
            confirmButtonColor: "#000000",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: 'confirm-button-swal'
            }
          })
          dispatch(listAllUsers());
        } else {
          const existingClient =
            allUsers?.allUsers?.listData &&
            allUsers.allUsers.listData.find(
              (user) =>
                user.email === formik.values.email &&
                (!user.name ||
                  user.name.trim() === "" ||
                  user.name.trim() === null)
            );

          if (existingClient) {
            await handleDeleteClient(existingClient.id);
          }
          await new Promise((resolve) => setTimeout(resolve, 1000));
          const res = await dispatch(
            addClient({
              user: {
                ...formik.values,
                roleID,
                isActive,
                name: "",
                createdBy: userId,
                logo: logoFile
              }
            }));
          await new Promise((resolve) => setTimeout(resolve, 1000));
          setUpdateList(true);
          var email = formik.values.email;
          // const users = await dispatch(listAllUsers());
          dispatch(listAllUsers());
          await new Promise((resolve) => setTimeout(resolve, 1000));
          closeModal();
          if (res.payload.result > 0) {
            Swal.fire({
              title: res?.payload?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: 'confirm-button-swal'
              }
            })
          } else {
            Swal.fire({
              title: res?.payload?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: 'confirm-button-swal'
              }
            })
          }
        }
        dispatch(listAllUsers());
        dispatch(
          listClients({
            searchTerm: searchText ? searchText : "",
            sortBy: sort.sortBy ? sort.sortBy : "name",
            sortDirection: sort.sortDirection ? sort.sortDirection : "asc",
            page: pagination.page ? pagination.page : 1,
            pageSize: pagination.pageSize ? pagination.pageSize : 12,
          })
        );
        setUpdateList(true);
        setIsEdit(false);
      }
    },
  });
  const handleDeleteClient = async (userId) => {
    var res;
    if (userId !== "") {
      res = await dispatch(deleteClient(userId));
      setUpdateList(true);
    }
  };
  const getImageById = (email) => {
    const user = !!users.clients.listData && users.clients.listData.find(user => user.email === email && user.isDeleted !== true);
    if (user) {
      //return `${IMAGE_BASE_URL}/${user.id}.jpg`;
      const imageUrl = `${IMAGE_BASE_URL}/${user.id}.jpg`;
      // const img = new Image();
      // img.src = imageUrl;
      // if (img.complete) {
        return imageUrl;
      // }
    }
  };
  const imageById = getImageById(formik.values.email);
  const imageUrl = isEdit && formik.values.email && imageById;

  const isEmailUnique = !allUsers?.allUsers?.listData?.some(user => user.email === formik.values.email && user.id !== id && user.isDeleted !== true);

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 12,
    totalItems: 0,
    totalPages: 0,
  });
  
  const loadNextClients = () => {
    setPagination({page: parseInt(pagination.page) + 1});
  };
  const loadPreviousClients = () => {
    setPagination({page: parseInt(pagination.page) - 1});
  };

  const [sort, setSort] = useState({
    sortBy: "name",
    sortDirection: "asc",
  });

  useEffect(() => {
    dispatch(
      listClients({
        searchTerm: searchText ? searchText : "",
        sortBy: sort.sortBy ? sort.sortBy : "name",
        sortDirection: sort.sortDirection ? sort.sortDirection : "asc",
        page: pagination.page ? pagination.page : 1,
        pageSize: pagination.pageSize ? pagination.pageSize : 12,
      })
    );
  }, [dispatch, searchText, sort, pagination]);

  return (
    <>
      {(loader || loading || clientLoading) && <Loader />}
      <div>
        <div className="mainContent px-4 pt-3">
          <div className="page-header mb-3">
            <div className="row align-items-center">
              <div className="col-sm-12">
                <div className="page-sub-header">
                  <h3 className="page-title">Clients</h3>
                  <div className="page-title-right">
                    <form className="d-flex">
                      <div>
                        <input
                          onChange={(e) => setSearchText(e.target.value)}
                          placeholder="Search"
                          className="form form-control"
                        />
                      </div>
                      {/* <div className="input-group">
                        <input
                          type="text"
                          className="form-control form-control-light datetimepicker"
                          placeholder="DD-MM-YYYY"
                          id="dash-daterange"
                        />
                        <span className="input-group-text btn-primary text-white">
                          <i className="bi bi-calendar3" />
                        </span>
                      </div>
                      <a
                        href="#"
                        className="btn btn-primary ms-2"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Repeat"
                      >
                        <i className="bi bi-arrow-clockwise" />
                      </a>
                      <a
                        href="#"
                        className="btn btn-primary ms-1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Filter"
                      >
                        <i className="bi bi-filter" />
                      </a> */}
                      <a
                        // href="#"
                        className="btn btn-primary ms-2"
                        data-bs-toggle="tooltip"
                        onClick={openModal}
                        data-bs-placement="bottom"
                        data-bs-title="Import Data"
                      >
                        <i className="bi bi-plus-square-dotted" /> Register Client
                      </a>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {data ? (
              data.map((user, index) => (
                <>
                  <div
                    key={index}
                    className="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-3"
                  >
                    <div className="card widget-flat shadow-sm">
                      <div className="card-body">
                        <div className="clientAction">

                          <div>
                            <button
                              type="button"
                              className="btn btn-primary px-1 btn-sm py-0 me-2"
                            >
                              <i
                                className="bi bi-pencil-square"
                                onClick={() => {
                                  setId(user.id);
                                  setIsEdit(true);
                                  openModal(true);
                                }}
                              ></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm px-1 py-0"
                            >
                              <i
                                className="bi bi-trash3"
                                onClick={() => handleDeleteUser(user.id)}
                              ></i>
                            </button>
                          </div>
                        </div>
                        <div className="project-client-action">
                          
                          <Link to={`/projectList/${user.id}`}>
                            <div className="mb-2">
                              {/* <img src={`${getImageUrl(user.id)}?timestamp=${Date.now()}`} alt="Logo" className="project-logo-img" /> */}
                              {/* <img src={`${getImageUrl(user.id)}?timestamp=${Date.now()}`} alt="Logo" className="project-logo-img" /> */}
                              {user.id && <img src={`${IMAGE_BASE_URL}/${user.id}.jpg?timestamp=${Date.now()}`} alt="Logo" className="project-logo-img" />}
                                {/* <img src={getImageUrl(user.id)} alt="Logo" className="project-logo-img" /> */}
                            </div>
                            <div className="project-title-company-name pt-0">
                              {/* <div className="project-logo-img"> */}
                              {user.companyName ? capitalizeWords(user.companyName) : ''}
                              {/* </div> */}
                            </div>
                            <div className="project-title-name pt-0">
                              {user.name ? capitalizeWords(user.name) : ''}
                            </div>
                            <div className="px-4 text-center">
                              {/* <img src={getImageUrl(user.id)} alt="Logo" className="project-logo-img" /> */}
                              {/* {user.id && (
                                <img src={`${IMAGE_BASE_URL}/${user.id}.${'jpg'}`} alt="Logo" className="project-logo-img" />
                              )} */}
                            </div>
                            <div className="invited-name pt-0">
                              {getCreatedByName(user.createdBy) ? 'Invited By:' + getCreatedByName(user.createdBy) : ''}
                            </div>
                          </Link>

                        </div>
                        <div className="px-4 text-center">
                          {/* <img src={project1} className="project-logo-img" /> */}

                        </div>
                        <div className="project-info">
                          <span className>
                            Region:{" "}
                            <span className="fw-bold">{user.location}</span>
                          </span>
                          <span className="value-badge">
                            Value: <span className="fw-bold">00</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))
            ) : (
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="card shadow-sm border-0">
                    <div className="card-body">
                      <div>There are no records to display</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* {data && (
          <>
            <div className="text-center">
              <Button variant="primary" onClick={loadPreviousClients} disabled={noOfRecords >= (12 * parseInt(pagination.page)) ? true : false}>Previous</Button>
              <Button variant="primary" onClick={loadNextClients} disabled={noOfRecords <= (12 * parseInt(pagination.page)) ? true : false}>Next</Button>
            </div>
          </>
        )} */}
        <Modal
          show={modalIsOpen}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => closeModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title>{isEdit ? "Edit Client" : "Registration"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {!isEdit ? (
                <>
                  <div className="col-md-12 mb-3">

                  <div className="text-center"><label htmlFor="logo" className="form-label">Upload Logo</label></div>
                    <div className="text-center changeLogo-wrapper">
  
                      <div className="edit-icon-logo">
                        {selectedLogo ? <i className="editLogo-icon bi bi-pencil-square" onClick={() => document.getElementById('logo').click()} style={{ cursor: 'pointer' }}></i>
                        :
                        <i className="editLogo-icon bi bi-plus-square" onClick={() => document.getElementById('logo').click()} style={{ cursor: 'pointer' }}></i>}
                        {/* <img src={"/Assets/images/defaultLogo.png"} alt="Logo" className="change-logo-img" /> */}
                        {selectedLogo !== "error" && <img src={selectedLogo ? selectedLogo : "/Assets/images/defaultLogo.png"} alt="Logo" className="change-logo-img" />}
                        <input
                          type="file"
                          className={`visually-hidden form-control ${formik.touched.logo && formik.errors.logo
                            ? "is-invalid"
                            : ""
                            }`}
                          id="logo"
                          // className="visually-hidden"
                          placeholder="Logo"
                          value={!!formik.values.logo ? formik.values.logo : ''}
                          onChange={(e) => {
                            handleFileChange(e);
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.logo && formik.errors.logo && (
                          <div className="invalid-feedback">
                            {formik.errors.logo}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="companyName" className="form-label">
                      Company Name<span className="text-danger"> * </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${formik.touched.companyName && formik.errors.companyName
                        ? "is-invalid"
                        : ""
                        }`}
                      id="companyName"
                      placeholder="Enter Company Name"
                      value={!!formik.values.companyName ? capitalizeWords(formik.values.companyName) : ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.companyName && formik.errors.companyName && (
                      <div className="invalid-feedback">
                        {formik.errors.companyName}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="email" className="form-label">
                      Email<span className="text-danger"> * </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${formik.touched.email && formik.errors.email
                        ? "is-invalid"
                        : ""
                        }`}
                      id="email"
                      placeholder="Enter Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="invalid-feedback">
                        {formik.errors.email}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="contactPerson" className="form-label">
                      Contact Person Name<span className="text-danger"> * </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${formik.touched.contactPerson &&
                        formik.errors.contactPerson
                        ? "is-invalid"
                        : ""
                        }`}
                      id="contactPerson"
                      placeholder="Enter Contact Person Name"
                      value={!!formik.values.contactPerson ? capitalizeWords(formik.values.contactPerson) : ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.contactPerson &&
                      formik.errors.contactPerson && (
                        <div className="invalid-feedback">
                          {formik.errors.contactPerson}
                        </div>
                      )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="contactNo" className="form-label">
                      Contact Number
                    </label>
                    <input
                      type="text"
                      className={`form-control ${formik.touched.contactNo && formik.errors.contactNo
                        ? "is-invalid"
                        : ""
                        }`}
                      id="contactNo"
                      placeholder="Enter contact Number"
                      value={formik.values.contactNo}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.contactNo && formik.errors.contactNo && (
                      <div className="invalid-feedback">
                        {formik.errors.contactNo}
                      </div>
                    )}
                  </div>

                  <div className="col-md-12 mb-3">
                    <label htmlFor="location" className="form-label">
                      Location<span className="text-danger"> * </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${formik.touched.location && formik.errors.location
                        ? "is-invalid"
                        : ""
                        }`}
                      id="location"
                      placeholder="Enter Location"
                      value={!!formik.values.location ? capitalizeWords(formik.values.location) : ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.location && formik.errors.location && (
                      <div className="invalid-feedback">
                        {formik.errors.location}
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-12 mb-3">

                    {/* <label htmlFor="logo" className="form-label">
                      Change Logo
                    </label> */}
                    <div className="text-center changeLogo-wrapper">
                      <div className="edit-icon-logo">
                        <i className="editLogo-icon bi bi-pencil-square" onClick={() => document.getElementById('logo').click()} style={{ cursor: 'pointer' }}></i>
                        {selectedLogo !== "error" && <img src={selectedLogo ? selectedLogo : `${imageUrl}?timestamp=${Date.now()}`} alt="Logo" className="change-logo-img" />}
                        <input
                          type="file"
                          className={`visually-hidden form-control ${formik.touched.logo && formik.errors.logo
                            ? "is-invalid"
                            : ""
                            }`}
                          id="logo"
                          // className="visually-hidden"
                          placeholder="Logo"
                          value={!!formik.values.logo ? formik.values.logo : ''}
                          onChange={(e) => {
                            handleFileChange(e);
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.logo && formik.errors.logo && (
                          <div className="invalid-feedback">
                            {formik.errors.logo}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="name" className="form-label">
                      Name<span className="text-danger"> * </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${formik.touched.name && formik.errors.name
                        ? "is-invalid"
                        : ""
                        }`}
                      id="name"
                      placeholder="Enter Name"
                      value={!!formik.values.name ? capitalizeWords(formik.values.name) : ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="invalid-feedback">
                        {formik.errors.name}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="companyName" className="form-label">
                      Company Name<span className="text-danger"> * </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${formik.touched.companyName && formik.errors.companyName
                        ? "is-invalid"
                        : ""
                        }`}
                      id="companyName"
                      placeholder="Enter Company Name"
                      value={!!formik.values.companyName ? capitalizeWords(formik.values.companyName) : ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.companyName && formik.errors.companyName && (
                      <div className="invalid-feedback">
                        {formik.errors.companyName}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="email" className="form-label">
                      Email<span className="text-danger"> * </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${formik.touched.email && formik.errors.email
                        ? "is-invalid"
                        : ""
                        }`}
                      id="email"
                      placeholder="Enter Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      readOnly
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="invalid-feedback">
                        {formik.errors.email}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="contactPerson" className="form-label">
                      Contact Person Name<span className="text-danger"> * </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${formik.touched.contactPerson &&
                        formik.errors.contactPerson
                        ? "is-invalid"
                        : ""
                        }`}
                      id="contactPerson"
                      placeholder="Enter Contact Person Name"
                      value={formik.values.contactPerson}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.contactPerson &&
                      formik.errors.contactPerson && (
                        <div className="invalid-feedback">
                          {formik.errors.contactPerson}
                        </div>
                      )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="contactNo" className="form-label">
                      Contact Number
                    </label>
                    <input
                      type="text"
                      className={`form-control ${formik.touched.contactNo && formik.errors.contactNo
                        ? "is-invalid"
                        : ""
                        }`}
                      id="contactNo"
                      placeholder="Enter contact Number"
                      value={formik.values.contactNo}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.contactNo && formik.errors.contactNo && (
                      <div className="invalid-feedback">
                        {formik.errors.contactNo}
                      </div>
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="location" className="form-label">
                      Location<span className="text-danger"> * </span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${formik.touched.location && formik.errors.location
                        ? "is-invalid"
                        : ""
                        }`}
                      id="location"
                      placeholder="Enter Location"
                      value={formik.values.location}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.location && formik.errors.location && (
                      <div className="invalid-feedback">
                        {formik.errors.location}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={closeModal}>
              Close
            </Button>
            <Button variant="primary" onClick={isEdit ? handleEditUser : handleAddUser} disabled={loader}>
              {isEdit ? "Update " : "Register "} Client
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
export default Clients;
