import React from "react";
// import LinkExpiredImage from "../../Assets/images/Link-expired.svg";

const LinkExpired = () => {
  return (
    <div className="page-frame">
      <div className="d-flex w-40 flex-column text-center">
        <div>
          <img
            src={"/Assets/images/Link-expired.svg"}
            alt="Expired Link"
            className="mx-450 d-block mx-auto"
          />
        </div>
        <div className="mt-5">
          {/* <button className="btn btn-lg btn-primary">Go Back</button> */}
        </div>
      </div>
    </div>
  );
};

export default LinkExpired;
