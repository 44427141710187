import { createSlice } from "@reduxjs/toolkit";
import { allProjectsReportList, projectsReport } from "../Actions/projectsReportActions";
import { projectReportHistoryList } from "../Actions/projectsActions";

const initialState = {
  projectsReport: [],
  projectReport: [],
  projectReportDDList: [],
  conditionBreakdownList: [],
  noOfRecords: 0,
  reportRecordsCounts: null,
  projectName: null,
  clientName: null,
  author: null,
  ffeSurveyors: null,
  columnJSON:null,
  loading: false,
  error: null,
  projectReportsHistory: [],
};

const projectReportSlice = createSlice({
  name: "projectsReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(projectsReport.pending, (state) => {
        state.loading = true;
        state.projectReport = [];
        state.projectName = null;
        state.clientName = null;
        state.author = null;
        state.ffeSurveyors = null;
        state.columnJSON = null;
      })
      .addCase(projectsReport.fulfilled, (state, action) => {
        state.loading = false;
        state.projectReport = action.payload.projectReportList;
        state.conditionBreakdownList = action.payload.conditionBreakdownList;
        state.reportRecordsCounts = action.payload.reportRecordsCounts;
        state.noOfRecords = action.payload.noOfRecords;
        state.projectName = action.payload.projectName;
        state.clientName = action.payload.clientName;
        state.author = action.payload.author;
        state.ffeSurveyors = action.payload.ffeSurveyors;
        state.columnJSON = action.payload.columnJSON;
      })
      .addCase(projectsReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(allProjectsReportList.pending, (state) => {
        state.projectReportDDList = [];
      })
      .addCase(allProjectsReportList.fulfilled, (state, action) => {
        state.projectReportDDList = action.payload;
      })
      .addCase(allProjectsReportList.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(projectReportHistoryList.pending, (state) => {
        state.loading = true;
        state.projectReportsHistory = [];
        state.noOfRecords = 0;
      })
      .addCase(projectReportHistoryList.fulfilled, (state, action) => {
        state.loading = false;
        state.projectReportsHistory = action.payload.projectReportHistoryList;
        state.noOfRecords = action.payload.noOfRecords;
      })
      .addCase(projectReportHistoryList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default projectReportSlice.reducer;
