/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
// import project1 from "../../Assets/images/project-logo/project1.png";
import { useDispatch, useSelector } from "react-redux";
import {
  listUsers,
  deleteUser,
  editUser,
} from "../../Redux/Actions/usersActions";
import { listAllUsers } from "../../Redux/Actions/allUsersActions";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import { addUser } from "../../Redux/Actions/usersActions";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import Loader from "../Common/Loader/loader";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import * as CryptoJS from "crypto-js";
import { secret_key } from "../../APIProxy/secret";
import { capitalizeWords } from "../Common/capitalizeWord";

const nameRegExp = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Name is too short")
    .matches(nameRegExp, "Name is not valid"), //.max(15, "Name is too long"),
  email: Yup.string()
    .email("Email is not valid")
    .matches(emailRegExp, "Email is not valid")
    .required("Email is required"),
});

const UsersList = () => {
  const loginUserId =
    !!Cookies.get("userId") &&
    CryptoJS.AES.decrypt(Cookies.get("userId"), secret_key).toString(
      CryptoJS.enc.Utf8
    );
  const userRole = Cookies.get("userRole");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector((state) => state.users);
  const allUsers = useSelector((state) => state.allUsers);
  const loading = useSelector((state) => state.allUsers.loading);
  const noOfRecords = useSelector((state) => state.users.noOfRecords);
  const [searchText, setSearchText] = useState(null);
  const [filteredData, setFilteredData] = useState();
  const [data, setUsersdata] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState("Name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectedRole, setSelectedRole] = useState("employee");
  const [loader, setLoader] = useState(false);

  const parentID = userRole === "Client" ? loginUserId : null;

  useEffect(() => {
    if (!!userRole && userRole !== "ClientUser") {
      dispatch(listAllUsers());
    }
  }, [dispatch, updateList]);

  useEffect(() => {
    if (!!userRole && userRole !== "ClientUser") {
      dispatch(
        listUsers({
          searchTerm: searchText,
          sortBy,
          sortDirection,
          page,
          pageSize,
          parentId: parentID,
        })
      );
    }
  }, [dispatch, searchText, sortBy, sortDirection, page, pageSize, updateList]);

  useEffect(() => {
    if (isEdit && id) {
      const user = data && data.find((item) => item.id === id);

      formik.setValues({
        name: user.name,
        email: user.email,
      });
      setId(user.id);
      setSelectedRole(user.roleName.toLowerCase());
    } else {
      formik.setValues({
        name: "",
        email: "",
      });
    }
  }, [isEdit, id]);

  const handleDeleteUser = (userId) => {
    Swal.fire({
      title: "Are you sure you want to delete this user?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#000000",

      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "confirm-button-swal",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (userId !== "") {
          var res = await dispatch(deleteUser(userId));
          if (res.payload.result == 0) {
            dispatch(
              listUsers({
                searchTerm: searchText,
                sortBy,
                sortDirection,
                page,
                pageSize,
                parentId: parentID,
              })
            );
            Swal.fire({
              title: res?.payload?.message,
              confirmButtonColor: "#000000",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "confirm-button-swal",
              },
            });
            setUpdateList(true);
            const encryptedUserId = Cookies.get("userId");
            const decryptedBytes =
              !!encryptedUserId &&
              CryptoJS.AES.decrypt(encryptedUserId, secret_key);
            const loginUserId = decryptedBytes.toString(CryptoJS.enc.Utf8);
            if (userId === loginUserId) {
              Cookies.remove("COOKIE_JWTTOKEN");
              Cookies.remove("userId");
              Cookies.remove("userRole");
              Cookies.remove("userName");
              Cookies.remove("projectCreationDate");
              Cookies.remove("email");
              navigate("/");
            }
          }
        } else {
          Swal.fire({
            title: "Please fill all the details",
            confirmButtonColor: "#000000",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "confirm-button-swal",
            },
          });
        }
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema,
    onSubmit: async () => {
      setLoader(true);
      openModal(true);

      if (!isEmailUnique) {
        Swal.fire({
          title: "Email already exists!",
          confirmButtonColor: "#000000",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "confirm-button-swal",
          },
        });
        closeModal();
        return;
      }

      const password = generatePassword();
      let roleID = 1;
      if (selectedRole === "employee") {
        roleID = 3;
      }
      if (userRole === "Client") {
        roleID = 4;
      }

      if (formik.isValid) {
        if (isEdit) {
          const res = await dispatch(
            editUser({ id, ...formik.values, roleID })
          );
          closeModal();
          Swal.fire({
            title: res?.payload?.message,
            confirmButtonColor: "#000000",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "confirm-button-swal",
            },
          });
          dispatch(listAllUsers());
        } else {
          const res = await dispatch(
            addUser({ ...formik.values, password, roleID, parentID })
          );
          closeModal();
          Swal.fire({
            title: res?.payload?.message,
            confirmButtonColor: "#000000",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "confirm-button-swal",
            },
          });
          dispatch(listAllUsers());
        }
        dispatch(listAllUsers());
        dispatch(
          listUsers({
            searchText,
            sortBy,
            sortDirection,
            page,
            pageSize,
            parentId: parentID,
          })
        );
        setUpdateList(true);
        setIsEdit(false);
      }
    },
  });
  const handleEditUser = () => {
    formik.handleSubmit();
  };

  const handleAddUser = () => {
    setIsEdit(false);
    formik.handleSubmit();
  };

  const isEmailUnique = !allUsers?.allUsers?.listData?.some(
    (user) =>
      user.email === formik.values.email &&
      user.id !== id &&
      user.isDeleted !== true
  );

  useEffect(() => {
    if (users?.users?.listData?.length || searchText)
      setUsersdata(users?.users?.listData);
  }, [users?.users?.listData, searchText]);

  useEffect(() => {
    const fd =
      data &&
      data.filter(
        (item) =>
          item?.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
          item?.email?.toLowerCase().includes(searchText?.toLowerCase())
      );
    setFilteredData(fd);
  }, [data, searchText]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setLoader(false);
    formik.resetForm();
    setIsEdit(false);
    setId(0);
    setIsOpen(false);
  }

  function generatePassword() {
    var chars =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var string_length = 8;
    var randomstring = "";
    for (var i = 0; i < string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  }

  const columns = [
    {
      name: "Sr. no",
      cell: (row, index) => (page - 1) * 10 + index + 1,
      // sortable: true,
    },
    {
      name: "Name",
      selector: (row) => capitalizeWords(row.name),
      sortable: true,
      sortField: "Name",
    },
    {
      name: "Type",
      selector: (row) => row.roleName,
      sortable: true,
      sortField: "roleName",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      sortField: "Email",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div className="actions ">
            <a className="btn btn-sm bg-warning-subtle me-2">
              <i
                className="bi bi-pencil-square"
                onClick={() => {
                  setId(row.id);
                  setIsEdit(true);
                  openModal(true);
                }}
              />
            </a>
            <a className="btn btn-sm bg-danger-subtle me-2 ">
              <i
                className="bi bi-trash3"
                onClick={() => handleDeleteUser(row.id)}
              />
            </a>
          </div>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "130px",
    },
  ];
  const getUsersList = (page, pageSize, sortBy, sortDirection, searchText) => {
    const data = {
      page: page,
      pageSize: pageSize,
      sortBy: !!sortBy ? sortBy : null,
      sortDirection: !!sortDirection ? sortDirection : null,
      searchText: !!searchText ? searchText : null,
      parentId: parentID,
    };
    if (!!userRole && userRole !== "ClientUser") {
      dispatch(listUsers(data));
    }
  };

  const handleSort = (column, sortDirection) => {
    const sortByName = !!column.sortField ? column.sortField : null;
    setSortBy(sortByName);
    setSortDirection(sortDirection);
    getUsersList(1, pageSize, sortByName, sortDirection, searchText);
  };

  const handlePageChange = (page) => {
    setPage(page);
    getUsersList(page, pageSize, sortBy, sortDirection, searchText);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPageSize(newPerPage);
    setPage(page);
    getUsersList(page, newPerPage, sortBy, sortDirection, searchText);
  };
  return (
    <>
      {(loader || loading) && <Loader />}
      <div>
        <div className="mainContent px-4 pt-3">
          <div className="page-header mb-3">
            <div className="row align-items-center">
              <div className="col-sm-12">
                <div className="page-sub-header">
                  <h3 className="page-title">User List</h3>
                  <div className="page-title-right">
                    <div className="symbol-project bg-light">
                      {/* <img src={project1} alt="image" className="p-2" /> */}
                    </div>

                    <a
                      //   href="#"
                      className="btn btn-primary ms-2"
                      onClick={openModal}
                    >
                      <span
                        className=""
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Invite user"
                      >
                        <i className="bi bi-person-add"></i>{" "}
                        {userRole === "Client" ? "Invite User" : "Add User"}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="card shadow-sm border-0">
                <div className="card-body p-9">
                  <DataTable
                    columns={columns}
                    // data={filteredData && filteredData}
                    data={data}
                    className="table table-bordered table-striped"
                    filterServer={true}
                    pagination
                    paginationServer
                    paginationTotalRows={noOfRecords}
                    subHeader
                    subHeaderComponent={
                      <div className="col-md-2">
                        <input
                          onChange={(e) => setSearchText(e.target.value)}
                          placeholder="Search"
                          className="form form-control"
                        />
                      </div>
                    }
                    onChangeRowsPerPage={(newPerPage, page) =>
                      handlePerRowsChange(newPerPage, page)
                    }
                    onChangePage={(page) => handlePageChange(page)}
                    onSort={(column, sortDirection) =>
                      handleSort(column, sortDirection)
                    }
                    sortServer
                    highlightOnHover
                    searchableRows={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={modalIsOpen}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => closeModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title>{isEdit ? "Edit " : "Add "}User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 mb-3">
                <label htmlFor="name" className="form-label">
                  Name<span className="text-danger"> * </span>
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    formik.touched.name && formik.errors.name
                      ? "is-invalid"
                      : ""
                  }`}
                  id="name"
                  placeholder="Enter Name"
                  value={
                    !!formik.values.name
                      ? capitalizeWords(formik.values.name)
                      : ""
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="invalid-feedback">{formik.errors.name}</div>
                )}
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="email" className="form-label">
                  Email<span className="text-danger"> * </span>
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    formik.touched.email && formik.errors.email
                      ? "is-invalid"
                      : ""
                  }`}
                  id="email"
                  placeholder="Enter Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  readOnly={isEdit ? true : false}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                )}
              </div>
              {userRole === "Client" && !isEdit ? (
                <></>
              ) : userRole === "Client" && isEdit ? (
                <div className="col-md-12 mb-3">
                  <label htmlFor="role" className="form-label">
                    User Type<span className="text-danger"> * </span>
                  </label>
                  <select
                    className="form-select"
                    id="role"
                    value={selectedRole}
                    disabled={isEdit ? true : false}
                    onChange={(e) => setSelectedRole(e.target.value)}
                  >
                    <option value="ClientUser">ClientUser</option>
                  </select>
                </div>
              ) : (
                <div className="col-md-12 mb-3">
                  <label htmlFor="role" className="form-label">
                    User Type<span className="text-danger"> * </span>
                  </label>
                  <select
                    className="form-select"
                    id="role"
                    value={selectedRole}
                    disabled={isEdit ? true : false}
                    onChange={(e) => setSelectedRole(e.target.value)}
                  >
                    <option value="admin">Admin</option>
                    <option value="employee">Employee</option>
                  </select>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={closeModal}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={isEdit ? handleEditUser : handleAddUser}
              disabled={loader}
            >
              Save changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
export default UsersList;
