import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie"
import CryptoJS from "crypto-js"
import {
  API_APPROVE_CARBON_SAVING_KG,
  API_APPROVE_COLOURS,
  API_APPROVE_COST,
  API_APPROVE_DESCRIPTION,
  API_APPROVE_ESFA_CODE,
  API_APPROVE_ITEM_TYPE,
  API_CARBON_SAVING_KG_MASTER_LIST,
  API_COLOURS_MASTER_LIST,
  API_COSTING_MASTER_LIST,
  API_DESCRIPTION_MASTER_LIST,
  API_ESFA_CODES_MASTER_LIST,
  API_ITEM_TYPE_MASTER_LIST,
} from "../../APIProxy/api";

const token = !!Cookies.get("COOKIE_JWTTOKEN") && Cookies.get("COOKIE_JWTTOKEN");
let decodedToken = '';

if (token) {
  const decryptedToken = CryptoJS.AES.decrypt(token, "CRYPTO_SECRET_KEY");
  decodedToken = decryptedToken.toString(CryptoJS.enc.Utf8);
}
export const descriptionMasterList = createAsyncThunk(
  "ApprovalMasters/DescriptionList",
  async (data) => {
    const response = await axios.get(`${API_DESCRIPTION_MASTER_LIST}`, {
      headers: {'Authorization': `Bearer ${decodedToken}`},
      params: data,
    });
    return response.data;
  }
);

export const itemTypeMasterList = createAsyncThunk(
  "ApprovalMasters/ItemTypeList",
  async (data) => {
    const response = await axios.get(`${API_ITEM_TYPE_MASTER_LIST}`, {
      headers: {'Authorization': `Bearer ${decodedToken}`},
      params: data,
    });
    return response.data;
  }
);

export const esfaCodesMasterList = createAsyncThunk(
  "ApprovalMasters/ESFACodesList",
  async (data) => {
    const response = await axios.get(`${API_ESFA_CODES_MASTER_LIST}`, {
      headers: {'Authorization': `Bearer ${decodedToken}`},
      params: data,
    });
    return response.data;
  }
);

export const coloursMasterList = createAsyncThunk(
  "ApprovalMasters/ColoursList",
  async (data) => {
    const response = await axios.get(`${API_COLOURS_MASTER_LIST}`, {
      headers: {'Authorization': `Bearer ${decodedToken}`},
      params: data,
    });
    return response.data;
  }
);

export const costMasterList = createAsyncThunk(
  "ApprovalMasters/CostingList",
  async (data) => {
    const response = await axios.get(`${API_COSTING_MASTER_LIST}`, {
      headers: {'Authorization': `Bearer ${decodedToken}`},
      params: data,
    });
    return response.data;
  }
);

export const carbonSavingKGMasterList = createAsyncThunk(
  "ApprovalMasters/CarbonSavingKGList",
  async (data) => {
    const response = await axios.get(`${API_CARBON_SAVING_KG_MASTER_LIST}`, {
      headers: {'Authorization': `Bearer ${decodedToken}`},
      params: data,
    });
    return response.data;
  }
);

// approve actions
export const approveDescription = createAsyncThunk(
  "ApprovalMasters/approveDescription",
  async (data) => {
    const response = await axios.post(API_APPROVE_DESCRIPTION, null ,{
      headers: {'Authorization': `Bearer ${decodedToken}`},
      params: { id: data.id, is_delete: data.is_delete },
    });
    return response.data;
  }
);

export const approveItemType = createAsyncThunk(
  "ApprovalMasters/approveItemType",
  async (data) => {
    const response = await axios.post(API_APPROVE_ITEM_TYPE, null , {
      headers: {'Authorization': `Bearer ${decodedToken}`},
      params: { id: data.id, is_delete: data.is_delete },
    });
    return response.data;
  }
);

export const approveEsfaCode = createAsyncThunk(
  "ApprovalMasters/approveEsfaCode",
  async (data) => {
    const response = await axios.post(API_APPROVE_ESFA_CODE, null ,{
      headers: {'Authorization': `Bearer ${decodedToken}`},
      params: { id: data.id, is_delete: data.is_delete },
    });
    return response.data;
  }
);

export const approveColours = createAsyncThunk(
  "ApprovalMasters/approveColours",
  async (data) => {
    const response = await axios.post(API_APPROVE_COLOURS, null ,{
      headers: {'Authorization': `Bearer ${decodedToken}`},
      params: { id: data.id, is_delete: data.is_delete },
    });
    return response.data;
  }
);

export const approveCost = createAsyncThunk(
  "ApprovalMasters/approveCost",
  async (data) => {
    const response = await axios.post(API_APPROVE_COST, null ,{
      headers: {'Authorization': `Bearer ${decodedToken}`},
      params: { id: data.id, is_delete: data.is_delete },
    });
    return response.data;
  }
);

export const approveCarbonSavingKG = createAsyncThunk(
  "ApprovalMasters/approveCarbonSavingKG",
  async (data) => {
    const response = await axios.post(API_APPROVE_CARBON_SAVING_KG, null,{
      headers: {'Authorization': `Bearer ${decodedToken}`},
      params: { id: data.id, is_delete: data.is_delete },
    });
    return response.data;
  }
);
