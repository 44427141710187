import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie"
import CryptoJS from "crypto-js"
import {
  API_PROJECT_DETAILS_BY_QRCODE
} from "../../APIProxy/api";

//Project DetailsByQRCode APIs
const token = !!Cookies.get("COOKIE_JWTTOKEN") && Cookies.get("COOKIE_JWTTOKEN");
let decodedToken = '';

if (token) {
  const decryptedToken = CryptoJS.AES.decrypt(token, "CRYPTO_SECRET_KEY");
  decodedToken = decryptedToken.toString(CryptoJS.enc.Utf8);
}

export const projectDetailsByQRCode = createAsyncThunk(
  "project/projectDetailsByCode",
  async (data) => {
    const response = await axios.get(`${API_PROJECT_DETAILS_BY_QRCODE}`, {
      //headers: {'Authorization': `Bearer ${decodedToken}`},
      params: { QRCode: data },
    });
    return response.data;
  }
);

