/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
// import avatar1 from "../../Assets/images/avatar-1.jpg";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { capitalizeWords } from "./capitalizeWord";

export default function Navbar({ toggleSidebar, uploadingFiles, successUploadCount, clearUploadStatus }) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const handleChangePassword = () => {
    navigate("/changepassword");
  };
  const handleLogout = () => {
    Cookies.remove("COOKIE_JWTTOKEN");
    Cookies.remove("userId");
    Cookies.remove("userRole");
    Cookies.remove("userName");
    Cookies.remove("projectCreationDate");
    Cookies.remove("email");
    navigate("/");
  };

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <nav className="navbar navbar-expand sticky-top px-4 py-2">
      {/* <a href="index.html" className="navbar-brand d-flex d-lg-none me-4">
      <h2 className="text-primary mb-0">Class</h2>
    </a> */}
      <a href="#" className="sidebar-toggler flex-shrink-0" onClick={toggleSidebar}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-fold-down"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 11v8l3 -3m-6 0l3 3" />
          <path d="M9 7l1 0" />
          <path d="M14 7l1 0" />
          <path d="M19 7l1 0" />
          <path d="M4 7l1 0" />
        </svg>
      </a>
      <div className="navbar-nav align-items-center ms-auto">
        {/* Notification */}
         <div className="nav-item dropdown">
          {uploadingFiles.length > 0 && (
                    <div className="">
                      <div className="d-flex align-items-center">
                        {successUploadCount !== uploadingFiles.length ? (
                          <>
                            <div className="upload-status-box">
                              <div
                                className="spinner-border text-success me-2"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>

                              <div className="ms-3">
                                <h6 className="my-0">Uploading...</h6>
                                <div className="my-0 fw-normal">
                                  {successUploadCount}/{uploadingFiles.length}{" "}
                                  Images
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="upload-status-box-success">
                              <div>
                                <h5 className="my-0">Uploaded Successfully</h5>
                                <h6 className="my-0 fw-normal">
                                  {successUploadCount} Images
                                </h6>
                              </div>

                              <div className="ms-3">
                                <button
                                  type="button"
                                  className="btn-close"
                                  aria-label="Close"
                                  onClick={() => clearUploadStatus()}    
                                ></button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  </div>
        {/* <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"> */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bell-ringing" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6">
            </path>
            <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
            <path d="M21 6.727a11.05 11.05 0 0 0 -2.794 -3.727" />
            <path d="M3 6.727a11.05 11.05 0 0 1 2.792 -3.727" />
          </svg> */}
        








        {/* </a> */}
        
        {/*<div className="dropdown-menu dropdown-menu-end border-0 rounded-0 rounded-bottom m-0 shadow-sm">
          <div className="p-2 border-top-0 border-start-0 border-end-0 border-dashed border">
            <div className="row align-items-center">
              <div className="col">
                <h6 className="m-0 fnt-16 fw-semibold"> Notification</h6>
              </div>
              <div className="col-auto">
                <a href="javascript: void(0);" className="text-dark text-decoration-underline">
                  <small>Clear All</small>
                </a>
              </div>
            </div>
          </div>
          <div className="px-2">
            <div className="simplebar-wrapper">
              <div className="simplebar-height-auto-observer-wrapper">
                <div className="simplebar-height-auto-observer" />
              </div>
              <div className="simplebar-mask">
                <div className="simplebar-offset" style={{right: '0px', bottom: '0px'}}>
                  <div className="simplebar-content-wrapper" tabIndex={0} role="region" aria-label="scrollable content" style={{height: 'auto', overflow: 'hidden scroll'}}>
                    <div className="simplebar-content" style={{padding: '0px 12px'}}>
                      <h5 className="text-muted fnt-13 fw-normal mt-2">Today</h5>

                      <a href="javascript:void(0);" className="dropdown-item p-0 notify-item card unread-noti shadow-none mb-2">
                        <div className="card-body">
                          <span className="float-end noti-close-btn text-muted"><i className="mdi mdi-close" /></span>
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 text-truncate ms-2">
                              <h5 className="noti-item-title fw-semibold fnt-14">Datacorp
                                <small className="fw-normal text-muted ms-1">1 min
                                  ago</small>
                              </h5>
                              <small className="noti-item-subtitle text-muted">Caleb
                                Flakelar commented on Admin</small>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a href="javascript:void(0);" className="dropdown-item p-0 notify-item card read-noti shadow-none mb-2">
                        <div className="card-body">
                          <span className="float-end noti-close-btn text-muted"><i className="mdi mdi-close" /></span>
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 text-truncate ms-2">
                              <h5 className="noti-item-title fw-semibold fnt-14">Admin
                                <small className="fw-normal text-muted ms-1">1 hours
                                  ago</small>
                              </h5>
                              <small className="noti-item-subtitle text-muted">New user
                                registered</small>
                            </div>
                          </div>
                        </div>
                      </a>
                      <h5 className="text-muted fnt-13 fw-normal mt-0">Yesterday</h5>

                      <a href="javascript:void(0);" className="dropdown-item p-0 notify-item card read-noti shadow-none mb-2">
                        <div className="card-body">
                          <span className="float-end noti-close-btn text-muted"><i className="mdi mdi-close" /></span>
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 text-truncate ms-2">
                              <h5 className="noti-item-title fw-semibold fnt-14">Cristina
                                Pride <small className="fw-normal text-muted ms-1">1 day
                                  ago</small></h5>
                              <small className="noti-item-subtitle text-muted">Hi, How are
                                you? What about our next meeting</small>
                            </div>
                          </div>
                        </div>
                      </a>
                      <h5 className="text-muted fnt-13 fw-normal mt-0">30 Dec 2021</h5>

                      <a href="javascript:void(0);" className="dropdown-item p-0 notify-item card read-noti shadow-none mb-2">
                        <div className="card-body">
                          <span className="float-end noti-close-btn text-muted"><i className="mdi mdi-close" /></span>
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 text-truncate ms-2">
                              <h5 className="noti-item-title fw-semibold fnt-14">Datacorp
                              </h5>
                              <small className="noti-item-subtitle text-muted">Caleb
                                Flakelar commented on Admin</small>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a href="javascript:void(0);" className="dropdown-item p-0 notify-item card read-noti shadow-none mb-2">
                        <div className="card-body">
                          <span className="float-end noti-close-btn text-muted"><i className="mdi mdi-close" /></span>
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 text-truncate ms-2">
                              <h5 className="noti-item-title fw-semibold fnt-14">Karen
                                Robinson</h5>
                              <small className="noti-item-subtitle text-muted">Wow ! this
                                admin looks good and awesome design</small>
                            </div>
                          </div>
                        </div>
                      </a>
                      <div className="text-center">
                        <i className="mdi mdi-dots-circle mdi-spin text-muted h3 mt-0" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a href="javascript:void(0);" className="dropdown-item text-center text-dark notify-item border-top py-2">
            View All
          </a>
        </div>
      </div> */}
        <div className="dropdown" ref={dropdownRef}>
          <a
            className="nav-link dropdown-toggle arrow-none nav-user px-2"
            data-bs-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="false"
            aria-expanded={isDropdownVisible}
            onClick={toggleDropdown}
          >
            <span className="account-user-avatar me-3">
              {/* <img
                src={"/Assets/images/avatar-1.jpg"}
                alt="userImage"
                width={32}
                className="rounded-circle"
              /> */}
              <i className="bi bi-person user-profile-icon" />
            </span>
            <span className="d-lg-flex flex-column gap-1 d-none">
              <h5 className="my-0">{Cookies.get("userName") ? capitalizeWords(Cookies.get("userName")) : ''}</h5>
              <h6 className="my-0 fw-normal">{Cookies.get("userRole")}</h6>
            </span>
            {/* <span className="d-lg-flex flex-column gap-1 d-none"><Link to="/changepassword" className="btn-link fw-bold"> Change Password? </Link></span>
            <span className="d-lg-flex flex-column gap-1 d-none">
            <button type="button" className="btn btn-primary px-1 py-0 ms-2" onClick={() => handleLogout()}><i className="bi bi-power"></i></button>
            </span> */}
          </a>
          <div
            className={`dropdown-menu dropdown-menu-end dropdown-menu-lg-start dropdown-menu-animated profile-dropdown ${
              isDropdownVisible ? "show" : ""
            }`}
          >
            {/* item*/}
            {/* <div className=" dropdown-header noti-title">
              <h6 className="text-overflow m-0">Welcome !</h6>
            </div> */}
            {/* <a href="javascript:void(0);" className="dropdown-item">
              <i className="mdi mdi-account-circle me-1" />
              <span>My Account</span>
            </a>
            <a href="javascript:void(0);" className="dropdown-item">
              <i className="mdi mdi-account-edit me-1" />
              <span>Settings</span>
            </a>
            <a href="javascript:void(0);" className="dropdown-item">
              <i className="mdi mdi-lifebuoy me-1" />
              <span>Support</span>
            </a>
            <a href="javascript:void(0);" className="dropdown-item">
              <i className="mdi mdi-lock-outline me-1" />
              <span>Lock Screen</span>
            </a> */}
            <a
              href=""
              className="dropdown-item"
              onClick={() => handleChangePassword()}
            >
              <i className="mdi mdi-logout me-1" />
              <span>Change Password</span>
            </a>
            <a href="" className="dropdown-item" onClick={() => handleLogout()}>
              <i className="mdi mdi-logout me-1" />
              <span>Logout</span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}
