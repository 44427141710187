import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from "js-cookie"
import CryptoJS from "crypto-js"
import { API_DECRYPT_USER_ID} from "../../APIProxy/api";

//Client decryt ID
const token = !!Cookies.get("COOKIE_JWTTOKEN") && Cookies.get("COOKIE_JWTTOKEN");
let decodedToken = '';

if (token) {
  const decryptedToken = CryptoJS.AES.decrypt(token, "CRYPTO_SECRET_KEY");
  decodedToken = decryptedToken.toString(CryptoJS.enc.Utf8);
}
export const decryptUserID = createAsyncThunk('user/decryptUserID', async (userId) => {
  const response = await axios.get(API_DECRYPT_USER_ID + `?id=${userId}`,{ headers: {'Authorization': `Bearer ${decodedToken}`}}) //, { ID: userId });
  return response.data;
});


