import { createSlice } from '@reduxjs/toolkit';
import { resetPassword } from '../Actions/resetPasswordActions';

const initialState = {
  resetPassword: [],
  loading: false,
  error: null,
};

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    //resetPassword Slices
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.resetPassword = action.payload;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
    ;
  },
});

export default resetPasswordSlice.reducer;