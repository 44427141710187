import React from "react";
import Sidebar from "../Common/Sidebar";
import Navbar from "../Common/navbar";
import UsersList from "../Users/usersList";
import { Route, Routes } from "react-router-dom";
import ProjectList from "../Users/projectList";
import ClientDashboard from "../Users/clientDashboard";
import Clients from "../Users/clients";
import AddClientsDetailsWrapper from "../Users/addClientsDetailsWrapper";
import ProjectDetails from "../Users/ProjectDetails";
import { useState } from "react";
// import ProjectReport from "../Users/ProjectReport";
import MastersApproval from "../MastersApproval/MastersApproval";
import Barcode from "../Barcode/Barcode";
import QRCode from "../QRCode/QRCode";
import ProjectReports from "../Users/ProjectReports";
import ProjectDetailsByQRCode from "../QRCode/ProjectDetailsByQRCode";
import ChangeRequestApprovals from "../Users/ChangeRequestApprovals";
import ChangeRequestProjectReports from "../Users/ChangeRequestProjectReports";

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [successUploadCount, setSuccessUploadCount] = useState(0);

  const clearUploadStatus = () => {
    setSuccessUploadCount(0);
    setUploadingFiles([]);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
      <Sidebar isOpen={isSidebarOpen} />
      <div className={`content ${isSidebarOpen ? "open" : ""}`}>
        <Navbar
          toggleSidebar={toggleSidebar}
          uploadingFiles={uploadingFiles}
          successUploadCount={successUploadCount}
          clearUploadStatus={clearUploadStatus}
        />
        <Routes>
          <Route path={"/clients"} element={<Clients />} />
          <Route path={"/admin"} element={<UsersList />} />
          <Route path={"/users"} element={<UsersList />} />
          {/* <Route
              path={"/registerClient/:userId"}
              element={<AddClientsDetailsWrapper />}
            /> */}
          <Route
            path="/projectList/:userId"
            element={
              <ProjectList
                uploadingFiles={uploadingFiles}
                setUploadingFiles={setUploadingFiles}
                successUploadCount={successUploadCount}
                setSuccessUploadCount={setSuccessUploadCount}
                clearUploadStatus={clearUploadStatus}
              />
            }
          />
          <Route
            path="/ProjectDetails/:projectId/:clientId"
            element={<ProjectDetails />}
          />
          <Route
            path="/ProjectReport/:projectId/:clientId"
            element={<ProjectReports />}
          />

          {/* <Route
              path="/ProjectReport/:projectId/:clientId"
              element={<ProjectReport />}
            /> */}
          <Route path={"/dashboard"} element={<ClientDashboard />} />
          <Route path="/mastersApproval" element={<MastersApproval />} />
          <Route
            path="/changeRequestApproval"
            element={<ChangeRequestApprovals />}
          />
          <Route
            path="/changeRequestProjectReports/:projectId/:clientId"
            element={<ChangeRequestProjectReports />}
          />
          {/* <Route path={"/barcode"} element={<Barcode />} /> */}
          {/* <Route path={"/qrCode"} element={<QRCode />} /> */}
        </Routes>
      </div>
    </>
  );
};

export default Layout;
