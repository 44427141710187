/* eslint-disable */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  approveCost,
  costMasterList,
} from "../../Redux/Actions/MastersApprovalActions";
import { OverlayTrigger } from "react-bootstrap";
import Swal from "sweetalert2";

const CostMaster = ({ selectedTab, ApproveTooltip, DeleteTooltip }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortColumnDir, setSortColumnDir] = useState(null);

  const loading = useSelector((state) => state.MastersApproval.costsLoading);
  const costsMasterList = useSelector(
    (state) => state.MastersApproval.costsMasterList
  );
  const noOfRecords = useSelector(
    (state) => state.MastersApproval.noOfRecordsCosts
  );

  const getAllCostMasterList = (
    page_number,
    page_size,
    sort_column_name,
    sort_column_dir,
    search_text
  ) => {
    const data = {
      page_number: page_number,
      page_size: page_size,
      sort_column_name: !!sort_column_name ? sort_column_name : null,
      sort_column_dir: !!sort_column_dir ? sort_column_dir : null,
      search_text: !!search_text ? search_text : null,
    };
    dispatch(costMasterList(data));
  };

  const handleSort = (column, sortDirection) => {
    if (Object.keys(column).length !== 0 && !!sortDirection) {
      setSortColumn(column.sortField);
      setSortColumnDir(sortDirection);
      getAllCostMasterList(1, perPage, column.sortField, sortDirection, searchText);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
    getAllCostMasterList(page, perPage, sortColumn, sortColumnDir, searchText);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
    getAllCostMasterList(page, newPerPage, sortColumn, sortColumnDir, searchText);
  };

  const approveCostMaster = async (id, is_delete) => {
    const data = {
      id: id,
      is_delete: is_delete,
    };
    Swal.fire({
      title: "Are you sure?",
      html: `You want to ${is_delete ? "delete" : "approve"}!`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#dd3333",
      confirmButtonText: is_delete ? "Delete" : "Approve",
      customClass: {
        confirmButton: 'confirm-button-swal'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(approveCost(data));
        if (res.payload.result > 0) {
          getAllCostMasterList(page, perPage, null, null, null);
          Swal.fire({
            title: res?.payload?.message,
            confirmButtonColor: "#000000",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: 'confirm-button-swal'
            }
          })
        } else {
          Swal.fire({
            title: res?.payload?.message,
            confirmButtonColor: "#000000",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: 'confirm-button-swal'
            }
          })
        }
      }
    });
  };

  useEffect(() => {
    if (!!selectedTab && selectedTab === "cost") {
      getAllCostMasterList(page, perPage, null, null, searchText);
    }
  }, [selectedTab, searchText]);

  const customStylesDataTable = {
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: "Bold",
      },
    },
  };

  const columns = [
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      sortField: "Description",
    },
    {
      name: "CostToReplace",
      selector: (row) => row.costToReplace,
      sortable: true,
      sortField: "CostToReplace",
    },
    {
      name: "Details",
      selector: (row) => row.details,
      sortable: true,
      sortField: "Details",
    },
    {
      name: "Link",
      selector: (row) => row.link,
      sortable: true,
      sortField: "Link",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
        <div className={`actions`}>
          <OverlayTrigger placement="top" overlay={ApproveTooltip}>
            <button
              className="btn btn-sm bg-success-subtle"
              onClick={() => approveCostMaster(row.id, false)}
            >
              <i className="bi bi-check2-circle"></i>
            </button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={DeleteTooltip}>
            <button
              className="btn btn-sm bg-danger-subtle ms-2"
              onClick={() => approveCostMaster(row.id, true)}
            >
              <i className="bi bi-trash"></i>
            </button>
          </OverlayTrigger>
          </div>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  return (
    <div>
      <DataTable
        columns={columns}
        data={costsMasterList}
        className="table table-bordered table-striped"
        filterServer={true}
        pagination
        paginationServer
        paginationTotalRows={noOfRecords}
        subHeader
        subHeaderComponent={
          <div className="col-md-2">
            <input
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search"
              className="form form-control"
            />
          </div>
        }
        progressPending={loading}
        progressComponent={
          <div style={{ padding: "100px" }}>
            <div className="spinner-border" role="status"></div>
          </div>
        }
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleSort}
        highlightOnHover
        searchableRows={true}
        customStyles={customStylesDataTable}
      />
    </div>
  );
};

export default CostMaster;
