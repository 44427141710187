import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "./Slices/usersSlice";
import allUsersReducer from "./Slices/allUsersSlice";
import idDecryptReducer from "./Slices/idDecryptSlice";
import projectsReducer from "./Slices/projectSlice";
import userLoginResReducer from "./Slices/loginSlice";
import changePasswordReducer from "./Slices/changePasswordSlice";
import forgotPasswordReducer from "./Slices/forgotPasswordSlice";
import resetPasswordReducer from "./Slices/resetPasswordSlice";
import clientsReducer from "./Slices/clientsSlice";
import projectDetailsReducer from "./Slices/projectDetailsSlice";
import projectReportReducer from "./Slices/projectReportSlice";
import projectColourReducer from "./Slices/projectColourSlice";
import MastersApprovalReducer from "./Slices/MastersApprovalSlice";
import imagesUploadReducer from "./Slices/imagesUploadSlices";
import clientProjectReportReducer from "./Slices/clientProjectReportSlice";
import projectDetailsByQRCodeReducer from "./Slices/ProjectDetailsByQRCodeSlice";
import changeRequestReducer from "./Slices/changeRequestSlice";

const store = configureStore({
  reducer: {
    users: usersReducer,
    clients: clientsReducer,
    allUsers: allUsersReducer,
    idDecrypt: idDecryptReducer,
    projects: projectsReducer,
    userLoginRes: userLoginResReducer,
    changePassword: changePasswordReducer,
    forgotPassword: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
    projectDetails: projectDetailsReducer,
    projectsReport: projectReportReducer,
    projectColour: projectColourReducer,
    MastersApproval: MastersApprovalReducer,
    images: imagesUploadReducer,
    clientProjectsReport: clientProjectReportReducer,
    projectDetailsByCode: projectDetailsByQRCodeReducer,
    changeRequest: changeRequestReducer,
  },
});

export default store;
