import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie"
import CryptoJS from "crypto-js"
import {
  API_IMAGES_UPLOAD,
  API_MULTIPLE_IMAGES_UPLOAD,
} from "../../APIProxy/api";
const token = !!Cookies.get("COOKIE_JWTTOKEN") && Cookies.get("COOKIE_JWTTOKEN");
let decodedToken = '';

if (token) {
  const decryptedToken = CryptoJS.AES.decrypt(token, "CRYPTO_SECRET_KEY");
  decodedToken = decryptedToken.toString(CryptoJS.enc.Utf8);
}
export const imagesUpload = createAsyncThunk(
  "Projects/uploadPhotos",
  async ({ files }) => {
    // const formData = new FormData();
    // formData.append("files", files, files.name);
    // const response = await axios.post(API_IMAGES_UPLOAD + projectId, formData);
    const response = await axios.post(API_MULTIPLE_IMAGES_UPLOAD, files, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${decodedToken}`
      },
      maxBodyLength: 100000000,
      maxContentLength: 100000000,
    });
    return response.data;
  }
);
