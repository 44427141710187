import { createSlice } from "@reduxjs/toolkit";
import {
  allProjectsReportListForChangeRequested,
  listAllProjectsForRemarksRequested,
  projectsReportForChangeRequested,
} from "../Actions/ChangeRequestActions";

const initialState = {
  allRequestProject: [],
  projectReport: [],
  projectReportDDList: [],
  conditionBreakdownList: [],
  noOfRecords: 0,
  reportRecordsCounts: null,
  projectName: null,
  clientName: null,
  author: null,
  ffeSurveyors: null,
  columnJSON: null,
  loading: false,
  error: null,
};

const changeRequestSlice = createSlice({
  name: "changeRequest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listAllProjectsForRemarksRequested.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        listAllProjectsForRemarksRequested.fulfilled,
        (state, action) => {
          state.loading = false;
          state.allRequestProject = action.payload;
          state.noOfRecords = action.payload.noOfRecords;
        }
      )
      .addCase(listAllProjectsForRemarksRequested.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(projectsReportForChangeRequested.pending, (state) => {
        state.loading = true;
        state.projectReport = [];
        state.projectName = null;
        state.clientName = null;
        state.author = null;
        state.ffeSurveyors = null;
        state.columnJSON = null;
      })
      .addCase(projectsReportForChangeRequested.fulfilled, (state, action) => {
        state.loading = false;
        state.projectReport = action.payload.projectReportList;
        state.conditionBreakdownList = action.payload.conditionBreakdownList;
        state.reportRecordsCounts = action.payload.reportRecordsCounts;
        state.noOfRecords = action.payload.noOfRecords;
        state.projectName = action.payload.projectName;
        state.clientName = action.payload.clientName;
        state.author = action.payload.author;
        state.ffeSurveyors = action.payload.ffeSurveyors;
        state.columnJSON = action.payload.columnJSON;
      })
      .addCase(projectsReportForChangeRequested.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(allProjectsReportListForChangeRequested.pending, (state) => {
        state.projectReportDDList = [];
      })
      .addCase(
        allProjectsReportListForChangeRequested.fulfilled,
        (state, action) => {
          state.projectReportDDList = action.payload;
        }
      )
      .addCase(
        allProjectsReportListForChangeRequested.rejected,
        (state, action) => {
          state.error = action.error.message;
        }
      );
  },
});

export default changeRequestSlice.reducer;
