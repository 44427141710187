import { createSlice } from "@reduxjs/toolkit";
import { clientProjectsReport } from "../Actions/clientProjectsReportActions";

const initialState = {
  clientProjectsReport: [],
  conditionBreakdownList: [],
  noOfRecords: 0,
  suitablePer:0.0,
  reportRecordsCounts:null,
  projectName: null,
  clientName: null,
  loading: false,
  error: null,
  distinctRoomNames:null,
  distinctRoomNumbers:null,
  distinctDescription:null,
  distinctItemType:null,
  distinctCondition:null,
  distinctDFEStandard:null,
  distinctLifeExpectancy:null,
  conditionDescriptionWithCounts:null
};

const clientProjectReportSlice = createSlice({
  name: "clientProjectsReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clientProjectsReport.pending, (state) => {
        state.loading = true;
        state.projectReport = [];
        state.projectName = null;
        state.clientName = null;
      })
      .addCase(clientProjectsReport.fulfilled, (state, action) => {
        state.loading = false;
        //state.projectReport = action.payload.projectReportList;
        state.conditionBreakdownList = action.payload.conditionBreakdownList;
        state.reportRecordsCounts = action.payload.reportRecordsCounts;
        state.noOfRecords = action.payload.noOfRecords;
        state.suitablePer = action.payload.suitablePer;
        state.projectName = action.payload.projectName;
        state.clientName = action.payload.clientName;
        state.distinctRoomNames = action.payload.distinctRoomNames;
        state.distinctRoomNumbers = action.payload.distinctRoomNumbers;
        state.distinctDescription = action.payload.distinctDescription;
        state.distinctItemType = action.payload.distinctItemType;
        state.distinctCondition = action.payload.distinctCondition;
        state.distinctDFEStandard = action.payload.distinctDFEStandard;
        state.distinctLifeExpectancy = action.payload.distinctLifeExpectancy;
        state.conditionDescriptionWithCounts = action.payload.conditionDescriptionWithCounts;
      })
      .addCase(clientProjectsReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default clientProjectReportSlice.reducer;
