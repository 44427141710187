import { createSlice } from "@reduxjs/toolkit";
import { projectColour } from "../Actions/projectsReportActions";

const initialState = {
  projectColour: [],
  loading: false,
  error: null,
};

const projectColourSlice = createSlice({
  name: "projectColour",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(projectColour.pending, (state) => {
        state.loading = true;
      })
      .addCase(projectColour.fulfilled, (state, action) => {
        state.loading = false;
        state.projectColour = action.payload;
      })
      .addCase(projectColour.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default projectColourSlice.reducer;
