import { createSlice } from '@reduxjs/toolkit';
import { forgotPassword } from '../Actions/forgotPasswordActions';

const initialState = {
  forgotPassword: [],
  loading: false,
  error: null,
};

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    //forgotPassword Slices
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.forgotPassword = action.payload;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
    ;
  },
});

export default forgotPasswordSlice.reducer;