/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { clientProjectsReport } from "../../Redux/Actions/clientProjectsReportActions";
import { useDispatch, useSelector } from "react-redux";
import "react-awesome-lightbox/build/style.css";
import Loader from "../Common/Loader/loader";
import { listProjects } from "../../Redux/Actions/projectsActions";
import * as CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import Multiselect from "multiselect-react-dropdown";
import { secret_key } from "../../APIProxy/secret";
import { Bar, Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js/auto";
import { Button } from "bootstrap";
import moment from "moment";
import DataTable from "react-data-table-component";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { projectsReport } from "../../Redux/Actions/projectsReportActions";
import config from "../../config";
Chart.register(...registerables);

const customStylesDataTable = {
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "Bold",
      backgroundColor: "black",
      color: "white",
    },
  },
};
const conditionalRowStyles = [
  {
    when: (row) => row.description === "Total Result",
    style: {
      fontWeight: "bold",
    },
  },
];
const ClientDashboard = () => {
  const [projectId, setProjectId] = useState(null);
  const [userId, setUserId] = useState(null);
  const dispatch = useDispatch();
  const userRole = Cookies.get("userRole");

  const IMAGE_BASE_URL = config.LOGO_URL;

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortColumnDir, setSortColumnDir] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [projectReportArr, setProjectReportArr] = useState([]);

  const projectReport = useSelector(
    (state) => state.clientProjectsReport.projectReport
  );
  const distinctRoomNames = useSelector(
    (state) => state.clientProjectsReport.distinctRoomNames
  );
  const distinctRoomNumbers = useSelector(
    (state) => state.clientProjectsReport.distinctRoomNumbers
  );
  const distinctDescription = useSelector(
    (state) => state.clientProjectsReport.distinctDescription
  );
  const distinctItemType = useSelector(
    (state) => state.clientProjectsReport.distinctItemType
  );
  const distinctCondition = useSelector(
    (state) => state.clientProjectsReport.distinctCondition
  );
  const distinctDFEStandard = useSelector(
    (state) => state.clientProjectsReport.distinctDFEStandard
  );
  const distinctLifeExpectancy = useSelector(
    (state) => state.clientProjectsReport.distinctLifeExpectancy
  );
  const conditionDescriptionWithCounts = useSelector(
    (state) => state.clientProjectsReport.conditionDescriptionWithCounts
  );
  const suitablePer = useSelector(
    (state) => state.clientProjectsReport.suitablePer
  );
  const conditionBreakdownList = useSelector(
    (state) => state.clientProjectsReport.conditionBreakdownList
  );
  const reportRecordsCounts = useSelector(
    (state) => state.clientProjectsReport.reportRecordsCounts
  );
  const loading = useSelector((state) => state.clientProjectsReport.loading);
  const loadingProjectReport = useSelector((state) => state.projects.loading);
  const columnJSON = useSelector((state) => state.projectsReport.columnJSON);

  const [selectedProjectName, setSelectedProjectName] = useState([]);
  const [selectedRoomName, setSelectedRoomName] = useState([]);
  const [selectedRoomNumber, setSelectedRoomNumber] = useState([]);
  const [selectedDescription, setSelectedDescription] = useState([]);
  const [selectedItemType, setSelectedItemType] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState([]);
  const [selectedDFEStandard, setSelectedDFEStandard] = useState([]);
  const [selectedLifeExpectancy, setSelectedLifeExpectancy] = useState([]);
  const [columnData, setColumnData] = useState();

  useEffect(() => {
    const projectcolumnJSON = !!columnJSON && JSON.parse(columnJSON);
    let convertedData = [];
    if (projectcolumnJSON && typeof projectcolumnJSON === "object") {
      convertedData = Object.keys(projectcolumnJSON).map((key) => ({
        columnName: key,
        alternateColumnName: projectcolumnJSON[key].ALternateColumnName,
        visible: projectcolumnJSON[key].Visible,
      }));
    }
    setColumnData(convertedData);
  }, [columnJSON]);

  useEffect(() => {    
    const userRole = Cookies.get("userRole");
    if (userRole === "ClientUser") {
      const encryptedParentId = Cookies.get("parentId");
      const decryptedBytesForParent =
        !!encryptedParentId &&
        CryptoJS.AES.decrypt(encryptedParentId, secret_key);
        setUserId(decryptedBytesForParent.toString(CryptoJS.enc.Utf8))
    }else{
      const encryptedUserId = Cookies.get("userId");
      const decryptedBytes =
        !!encryptedUserId && CryptoJS.AES.decrypt(encryptedUserId, secret_key);
      const userId = decryptedBytes.toString(CryptoJS.enc.Utf8);
      setUserId(userId)
    }
  }, []);

  const roomNameRef = useRef();
  const roomNumberRef = useRef();
  const descriptionRef = useRef();
  const itemTypeRef = useRef();
  const conditionRef = useRef();
  const dfeStandardRef = useRef();
  const lifeExpectancyRef = useRef();

  const tableColumns = [
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "A - Excellent", //Suitable",
      selector: (row) => row.aSuitable,
    },
    {
      name: "B - Minor Damage",
      selector: (row) => row.bMinorDamage,
    },
    {
      name: "C - Medium Damage ",
      selector: (row) => row.cMediumDamage,
    },
    {
      name: "D - Heavy Damage",
      selector: (row) => row.dHeavyDamage,
    },
    {
      name: "E - Unsuitable ",
      selector: (row) => row.eUnsuitable,
    },
    {
      name: "F - Special Required ",
      selector: (row) => row.fSpecialRequired,
    },
    // {
    //   name: "G - Electrical",
    //   selector: (row) => row.gElectrical
    // },
    {
      name: "Total Result ",
      selector: (row) => row.totalResult,
      style: {
        fontWeight: "bold",
      },
    },
  ];

  const totals = {
    description: "Total Result",
    aSuitable: 0,
    bMinorDamage: 0,
    cMediumDamage: 0,
    dHeavyDamage: 0,
    eUnsuitable: 0,
    fSpecialRequired: 0,
    // gElectrical:0,
    totalResult: 0,
  };

  conditionDescriptionWithCounts &&
    conditionDescriptionWithCounts.forEach((item) => {
      totals.aSuitable += item.aSuitable;
      totals.bMinorDamage += item.bMinorDamage;
      totals.cMediumDamage += item.cMediumDamage;
      totals.dHeavyDamage += item.dHeavyDamage;
      totals.eUnsuitable += item.eUnsuitable;
      totals.fSpecialRequired += item.fSpecialRequired;
      // totals.gElectrical += item.gElectrical;
      totals.totalResult += item.totalResult;
    });

  const tableData = Array.isArray(conditionDescriptionWithCounts)
    ? [...conditionDescriptionWithCounts, totals].map((item, index) => ({
        description: item.description,
        aSuitable: item.aSuitable,
        bMinorDamage: item.bMinorDamage,
        cMediumDamage: item.cMediumDamage,
        dHeavyDamage: item.dHeavyDamage,
        eUnsuitable: item.eUnsuitable,
        fSpecialRequired: item.fSpecialRequired,
        // gElectrical: item.gElectrical,
        totalResult: item.totalResult,
      }))
    : [];

  const handleResetFilter = () => {
    roomNameRef.current.resetSelectedValues();
    roomNumberRef.current.resetSelectedValues();
    descriptionRef.current.resetSelectedValues();
    itemTypeRef.current.resetSelectedValues();
    conditionRef.current.resetSelectedValues();
    dfeStandardRef.current.resetSelectedValues();
    lifeExpectancyRef.current.resetSelectedValues();
    setSelectedRoomName([]);
    setSelectedRoomNumber([]);
    setSelectedDescription([]);
    setSelectedItemType([]);
    setSelectedCondition([]);
    setSelectedDFEStandard([]);
    setSelectedLifeExpectancy([]);
    getProjectReportDataList(projectId, 1, 10, "", "asc", "");
  };

  // const handleProjectNameSelect = (selectedList, selectedItem) => {
  //   if (selectedList.length > 1) {
  //     setSelectedProjectName(selectedList[0].name);
  //     setProjectId(selectedList[0].id);
  //     selectedList.shift();
  //   }
  //   if (projects && projects.length > 0) {
  //     setSelectedProjectName(projects.projects.listData[0]?.name);
  //     setProjectId(projects.projects.listData[0]?.id);
  //   }

  //   // setSelectedProjectName(selectedList);
  //   setSelectedProjectName(selectedList[0].name);
  //   setProjectId(selectedList[0].id);
  //   getProjectReportDataList(!!selectedList && selectedList[0].id,1,10,"", "asc","")

  // }
  const handleProjectNameSelect = (selectedProjectId) => {
    const selectedProject = projects.projects.listData.find(
      (project) => project.id === selectedProjectId
    );
    if (selectedProject) setSelectedProjectName(selectedProject.name);
    setProjectId(selectedProjectId);
    getProjectReportDataList(selectedProjectId, 1, 10, "", "asc", "");
  };
  const handleRoomNameSelect = (selectedList, selectedItem) => {
    setSelectedRoomName([...selectedList]);
  };

  const handleRoomNumberSelect = (selectedList, selectedItem) => {
    setSelectedRoomNumber([...selectedList]);
  };

  const handleDescriptionSelect = (selectedList, selectedItem) => {
    setSelectedDescription([...selectedList]);
  };

  const handleItemTypeSelect = (selectedList, selectedItem) => {
    setSelectedItemType([...selectedList]);
  };

  const handleConditionSelect = (selectedList, selectedItem) => {
    setSelectedCondition([...selectedList]);
  };

  const handleDFEStandardSelect = (selectedList, selectedItem) => {
    setSelectedDFEStandard([...selectedList]);
  };

  const handleLifeExpectancySelect = (selectedList, selectedItem) => {
    setSelectedLifeExpectancy([...selectedList]);
  };

  const projects = useSelector((state) => state.projects);

  const getProjectReportDataList = (
    project_id,
    page_number,
    page_size,
    sort_column_name,
    sort_column_dir,
    search_text,
    roomNumbers,
    roomNames,
    descriptions,
    itemTypes,
    conditions,
    dfeStandards,
    lifeExpectancies
  ) => {
    const data = {
      page_number: page_number,
      page_size: page_size,
      projectId: project_id,
      sort_column_name: !!sort_column_name ? sort_column_name : null,
      sort_column_dir: !!sort_column_dir ? sort_column_dir : null,
      search_text: !!search_text ? search_text : null,
      roomNumbers: roomNumbers || null,
      roomNames: roomNames || null,
      descriptions: descriptions || null,
      itemTypes: itemTypes || null,
      conditions: conditions || null,
      dfeStandards: dfeStandards || null,
      lifeExpectancies: lifeExpectancies || null,
    };
    dispatch(clientProjectsReport(data));
    dispatch(projectsReport(data));
  };

  useEffect(() => {
    if (projects.projects.listData && projects.projects.listData.length > 0) {
      const firstProjectId = projects.projects.listData[0].id;
      getProjectReportDataList(
        firstProjectId,
        page,
        perPage,
        sortColumn,
        sortColumnDir,
        searchText
      );
      // setSelectedProjectName([{ id: firstProjectId, name: projects.projects.listData[0].name }]);
    }
  }, [projects]);

  const GetAllProjectList = (
    sort_column_name,
    sort_column_dir,
    page,
    page_size
  ) => {
    const sortcolumn = !!sort_column_name ? sort_column_name : null;
    const sortdir = !!sort_column_dir ? sort_column_dir : null;
    dispatch(
      listProjects({
        clientId: userId,
        loginUserId: userId,
        searchTerm: !!searchText ? searchText : null,
        sortBy: sortcolumn,
        sortDirection: sortdir,
        page: page,
        pageSize: page_size,
      })
    );
  };

  useEffect(() => {
    GetAllProjectList("p.name", "asc", 1, 10);
  }, [userId, searchText]);

  const standardCount = reportRecordsCounts?.standardCount || 0;
  const abnormalCount = reportRecordsCounts?.abnormalCount || 0;

  const donutChartData = {
    labels: ["Standard Count", "Abnormal Count"],
    datasets: [
      {
        data: [standardCount, abnormalCount],
        backgroundColor: [
          !!standardCount && !!abnormalCount && standardCount > abnormalCount
            ? "#305d92"
            : "#585857",
          !!standardCount && !!abnormalCount && standardCount > abnormalCount
            ? "#585857"
            : "#305d92",
        ],
      },
    ],
  };
  useEffect(() => {
    setProjectReportArr(projectReport);
  }, [projectReport]);

  useEffect(() => {
    if (!!projectId) {
      getProjectReportDataList(
        projectId,
        page,
        perPage,
        sortColumn,
        sortColumnDir,
        searchText,
        selectedRoomNumber.map((item) => item.name).join(","),
        selectedRoomName.map((item) => item.name).join(","),
        selectedDescription.map((item) => item.name).join(","),
        selectedItemType.map((item) => item.name).join(","),
        selectedCondition.map((item) => item.name).join(","),
        selectedDFEStandard.map((item) => item.name).join(","),
        selectedLifeExpectancy.map((item) => item.name).join(",")
      );
    }
  }, [
    projectId,
    searchText,
    selectedRoomNumber,
    selectedRoomName,
    selectedDescription,
    selectedItemType,
    selectedCondition,
    selectedDFEStandard,
    selectedLifeExpectancy,
  ]);

  useEffect(() => {
    if (!!projects.projects.listData && projects.projects.listData.length > 0) {
      setProjectId(projects.projects.listData[0].id);
      setSelectedProjectName(projects.projects.listData[0].name);
    }
  }, [projects]);

  // const naturalSort = (a, b) => {
  //   const chunk = /(\d+)|(\D+)/g;
  //   const chunkR = /\d+/;
  //   const aChunk = a.match(chunk);
  //   const bChunk = b.match(chunk);

  //   while (aChunk.length && bChunk.length) {
  //     const aChunkPart = aChunk.shift();
  //     const bChunkPart = bChunk.shift();
  //     if (chunkR.test(aChunkPart) !== chunkR.test(bChunkPart)) {
  //       return aChunkPart.localeCompare(bChunkPart);
  //     }
  //     if (chunkR.test(aChunkPart)) {
  //       if (parseInt(aChunkPart, 10) !== parseInt(bChunkPart, 10)) {
  //         return parseInt(aChunkPart, 10) - parseInt(bChunkPart, 10);
  //       }
  //     } else {
  //       const compareResult = aChunkPart.localeCompare(bChunkPart);
  //       if (compareResult !== 0) {
  //         return compareResult;
  //       }
  //     }
  //   }

  //   return aChunk.length - bChunk.length;
  // };
  const naturalSort = (a, b) => {
    const chunk = /(\d+)|(\D+)/g;
    const chunkR = /\d+/;
    const aChunk = a.match(chunk) || []; // Ensure aChunk is an array
    const bChunk = b.match(chunk) || []; // Ensure bChunk is an array

    while (aChunk.length && bChunk.length) {
      const aChunkPart = aChunk.shift();
      const bChunkPart = bChunk.shift();
      if (chunkR.test(aChunkPart) !== chunkR.test(bChunkPart)) {
        return aChunkPart.localeCompare(bChunkPart);
      }
      if (chunkR.test(aChunkPart)) {
        if (parseInt(aChunkPart, 10) !== parseInt(bChunkPart, 10)) {
          return parseInt(aChunkPart, 10) - parseInt(bChunkPart, 10);
        }
      } else {
        const compareResult = aChunkPart.localeCompare(bChunkPart);
        if (compareResult !== 0) {
          return compareResult;
        }
      }
    }

    return aChunk.length - bChunk.length;
  };

  const roomNumber = {
    options:
      !!distinctRoomNumbers &&
      [...distinctRoomNumbers]
        .filter((name) => name)
        .sort(naturalSort)
        .map((name, index) => ({ name: name, id: index + 1 })),
  };

  const roomName = {
    options:
      !!distinctRoomNames &&
      [...distinctRoomNames]
        .filter((name) => name)
        .map((name) => name.trim()) // Remove leading and trailing spaces
        .sort((a, b) => a.localeCompare(b)) // Sort the room names alphabetically
        .map((name, index) => ({ name: name, id: index + 1 })),
  };

  // const roomNumber = {
  //   options: !!distinctRoomNumbers && [...distinctRoomNumbers]
  //     .sort((a, b) => a - b) // Sort the room numbers numerically
  //     .map((name, index) => ({ name: name, id: index + 1 }))
  // };

  const description = {
    options:
      !!distinctDescription &&
      [...distinctDescription]
        .filter((name) => name)
        .sort((a, b) => a.trim().localeCompare(b.trim())) // Sort the descriptions alphabetically after trimming
        .map((name, index) => ({ name: name.trim(), id: index + 1 })), // Trim the names again
  };

  const itemType = {
    options:
      !!distinctItemType &&
      [...distinctItemType]
        .filter((name) => name)
        .map((name) => name.trim()) // Remove leading and trailing spaces
        .sort((a, b) => a.localeCompare(b)) // Sort the item types alphabetically
        .map((name, index) => ({ name: name, id: index + 1 })),
  };

  const condition = {
    options:
      !!distinctCondition &&
      [...distinctCondition]
        .filter((name) => name)
        .map((name) => name.trim()) // Remove leading and trailing spaces
        .sort((a, b) => a.localeCompare(b)) // Sort the conditions alphabetically
        .map((name, index) => ({ name: name, id: index + 1 })),
  };

  const dfeStandard = {
    options:
      !!distinctDFEStandard &&
      [...distinctDFEStandard]
        .filter((name) => name)
        .map((name) => name.trim()) // Remove leading and trailing spaces
        .sort((a, b) => a.localeCompare(b)) // Sort the DFE standards alphabetically
        .map((name, index) => ({ name: name, id: index + 1 })),
  };

  const lifeExpectancy = {
    options:
      !!distinctLifeExpectancy &&
      [...distinctLifeExpectancy]
        .filter((name) => name)
        .map((name) => name.trim()) // Remove leading and trailing spaces
        .sort((a, b) => a.localeCompare(b)) // Sort the life expectancies alphabetically
        .map((name, index) => ({ name: name, id: index + 1 })),
  };

  const conditionForChart = {
    options:
      !!conditionBreakdownList &&
      conditionBreakdownList
        .filter((item) => item.condition !== null) // Filter out items with null condition
        .map((name, index) => ({
          name: name.condition,
          id: index + 1,
          count: name.count,
        })),
  };
  const newConditionForChart =
    !!conditionForChart.options &&
    conditionForChart.options
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((item) => item);
  const quantities = newConditionForChart.map((item) => item.count);
  const adjustedQuantities = quantities;
  const barChartData = {
    labels: newConditionForChart.map((item) => item.name),
    datasets: [
      {
        label: "Condition Summary",
        data: adjustedQuantities,
        backgroundColor: "#305d92", //'rgba(54, 162, 235, 0.5)',
        // borderColor: 'rgba(54, 162, 235, 1)',
        // borderWidth: 1,
      },
    ],
  };
  const maxBarValue = Math.max(...adjustedQuantities); // Get the highest bar value
  const yAxisMax = maxBarValue + maxBarValue * 0.5;
  const barCharOptions = {
    indexAxis: "x",
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false, // Remove x-axis gridlines
        },
        title: {
          color: "#305d92",
          display: true,
          text: "Condition",
          font: {
            weight: "bold",
            family: "Arial",
            size: 14,
          },
        },
      },
      y: {
        grid: {
          display: false, // Remove x-axis gridlines
        },
        title: {
          color: "#305d92",
          display: true,
          text: "Total Quantity",
          font: {
            weight: "bold",
            family: "Arial",
            size: 14,
          },
        },
        ticks: {
          beginAtZero: true,
          // stepSize: Math.ceil(Math.max(...quantities) / 10),
          stepSize: Math.ceil(yAxisMax / 10),
        },
      },
    },
    plugins: {
      title: {
        color: "#305d92",
        display: true,
        text: "Condition Summary",
        font: {
          weight: "bold",
          family: "Arial",
          size: 14,
        },
      },
      legend: {
        display: false, // Hide legend
      },
      tooltip: false,
      datalabels: {
        display: true,
        color: "black", // Set the color of the labels
        anchor: "end", // Position of the labels, you can use 'end', 'start', 'center'
        align: "end", // Alignment of the labels, you can use 'start', 'center', 'end'
        formatter: (value, context) => value, // Function to format the label text
      },
    },
  };

  const donutChartOptions = {
    plugins: {
      title: {
        font: {
          weight: "bold",
          family: "Arial",
          size: 14,
        },
        color: "#305d92",
        display: true,
        text: "DFE Standard",
      },
      legend: {
        display: false,
      },
      tooltip: false,
      datalabels: {
        display: true,
        color: "white",
        formatter: (value, context) => {
          const index = context.dataIndex;
          if (index === 0) {
            return `Standard\n${value}, ${(
              (value / (standardCount + abnormalCount)) *
              100
            ).toFixed(2)}%`;
          } else if (index === 1) {
            return `Abnormal\n${value}, ${(
              (value / (standardCount + abnormalCount)) *
              100
            ).toFixed(2)}%`;
          }
        },
      },
    },
    cutout: "40%",
    elements: {
      arc: {
        borderWidth: 0, // Set border width to 0
      },
    },
  };

  // Find the second maximum count
  const sortedCounts = [...quantities].sort((a, b) => b - a);
  const secondMaxCount = sortedCounts[1];

  const filteredConditionBreakdownList = conditionBreakdownList.filter(
    (item) => item.lifeExpectancy !== "Total Result"
  );

  const horizontalBarChartData = {
    labels: filteredConditionBreakdownList
      .map((item) => item.lifeExpectancy)
      .reverse(),
    datasets: [
      {
        label: "Life Expectancy",
        data: filteredConditionBreakdownList
          .map((item) => item.count)
          .reverse(),
        backgroundColor: "#305d92", //'rgba(54, 162, 235, 0.5)',
        // borderColor: 'rgba(54, 162, 235, 1)',
        // borderWidth: 1,
      },
    ],
  };
  const horizontalBarCharOptions = {
    indexAxis: "y",
    responsive: true,
    scales: {
      x: {
        title: {
          color: "#305d92",
          display: true,
          text: "Total Quantity",
          font: {
            weight: "bold",
            family: "Arial",
            size: 14,
          },
        },

        ticks: {
          beginAtZero: true,
          max: secondMaxCount,
          // stepSize: Math.ceil(secondMaxCount / 10),
          stepSize: Math.ceil(yAxisMax / 10),
        },
      },
      y: {
        grid: {
          display: false, // Remove x-axis gridlines
        },
        title: {
          color: "#305d92",
          display: true,
          text: "Life Expectancy",
          font: {
            weight: "bold",
            family: "Arial",
            size: 14,
          },
        },
      },
    },
    plugins: {
      title: {
        color: "#305d92",
        font: {
          weight: "bold",
          family: "Arial",
          size: 14,
        },
        display: true,
        text: "Life Expectancy",
      },
      legend: {
        display: false, // Hide legend
      },
      tooltip: false,
      datalabels: {
        display: true,
        color: "black", // Set the color of the labels
        anchor: "end", // Position of the labels, you can use 'end', 'start', 'center'
        align: "end", // Alignment of the labels, you can use 'start', 'center', 'end'
        formatter: (value, context) => value, // Function to format the label text
      },
    },
  };
  function findAlternateName(name) {
    const lifeExpectancyColumn =
      !!columnData && columnData.find((column) => column.columnName === name);
    return lifeExpectancyColumn
      ? lifeExpectancyColumn.alternateColumnName
      : null;
  }
  function checkVisibility(name) {
    const lifeExpectancyColumn =
      !!columnData && columnData.find((column) => column.columnName === name);
    return lifeExpectancyColumn ? lifeExpectancyColumn.visible : true;
  }

  return (
    <>
      {(loading || loadingProjectReport) && <Loader />}
      <div className="mainContent px-4 pt-3">
        <div className="page-header mb-3">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Dashboard</h3>
                <div className="page-title-right d-flex"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="card shadow-sm border-0">
              <div className="card-body p-9">
                <div className="d-lg-flex gap-1 justify-content-between">
                  <div className="d-flex">
                    <div className="client-icon">
                      {/* <i className="bi bi-cast"></i> */}
                      {userId && (
                        <img
                          src={`${IMAGE_BASE_URL}/${userId}.jpg?timestamp=${Date.now()}`}
                          alt="Logo"
                          className="project-logo-img client-logo"
                        />
                      )}
                    </div>
                    <div>
                      <h6 className="my-0 fw-normal">{selectedProjectName} </h6>
                      <h6 className="my-0">
                        Date:{" "}
                        {reportRecordsCounts &&
                          moment(
                            reportRecordsCounts?.projectCreationDate
                          ).format("DD/MM/YYYY")}{" "}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-4 mb-2">
                    <div className="form-group">
                      <label className="form-label mb-0">Project Name</label>
                      {/* <Multiselect
                        options={projects.projects.listData || []}
                        onSelect={handleProjectNameSelect}
                        selectedValues={[{ id: projectId && projectId, name: selectedProjectName && selectedProjectName }]}
                        onRemove={(selectedList, removedItem) => {
                          setSelectedProjectName(removedItem.name);
                          setProjectId(removedItem.id);
                        }} 
                        style={{display: 'none'}}
                        displayValue="name"
                        placeholder={""}
                      /> */}
                      <select
                        //value={selectedProjectName}
                        value={projectId}
                        onChange={(e) =>
                          handleProjectNameSelect(e.target.value)
                        }
                        className="form-select"
                      >
                        {projects.projects.listData &&
                          projects.projects.listData.map((project) => (
                            <option key={project.id} value={project.id}>
                              {project.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 mb-2"
                    hidden={!checkVisibility("RoomName")}
                  >
                    <div className="form-group">
                      <label className="form-label mb-0">
                        {findAlternateName("RoomName")
                          ? findAlternateName("RoomName")
                          : "Room Name"}
                      </label>
                      <Multiselect
                        options={roomName.options || []}
                        onSelect={handleRoomNameSelect}
                        ref={roomNameRef}
                        // onRemove={(selectedList, removedItem) => {
                        //   setSelectedRoomName(selectedList);
                        // }}
                        onRemove={(selectedList, removedItem) => {
                          const updatedSelectedRoomName =
                            selectedRoomName.filter(
                              (item) => item.name !== removedItem.name
                            );
                          setSelectedRoomName(updatedSelectedRoomName);
                        }}
                        displayValue="name"
                        placeholder={
                          selectedRoomName.length > 0
                            ? ""
                            : "Select " +
                              (findAlternateName("RoomName")
                                ? findAlternateName("RoomName")
                                : "Room Name")
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 mb-2"
                    hidden={!checkVisibility("RoomNumber")}
                  >
                    <div className="form-group">
                      <label className="form-label mb-0">
                        {findAlternateName("RoomNumber")
                          ? findAlternateName("RoomNumber")
                          : "Room Number"}
                      </label>
                      <Multiselect
                        options={roomNumber.options || []}
                        onSelect={handleRoomNumberSelect}
                        ref={roomNumberRef}
                        // onRemove={(selectedList, removedItem) => {
                        //   setSelectedRoomNumber(selectedList);
                        // }}
                        onRemove={(selectedList, removedItem) => {
                          const updatedSelectedRoomNumber =
                            selectedRoomNumber.filter(
                              (item) => item.name !== removedItem.name
                            );
                          setSelectedRoomNumber(updatedSelectedRoomNumber);
                        }}
                        displayValue="name"
                        placeholder={
                          selectedRoomNumber.length > 0
                            ? ""
                            : "Select " +
                              (findAlternateName("RoomNumber")
                                ? findAlternateName("RoomNumber")
                                : "Room Number")
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 mb-2"
                    hidden={!checkVisibility("Description")}
                  >
                    <div className="form-group">
                      <label className="form-label mb-0">
                        {findAlternateName("Description")
                          ? findAlternateName("Description")
                          : "Description"}
                      </label>
                      <Multiselect
                        options={description.options || []}
                        onSelect={handleDescriptionSelect}
                        ref={descriptionRef}
                        // onRemove={(selectedList, removedItem) => {
                        //   setSelectedDescription(selectedList);
                        // }}
                        onRemove={(selectedList, removedItem) => {
                          const updatedSelectedDescription =
                            selectedDescription.filter(
                              (item) => item.name !== removedItem.name
                            );
                          setSelectedDescription(updatedSelectedDescription);
                        }}
                        displayValue="name"
                        placeholder={
                          selectedDescription.length > 0
                            ? ""
                            : "Select " +
                              (findAlternateName("Description")
                                ? findAlternateName("Description")
                                : "Description")
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 mb-2"
                    hidden={!checkVisibility("ItemType")}
                  >
                    <div className="form-group">
                      <label className="form-label mb-0">
                        {findAlternateName("ItemType")
                          ? findAlternateName("ItemType")
                          : "Item Type"}
                      </label>
                      <Multiselect
                        options={itemType.options || []}
                        onSelect={handleItemTypeSelect}
                        ref={itemTypeRef}
                        // onRemove={(selectedList, removedItem) => {
                        //   setSelectedItemType(selectedList);
                        // }}
                        onRemove={(selectedList, removedItem) => {
                          const updatedSelectedItemType =
                            selectedItemType.filter(
                              (item) => item.name !== removedItem.name
                            );
                          setSelectedItemType(updatedSelectedItemType);
                        }}
                        displayValue="name"
                        placeholder={
                          selectedItemType.length > 0
                            ? ""
                            : "Select " +
                              (findAlternateName("ItemType")
                                ? findAlternateName("ItemType")
                                : "Item Type")
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 mb-2"
                    hidden={!checkVisibility("Condition")}
                  >
                    <div className="form-group">
                      <label className="form-label mb-0">
                        {findAlternateName("Condition")
                          ? findAlternateName("Condition")
                          : "Condition"}
                      </label>
                      <Multiselect
                        options={condition.options || []}
                        onSelect={handleConditionSelect}
                        ref={conditionRef}
                        // onRemove={(selectedList, removedItem) => {
                        //   setSelectedCondition(selectedList);
                        // }}
                        onRemove={(selectedList, removedItem) => {
                          const updatedSelectedCondition =
                            selectedCondition.filter(
                              (item) => item.name !== removedItem.name
                            );
                          setSelectedCondition(updatedSelectedCondition);
                        }}
                        displayValue="name"
                        placeholder={
                          selectedCondition.length > 0
                            ? ""
                            : "Select " +
                              (findAlternateName("Condition")
                                ? findAlternateName("Condition")
                                : "Condition")
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 mb-2"
                    hidden={!checkVisibility("DFEStandard")}
                  >
                    <div className="form-group">
                      <label className="form-label mb-0">
                        {findAlternateName("DFEStandard")
                          ? findAlternateName("DFEStandard")
                          : "DFE Standard"}
                      </label>
                      <Multiselect
                        options={dfeStandard.options || []}
                        onSelect={handleDFEStandardSelect}
                        ref={dfeStandardRef}
                        // onRemove={(selectedList, removedItem) => {
                        //   setSelectedDFEStandard(selectedList);
                        // }}
                        onRemove={(selectedList, removedItem) => {
                          const updatedSelectedDFEStandard =
                            selectedDFEStandard.filter(
                              (item) => item.name !== removedItem.name
                            );
                          setSelectedDFEStandard(updatedSelectedDFEStandard);
                        }}
                        displayValue="name"
                        placeholder={
                          selectedDFEStandard.length > 0
                            ? ""
                            : "Select " +
                              (findAlternateName("DFEStandard")
                                ? findAlternateName("DFEStandard")
                                : "DFE Standard")
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-4 mb-2"
                    hidden={!checkVisibility("LifeExpectancy")}
                  >
                    <div className="form-group">
                      <label className="form-label mb-0">
                        {findAlternateName("LifeExpectancy")
                          ? findAlternateName("LifeExpectancy")
                          : "LifeExpectancy"}
                      </label>
                      <Multiselect
                        options={lifeExpectancy.options || []}
                        onSelect={handleLifeExpectancySelect}
                        ref={lifeExpectancyRef}
                        // onRemove={(selectedList, removedItem) => {
                        //   setSelectedLifeExpectancy(selectedList);
                        // }}
                        onRemove={(selectedList, removedItem) => {
                          const updatedSelectedLifeExpectancy =
                            selectedLifeExpectancy.filter(
                              (item) => item.name !== removedItem.name
                            );
                          setSelectedLifeExpectancy(
                            updatedSelectedLifeExpectancy
                          );
                        }}
                        displayValue="name"
                        placeholder={
                          selectedLifeExpectancy.length > 0
                            ? ""
                            : "Select " +
                              (findAlternateName("LifeExpectancy")
                                ? findAlternateName("LifeExpectancy")
                                : "LifeExpectancy")
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 mt-4">
                    {/* <label className="form-label mb-0"></label> */}
                    <button
                      className="btn btn-primary reset-button-height"
                      onClick={handleResetFilter}
                    >
                      Reset Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          {(checkVisibility("Condition") ||
            checkVisibility("CarbonSavingKG") ||
            checkVisibility("DFEStandard")) && (
            <>
              <div className="col-lg-4 mb-2">
                <div className="card shadow-sm border-0 h-100">
                  <div className="card-body p-9 d-flex align-items-center justify-content-center">
                    <div className="progress-wrapper">
                      {checkVisibility("Condition") ? (
                        <>
                          <div className="progress-title dark-blue-font">
                            {suitablePer} %{" "}
                          </div>
                          <strong>
                            <div className="fnt-20 dark-blue-font font-bold">
                              Suitable to repurpose{" "}
                            </div>
                          </strong>
                          <div className="fnt-16 dark-blue-font font-bold">
                            Within the next 2-3 years of present usage
                          </div>{" "}
                        </>
                      ) : (
                        "No data found"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-2">
                <div className="card shadow-sm border-0 h-100">
                  <div className="card-body p-9 d-flex align-items-center justify-content-center">
                    <div className="progress-wrapper">
                      {checkVisibility("CarbonSavingKG") ? (
                        <>
                          <div className="progress-title green-font">
                            {reportRecordsCounts?.carbonSavingKG}kg of Co2
                          </div>
                          {/* <span className="fnt-16 sup-script">kg of Co2</span></div> */}
                          {/* <div className="fnt-20 fw-bold mt-2">{selectedProjectName.length > 0 && selectedProjectName[0].name}</div> */}
                          <strong>
                            <div className="fnt-20 dark-blue-font font-bold">
                              {selectedProjectName} Potential carbon reduction
                            </div>
                          </strong>
                          <div className="fnt-16 dark-blue-font font-bold">
                            repurposing quality FF&E
                          </div>{" "}
                        </>
                      ) : (
                        "No data found"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-2">
                <div className="card shadow-sm border-0 h-100">
                  <div className="card-body p-9">
                    {checkVisibility("DFEStandard") ? (
                      <div className="card-body p-9">
                        <div className="text-center doughnut-chart">
                          <Doughnut
                            data={donutChartData}
                            options={donutChartOptions}
                            plugins={[ChartDataLabels]}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="card-body p-9 d-flex align-items-center justify-content-center">
                        No data found
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          {(checkVisibility("Condition") ||
            checkVisibility("LifeExpectancy")) && (
            <>
              <div className="col-lg-6 mb-2">
                <div className="card shadow-sm border-0 h-100">
                  {checkVisibility("Condition") ? (
                    <div className="card-body p-9">
                      <Bar
                        data={barChartData}
                        options={barCharOptions}
                        plugins={[ChartDataLabels]}
                      />
                    </div>
                  ) : (
                    <div className="card-body p-9 d-flex align-items-center justify-content-center">
                      No data found
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 mb-2">
                <div className="card shadow-sm border-0 h-100">
                  {checkVisibility("LifeExpectancy") ? (
                    <div className="card-body p-9">
                      <Bar
                        data={horizontalBarChartData}
                        options={horizontalBarCharOptions}
                        plugins={[ChartDataLabels]}
                      />
                    </div>
                  ) : (
                    <div className="card-body p-9 d-flex align-items-center justify-content-center">
                      No data found
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        {checkVisibility("Condition") &&
          checkVisibility("Description") &&
          tableData && (
            <div className="table-tbody-fixed">
              <DataTable
                columns={tableColumns}
                data={tableData}
                className="table table-bordered table-striped"
                customStyles={customStylesDataTable}
                conditionalRowStyles={conditionalRowStyles}
              />
            </div>
          )}
      </div>
    </>
  );
};
export default ClientDashboard;
