import { createSlice } from '@reduxjs/toolkit';
import { changePassword } from '../Actions/changePasswordActions';

const initialState = {
  changePassword: [],
  loading: false,
  error: null,
};

const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    //changePassword Slices
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.changePassword = action.payload;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
    ;
  },
});

export default changePasswordSlice.reducer;